import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import BreachReasonsMap from '../../models/BreachReasonsMap';
import { FiltersKinds, FiltersTypes, Kinds } from './FilterTypes';
import { onChangeFn } from './utils/filtersOnChangeFns';
import { FieldFilter, FilterValue } from '../../../../../../generated/graphql';
import getProcessStatusObject from '../../../../../shared/helpers/getProcessStatusObject';
import { TOptionsProcessStatusObject } from '../../models/CommonProcessStatusObject';
import { getModalsInfo } from '../../../../../../store/modals/modalsSelectors';

const useFilterData = (data: any): any => {
  const isUCadNum = useSelector(getModalsInfo)?.isUCadNum;

  const { t } = useTranslation();
  const [selectedFilters, setFilters] = useState([
    ...data.filters.map((filter: FilterValue) => ({
      ...filter,
      dateTo: filter.dateTo ? new Date(filter.dateTo) : null,
      dateFrom: filter.dateFrom ? new Date(filter.dateFrom) : null,
    })),
    ...data.fieldFilters.map((filter: FieldFilter) => ({
      kind: filter.field,
      value: filter.value,
      emptyField: !!filter?.emptyField,
    })),
  ]);

  const processStatusObject = getProcessStatusObject('Admin');

  const filters: Record<Kinds, { type: FiltersTypes; options?: any }> = {
    PROCESSING_STATUS: {
      type: 'selectWithDate',
      options: Object.values(processStatusObject).sort(
        (a, b) =>
          (a as TOptionsProcessStatusObject).order - (b as TOptionsProcessStatusObject).order
      ),
    },
    BREACH_REASON: {
      type: 'selectWithDate',
      options: BreachReasonsMap,
    },
    REGION: {
      type: 'region',
    },
    ADDRESS: {
      type: 'address',
    },
    CAD_NUM: {
      type: 'input',
      options: {
        mask: isUCadNum ? '' : '20:99:9999999:9999',
        onChangeFn,
      },
    },
    PHONE: {
      type: 'input',
      options: {
        mask: '+7 (999) 999 99 99',
        onChangeFn,
      },
    },
    OWNER: {
      type: 'input',
      options: {
        onChangeFn,
      },
    },
    USAGE: {
      type: 'usage',
      options: {
        onChangeFn,
      },
    },
  };

  const filterSelect = FiltersKinds.map((key) => ({
    value: key,
    label: t(`filters.fields.${key}`),
  }));

  useEffect(() => {
    if (data.addr)
      setFilters([
        ...data.filters,
        data.addr,
        ...data.fieldFilters.map((filter: FieldFilter) => ({
          kind: filter.field,
          value: filter.value,
          emptyField: !!filter?.emptyField,
        })),
      ]);
  }, [data]);

  return { filters, t, filterSelect, selectedFilters };
};
export default useFilterData;
