import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An RFC-3339 compliant Full Date Scalar */
  Date: any;
  /** An RFC-3339 compliant DateTime Scalar */
  DateTime: any;
  /** A universally unique identifier compliant UUID Scalar */
  UUID: any;
};

/** Тестируем возможно ли объеденить участки */
export type AbleToMergeInput = {
  /**
   * Домовладение источник.
   * откуда мержим.
   */
  sourceId: Scalars['ID'];
  /**
   * Домовладение получатель.
   * куда мержим.
   */
  targetId: Scalars['ID'];
};

export enum ActionContent {
  Oks = 'OKS',
  Plot = 'PLOT',
}

export enum ActionType {
  ChangeObjectLayer = 'CHANGE_OBJECT_LAYER',
  CopyObjectOnLayer = 'COPY_OBJECT_ON_LAYER',
}

export type AddBreachInput = {
  breach?: InputMaybe<BreachAddRequest>;
};

export type AddBreachesInput = {
  breaches?: InputMaybe<Array<InputMaybe<BreachAddRequest>>>;
};

export type AddGroupInput = {
  id?: InputMaybe<Scalars['ID']>;
  layers?: InputMaybe<Array<InputMaybe<LayerAddInput>>>;
  name: Scalars['String'];
  parentId?: InputMaybe<Scalars['Int']>;
};

export type AddOksInput = {
  address?: InputMaybe<Scalars['String']>;
  cadNumber?: InputMaybe<Scalars['String']>;
  geo: GeoInput;
  homeOwnershipId: Scalars['ID'];
  layerId: Scalars['ID'];
  parentId: Scalars['ID'];
};

export type AddRoomInput = {
  address?: InputMaybe<Scalars['String']>;
  cadNum?: InputMaybe<Scalars['String']>;
  comment?: InputMaybe<Scalars['String']>;
  parentId: Scalars['ID'];
  unifiedCadNum?: InputMaybe<Scalars['Boolean']>;
  usage?: InputMaybe<Scalars['String']>;
};

export type AddressBatchEditInput = {
  dadata?: InputMaybe<DadataAddressInput>;
  detail: AddressDetailBatchInput;
};

export type AddressDetail = {
  __typename?: 'AddressDetail';
  address?: Maybe<Scalars['String']>;
  /** DadataAddress ID */
  addressId?: Maybe<Scalars['ID']>;
  area?: Maybe<Scalars['String']>;
  areaTypeFull?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityTypeFull?: Maybe<Scalars['String']>;
  entityType: EntityType;
  house?: Maybe<Scalars['String']>;
  houseTypeFull?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** ссылка на участок */
  objId: Scalars['ID'];
  room?: Maybe<Scalars['String']>;
  settlement?: Maybe<Scalars['String']>;
  settlementTypeFull?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetTypeFull?: Maybe<Scalars['String']>;
};

export type AddressDetailBatchInput = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  areaTypeFull?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityTypeFull?: InputMaybe<Scalars['String']>;
  house?: InputMaybe<Scalars['String']>;
  houseTypeFull?: InputMaybe<Scalars['String']>;
  room?: InputMaybe<Scalars['String']>;
  settlement?: InputMaybe<Scalars['String']>;
  settlementTypeFull?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetTypeFull?: InputMaybe<Scalars['String']>;
};

export type AddressDetailInput = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  areaTypeFull?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityTypeFull?: InputMaybe<Scalars['String']>;
  entityType: EntityType;
  house?: InputMaybe<Scalars['String']>;
  houseTypeFull?: InputMaybe<Scalars['String']>;
  objId: Scalars['ID'];
  room?: InputMaybe<Scalars['String']>;
  settlement?: InputMaybe<Scalars['String']>;
  settlementTypeFull?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetTypeFull?: InputMaybe<Scalars['String']>;
};

export type AddressFilterInput = {
  /** наименовние поселка/города */
  bName: Scalars['String'];
  /** тип записи */
  bType: WorkBorderType;
  /** код фиас */
  fiasId?: InputMaybe<Scalars['UUID']>;
  /** дом */
  house?: InputMaybe<Scalars['String']>;
  /** квартира */
  room?: InputMaybe<Scalars['String']>;
  /** улица */
  street: Scalars['String'];
};

export type AddressInput = {
  dadata?: InputMaybe<DadataAddressInput>;
  detail: AddressDetailInput;
  objId: Scalars['ID'];
};

/** тело запроса при обновлении адреса */
export type AddressInputV2 = {
  data?: InputMaybe<AddressInput>;
  noInfoReason?: InputMaybe<NoInfoReasonRequest>;
  objectId?: InputMaybe<Scalars['ID']>;
  objectType?: InputMaybe<ContentType>;
  taskId?: InputMaybe<Scalars['ID']>;
  taskObjectId?: InputMaybe<Scalars['ID']>;
};

export type AddressOutput = {
  __typename?: 'AddressOutput';
  addressDetail?: Maybe<AddressDetail>;
  dadataAddress?: Maybe<DadataAddress>;
};

export type AddressUpdateOutput = {
  __typename?: 'AddressUpdateOutput';
  address?: Maybe<AddressOutput>;
  noInfoReason?: Maybe<NoInfoReasonResponse>;
};

export type AllInWindowInput = {
  geo: GeoInput;
  searchType: GeoSearchType;
};

export type AllInWindowOutput = {
  __typename?: 'AllInWindowOutput';
  contentType?: Maybe<ContentType>;
  geo?: Maybe<Geometry>;
  geomId: Scalars['ID'];
  id: Scalars['ID'];
  layerId: Scalars['ID'];
};

export enum AnContentType {
  Plot = 'PLOT',
}

export enum AnObjectStatus {
  All = 'ALL',
  Complete = 'COMPLETE',
  NotStarted = 'NOT_STARTED',
  Uncompleted = 'UNCOMPLETED',
}

export enum AnTaskConstraint {
  All = 'ALL',
  NoTask = 'NO_TASK',
  Task = 'TASK',
}

export enum AnalyticsMetricKey {
  /** Процент не зарегистрированных ЗУ */
  NotRegistredPlots = 'NOT_REGISTRED_PLOTS',
  /** Налоговый потенциал */
  PotencialEarn = 'POTENCIAL_EARN',
}

/** Фильтр для расчета зарплат обходчиков */
export type AntSalaryFilterInput = {
  /** Дата начала диапазона (YYYY-MM-DD) */
  fromDate: Scalars['Date'];
  /** id региона */
  regionIds?: InputMaybe<Array<Scalars['ID']>>;
  /** Название тарифа для расчета зп */
  tariffName?: InputMaybe<Scalars['String']>;
  /** Дата конца диапазона (YYYY-MM-DD) */
  toDate: Scalars['Date'];
  /** id границ работ */
  wbIds: Array<Scalars['ID']>;
};

/** Возвращаемое значение, зарплаты специалистов */
export type AntSalaryOutput = {
  __typename?: 'AntSalaryOutput';
  details?: Maybe<Array<Maybe<AntSalaryRecordOutput>>>;
  userId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfo>;
};

/** Возвращаемое значение, элемент зарплаты */
export type AntSalaryRecordOutput = {
  __typename?: 'AntSalaryRecordOutput';
  /** Тип поля */
  columnType?: Maybe<ObjectProperty>;
  /** Тип объекта (ЗУ/ОКС) */
  contentType?: Maybe<ContentType>;
  /** ЗП по данному типу поля и типу объекта */
  total?: Maybe<Scalars['Int']>;
};

export type AssignedUserResponse = {
  __typename?: 'AssignedUserResponse';
  id: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  roles?: Maybe<Array<Maybe<Role>>>;
  userInfo?: Maybe<UserInfo>;
  username: Scalars['String'];
  workBorders?: Maybe<Array<Maybe<BorderOutput>>>;
};

export type AssignedUserTasksInput = {
  objectStatuses?: InputMaybe<Array<InputMaybe<TaskObjectStatus>>>;
};

export enum AuditStatus {
  Created = 'CREATED',
  Deleted = 'DELETED',
  Updated = 'UPDATED',
}

/**
 * Пакетное редактирование данных пользователя и адреса
 * если address или  ownerData null то данный объект игнорируется(данные не изменяются)
 * ошибка если:
 * - objects пуст
 * - address и ownerData null
 */
export type BatchEditRequest = {
  /** Адрес */
  address?: InputMaybe<AddressBatchEditInput>;
  homeOwnershipId: Scalars['ID'];
  objects: Array<InputMaybe<MapObjectIdentityInput>>;
  /** ФИО */
  ownerData?: InputMaybe<PersonInfo>;
};

/** Фильтр Макет 1 */
export type BoBreachesCountFilterInput = {
  /** Нарушения */
  breaches?: InputMaybe<Array<InputMaybe<BreachReason>>>;
  /** Фильтр по типу объекта */
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  /** Дата начала диапазона (YYYY-MM-DD) */
  fromDate: Scalars['Date'];
  /** id региона */
  regionIds: Array<Scalars['ID']>;
  /** Дата конца диапазона (YYYY-MM-DD) */
  toDate: Scalars['Date'];
  /** id границ работ */
  wbIds: Array<Scalars['ID']>;
  /** Флаг 'учитывать историю'. Если true, то будут возвращено полное кол-во действий по смене нарушений, иначе количество проставленых финальных нарушений */
  withHistory: Scalars['Boolean'];
};

/** Фильтр Макет 2 */
export type BoBreachesDynamicFilterInput = {
  /** Нарушения */
  breaches?: InputMaybe<Array<InputMaybe<BreachReason>>>;
  /** Фильтр по типу объекта */
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  /** Дата начала диапазона (YYYY-MM-DD) */
  fromDate: Scalars['Date'];
  /** id региона */
  regionIds: Array<Scalars['ID']>;
  /** Дата конца диапазона (YYYY-MM-DD) */
  toDate: Scalars['Date'];
  /** id границ работ */
  wbIds: Array<Scalars['ID']>;
};

/** Фильтр Макет 3 */
export type BoProcessingCountFilterInput = {
  breaches?: InputMaybe<Array<InputMaybe<BreachReason>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  fromDate: Scalars['Date'];
  processingStatuses?: InputMaybe<Array<InputMaybe<ProcessingStatusValue>>>;
  regionIds: Array<Scalars['ID']>;
  toDate: Scalars['Date'];
  wbIds: Array<Scalars['ID']>;
  withHistory: Scalars['Boolean'];
};

/** Фильтр Макет 4 */
export type BoProcessingDynamicFilterInput = {
  breaches?: InputMaybe<Array<InputMaybe<BreachReason>>>;
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  fromDate: Scalars['Date'];
  processingStatuses?: InputMaybe<Array<InputMaybe<ProcessingStatusValue>>>;
  regionIds: Array<Scalars['ID']>;
  toDate: Scalars['Date'];
  wbIds: Array<Scalars['ID']>;
};

export type BorderOutput = {
  __typename?: 'BorderOutput';
  bType?: Maybe<WorkBorderType>;
  cityName?: Maybe<Scalars['String']>;
  geo: Geometry;
  id: Scalars['ID'];
  name: Scalars['String'];
  parentId?: Maybe<Scalars['ID']>;
};

export type BreachAddRequest = {
  breachReason: BreachReason;
  contentType: ContentType;
  objectId: Scalars['ID'];
};

/** Макет 2 */
export type BreachAnalyticDynamicOutput = {
  __typename?: 'BreachAnalyticDynamicOutput';
  details?: Maybe<Array<Maybe<BreachDynamicDetails>>>;
  userId: Scalars['ID'];
  userInfo?: Maybe<UserInfo>;
};

/** Макет 1 */
export type BreachAnalyticOutput = {
  __typename?: 'BreachAnalyticOutput';
  details?: Maybe<Array<Maybe<RecordDetails>>>;
  userId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfo>;
};

export type BreachAudit = {
  __typename?: 'BreachAudit';
  contentType: ContentType;
  fio?: Maybe<Scalars['String']>;
  objectId: Scalars['ID'];
  status: AuditStatus;
  timestamp: Scalars['DateTime'];
  user?: Maybe<Scalars['String']>;
  value: BreachReason;
};

export type BreachAuditOutput = {
  __typename?: 'BreachAuditOutput';
  data?: Maybe<Array<Maybe<BreachAudit>>>;
};

export type BreachDynamicDetails = {
  __typename?: 'BreachDynamicDetails';
  breach?: Maybe<BreachReason>;
  contentType?: Maybe<ContentType>;
  endCount?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  startCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
};

export type BreachOutput = {
  __typename?: 'BreachOutput';
  authorId?: Maybe<Scalars['ID']>;
  breachReason: BreachReason;
  contentType: ContentType;
  createDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  objectId: Scalars['ID'];
};

/** Тип нарушения */
export enum BreachReason {
  /** Отсутствует регистрация прав */
  MissingRegistrationRights = 'MISSING_REGISTRATION_RIGHTS',
  /** Нет нарушения */
  None = 'NONE',
  /** Не целевое использование земли */
  NonPurposeLandUsage = 'NON_PURPOSE_LAND_USAGE',
  /** Отсутствует постановка на кадастровый учет */
  NoCadastralRegistration = 'NO_CADASTRAL_REGISTRATION',
  /** Не определен */
  Undefined = 'UNDEFINED',
}

/** Макеты 1,2 */
export type BreachRecordDetail = {
  __typename?: 'BreachRecordDetail';
  breach?: Maybe<BreachReason>;
  contentType?: Maybe<ContentType>;
  count?: Maybe<Scalars['Int']>;
};

/** Передача задания другому исполнителю */
export type ChangeExecutorInput = {
  /** Новый дэдлайн.Должен быть позже сегодняшней даты */
  newDeadLine?: InputMaybe<Scalars['Date']>;
  /** Идентификатор нового исполнителя */
  newExecutorId: Scalars['ID'];
  /** Идентификатор задачи */
  taskId: Scalars['ID'];
};

export type CommentLabelOutput = {
  __typename?: 'CommentLabelOutput';
  contentType: ContentType;
  id: Scalars['ID'];
  label: CommentLabelValue;
  objectId: Scalars['ID'];
};

export type CommentLabelUpdateInput = {
  contentType: ContentType;
  data?: InputMaybe<Array<InputMaybe<CommentLabelValue>>>;
  objectId: Scalars['ID'];
};

/** Тело запроса при обновлении примечания */
export type CommentLabelUpdateInputV2 = {
  data?: InputMaybe<Array<InputMaybe<CommentLabelValue>>>;
  noInfoReason?: InputMaybe<NoInfoReasonRequest>;
  objectId: Scalars['ID'];
  objectType: ContentType;
  taskId?: InputMaybe<Scalars['ID']>;
  taskObjectId?: InputMaybe<Scalars['ID']>;
};

export type CommentLabelUpdateOutput = {
  __typename?: 'CommentLabelUpdateOutput';
  commentLabel?: Maybe<Array<Maybe<CommentLabelOutput>>>;
  noInfoReason?: Maybe<NoInfoReasonResponse>;
};

/** Тэги комментов */
export enum CommentLabelValue {
  /** Дом заброшен! */
  AbandonedHouse = 'ABANDONED_HOUSE',
  /** Адрес не зарегистрирован */
  AddressNotRegistered = 'ADDRESS_NOT_REGISTERED',
  /** Объект незавершенного строительства! */
  ConstructionInProgress = 'CONSTRUCTION_IN_PROGRESS',
  /**
   * Указаны ФИО и телефон родственника
   * Уточнить степень родства
   */
  ContactOfRelative = 'CONTACT_OF_RELATIVE',
  /**
   * Не проживают
   * Указать дополнительную информацию от соседей
   */
  DoNotReside = 'DO_NOT_RESIDE',
  /** Пустой участок! */
  EmptyPlot = 'EMPTY_PLOT',
  /** Собственника не удалось найти! */
  OwnerNotFound = 'OWNER_NOT_FOUND',
  /** Отказываются разговаривать */
  RefuseToTalk = 'REFUSE_TO_TALK',
  /**
   * Проживают. Зайти повторно
   * Указать дату и цель следующего посещения
   */
  VisitAgain = 'VISIT_AGAIN',
}

export type ContactInfo = {
  __typename?: 'ContactInfo';
  regionInfo?: Maybe<RegionContact>;
  workBorderInfo?: Maybe<WorkBorderContact>;
};

export enum ContentType {
  Oks = 'OKS',
  Plot = 'PLOT',
  Room = 'ROOM',
}

export type CountData = {
  __typename?: 'CountData';
  hoCount?: Maybe<Scalars['Int']>;
  oksCount?: Maybe<Scalars['Int']>;
  plotCount?: Maybe<Scalars['Int']>;
};

export type DadataAddress = {
  __typename?: 'DadataAddress';
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  areaTypeFull?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityTypeFull?: Maybe<Scalars['String']>;
  fiasActualityState: FiasActuality;
  fiasId?: Maybe<Scalars['UUID']>;
  fiasLevel: FiasLevel;
  house?: Maybe<Scalars['String']>;
  houseTypeFull?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  qcGeo?: Maybe<QcGeo>;
  settlement?: Maybe<Scalars['String']>;
  settlementTypeFull?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetTypeFull?: Maybe<Scalars['String']>;
};

export type DadataAddressInput = {
  address?: InputMaybe<Scalars['String']>;
  area?: InputMaybe<Scalars['String']>;
  areaTypeFull?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  cityTypeFull?: InputMaybe<Scalars['String']>;
  fiasActualityState: FiasActuality;
  fiasId: Scalars['UUID'];
  fiasLevel: FiasLevel;
  house?: InputMaybe<Scalars['String']>;
  houseTypeFull?: InputMaybe<Scalars['String']>;
  lat?: InputMaybe<Scalars['Float']>;
  lon?: InputMaybe<Scalars['Float']>;
  qcGeo?: InputMaybe<QcGeo>;
  settlement?: InputMaybe<Scalars['String']>;
  settlementTypeFull?: InputMaybe<Scalars['String']>;
  street?: InputMaybe<Scalars['String']>;
  streetTypeFull?: InputMaybe<Scalars['String']>;
};

export type DadataAddressOutput = {
  __typename?: 'DadataAddressOutput';
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['String']>;
  areaTypeFull?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  cityTypeFull?: Maybe<Scalars['String']>;
  fiasActualityState?: Maybe<FiasActuality>;
  fiasId?: Maybe<Scalars['UUID']>;
  fiasLevel?: Maybe<FiasLevel>;
  house?: Maybe<Scalars['String']>;
  houseTypeFull?: Maybe<Scalars['String']>;
  lat?: Maybe<Scalars['Float']>;
  lon?: Maybe<Scalars['Float']>;
  qcGeo?: Maybe<QcGeo>;
  settlement?: Maybe<Scalars['String']>;
  settlementTypeFull?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  streetTypeFull?: Maybe<Scalars['String']>;
};

/** Dadata section */
export type DadataGeoSearchInput = {
  /** Количество результатов (максимум — 20) */
  count?: InputMaybe<Scalars['Int']>;
  lat: Scalars['Float'];
  lon: Scalars['Float'];
  /** Радиус поиска в метрах (максимум – 1000) */
  radius?: InputMaybe<Scalars['Int']>;
};

export type DadataSuggestAddressInput = {
  /** range 1-20 */
  count?: InputMaybe<Scalars['Int']>;
  query: Scalars['String'];
};

export type DataSourceEgrnOksSearchOutput = {
  __typename?: 'DataSourceEgrnOksSearchOutput';
  data?: Maybe<Array<Maybe<EgrnOksResponse>>>;
};

export type DataSourceEgrnPlotSearchOutput = {
  __typename?: 'DataSourceEgrnPlotSearchOutput';
  data?: Maybe<Array<Maybe<EgrnPlotResponse>>>;
};

/** Тип реестра */
export enum DataSourceKind {
  /** Фильтр ЦТ */
  DtrCore = 'DTR_CORE',
  /** Файлы выгрузки КПТ */
  Kpt = 'KPT',
  /** Махачкала */
  MahDs = 'MAH_DS',
  RuBorder = 'RU_BORDER',
}

export type DataSourceKptOksSearchOutput = {
  __typename?: 'DataSourceKptOksSearchOutput';
  id: Scalars['ID'];
};

export type DataSourceKptPlotSearchOutput = {
  __typename?: 'DataSourceKptPlotSearchOutput';
  id: Scalars['ID'];
};

export type DataSourceOksSearchOutput = {
  __typename?: 'DataSourceOksSearchOutput';
  id: Scalars['ID'];
};

export type DataSourceSearchInput = {
  cadnum: Scalars['String'];
};

export type DataSourceSearchOutput = {
  __typename?: 'DataSourceSearchOutput';
  id: Scalars['ID'];
  kind: DataSourceKind;
};

export type DeleteAddressInput = {
  /** Тип объекта у которого удаляем адрес */
  entityType?: InputMaybe<EntityType>;
  /** Идентификатор объекта у которого удаляем адрес */
  id: Scalars['ID'];
};

export type DeleteBreachesInput = {
  breachReason: BreachReason;
  contentType: ContentType;
  objectId: Scalars['ID'];
};

export type DeleteFromHomeOwnership = {
  /** айди объекта */
  contentId: Scalars['ID'];
  /** тип объекта */
  contentType: ContentType;
  /** айди домовладения в котором удаляем */
  hoId: Scalars['ID'];
};

export enum Direction {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type DynamicDto = BreachDynamicDetails | ProcessingDynamicDetails;

export type EgrnOksResponse = {
  __typename?: 'EgrnOksResponse';
  /** Кадастровый номер */
  cad_number: Scalars['String'];
  /** Государственная регистрация права Дата */
  grpDate?: Maybe<Scalars['String']>;
  /** Государственная регистрация права Номер */
  grpNum?: Maybe<Scalars['String']>;
  /** Государственная регистрация права Вид */
  grpType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Ограничение прав и обременение Дата */
  opoDate?: Maybe<Scalars['String']>;
  /** Ограничение прав и обременение Номер */
  opoNum?: Maybe<Scalars['String']>;
  /** Ограничение прав и обременение Вид */
  opoType?: Maybe<Scalars['String']>;
};

export type EgrnPlotResponse = {
  __typename?: 'EgrnPlotResponse';
  /** Адрес ФИАС */
  addressEgrn?: Maybe<Scalars['String']>;
  /** Адрес ЕГРН */
  addressFias?: Maybe<Scalars['String']>;
  /** Кадастровый номер */
  cad_number: Scalars['String'];
  /** Государственная регистрация права Дата */
  grpDate?: Maybe<Scalars['String']>;
  /** Государственная регистрация права Номер */
  grpNum?: Maybe<Scalars['String']>;
  /** Государственная регистрация права Вид */
  grpType?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  /** Ограничение прав и обременение Дата */
  opoDate?: Maybe<Scalars['String']>;
  /** Ограничение прав и обременение Номер */
  opoNum?: Maybe<Scalars['String']>;
  /** Ограничение прав и обременение Вид */
  opoType?: Maybe<Scalars['String']>;
};

/** Данные зашитые в сущности */
export enum EntityProperty {
  Addr = 'ADDR',
  Cadnum = 'CADNUM',
  Comment = 'COMMENT',
  Cost = 'COST',
  Floor = 'FLOOR',
  Owners = 'OWNERS',
  Phone = 'PHONE',
  Usage = 'USAGE',
  UCadnum = 'U_CADNUM',
}

/** Подтип реестра */
export enum EntitySearchSubtype {
  Oks = 'OKS',
  Plot = 'PLOT',
}

export enum EntityType {
  Oks = 'OKS',
  Plot = 'PLOT',
  Room = 'ROOM',
}

export enum FiasActuality {
  Actual = 'ACTUAL',
  Removed = 'REMOVED',
  Renamed = 'RENAMED',
  Resubordinated = 'RESUBORDINATED',
}

export enum FiasLevel {
  Area = 'AREA',
  City = 'CITY',
  Cityarea = 'CITYAREA',
  Country = 'COUNTRY',
  Estate = 'ESTATE',
  Flat = 'FLAT',
  ForeignOrEmpty = 'FOREIGN_OR_EMPTY',
  House = 'HOUSE',
  Plot = 'PLOT',
  Region = 'REGION',
  Settlement = 'SETTLEMENT',
  Street = 'STREET',
}

export type FieldFilter = {
  emptyField?: InputMaybe<Scalars['Boolean']>;
  field?: InputMaybe<FieldType>;
  value?: InputMaybe<Scalars['String']>;
};

export enum FieldType {
  CadNum = 'CAD_NUM',
  Owner = 'OWNER',
  Phone = 'PHONE',
  Usage = 'USAGE',
}

export type FileAttachment = {
  __typename?: 'FileAttachment';
  fileId?: Maybe<Scalars['ID']>;
  filename?: Maybe<Scalars['String']>;
};

export type FileInfo = {
  __typename?: 'FileInfo';
  fileId: Scalars['ID'];
  /** Ссылка на скачивание уведомления */
  link: Scalars['UUID'];
};

export type FileInput = {
  entityId: Scalars['ID'];
  entityType: EntityType;
  files: Array<FileObj>;
};

export type FileObj = {
  fileType?: InputMaybe<FileType>;
  id: Scalars['ID'];
};

export type FileOutput = {
  __typename?: 'FileOutput';
  entityId?: Maybe<Scalars['Int']>;
  entityType?: Maybe<EntityType>;
  fileId?: Maybe<Scalars['Int']>;
  fileType?: Maybe<FileType>;
  id?: Maybe<Scalars['ID']>;
};

export enum FileType {
  Document = 'DOCUMENT',
  Photo = 'PHOTO',
}

/** Даты с и по для выборки в диапазоне */
export type FilterDateInput = {
  endDate: Scalars['Date'];
  startDate: Scalars['Date'];
};

export type FilterInput = {
  /** Фильтр по адресам */
  address?: InputMaybe<AddressFilterInput>;
  dataFilter?: InputMaybe<Array<InputMaybe<FieldFilter>>>;
  /** Нарушения и статусы обработки */
  filters?: InputMaybe<Array<InputMaybe<FilterValue>>>;
  pas?: InputMaybe<PaginationAndSortingInput>;
  workBorders?: InputMaybe<WorkBorderFilterInput>;
};

export enum FilterType {
  /** нарушения */
  BreachReason = 'BREACH_REASON',
  /** статус обработки */
  ProcessingStatus = 'PROCESSING_STATUS',
}

export type FilterValue = {
  /** Дата от. Опционально */
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  /** Дата до. Опционально */
  dateTo?: InputMaybe<Scalars['DateTime']>;
  /** Тип фильтра */
  kind?: InputMaybe<FilterType>;
  /** Искомое значения */
  value?: InputMaybe<Array<Scalars['String']>>;
};

export type GeoInput = {
  coordinates?: InputMaybe<
    Array<InputMaybe<Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>>>
  >;
  type: GeoType;
};

export type GeoOutput = {
  __typename?: 'GeoOutput';
  geo?: Maybe<Geometry>;
  geomId: Scalars['ID'];
  hintValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  status?: Maybe<PlotDataStatus>;
};

export type GeoSearchInput = {
  entityType?: InputMaybe<EntityType>;
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  layerId?: InputMaybe<Scalars['ID']>;
};

export type GeoSearchOutput = {
  __typename?: 'GeoSearchOutput';
  geo?: Maybe<Geometry>;
  id?: Maybe<Scalars['ID']>;
};

/** Тип геопоиска */
export enum GeoSearchType {
  Intersect = 'INTERSECT',
  Overlaps = 'OVERLAPS',
  Within = 'WITHIN',
}

export enum GeoType {
  MultiPolygon = 'MultiPolygon',
  Point = 'Point',
  Polygon = 'Polygon',
}

export type Geometry = {
  __typename?: 'Geometry';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>>>;
  type: GeoType;
};

export type GeometryRequestByIdsDto = {
  ids: Array<Scalars['ID']>;
};

/**  Мапим дто редиса на эту сущность и отдаём на фронт */
export type GeometryResponseDto = {
  __typename?: 'GeometryResponseDto';
  contentType: ContentType;
  geo: Geometry;
  id: Scalars['ID'];
  workBorderId: Scalars['ID'];
};

export type GroupDto = {
  __typename?: 'GroupDTO';
  id?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
};

export type HeatMapInput = {
  geo: GeoInput;
  objectStatus: TaskObjectStatus;
  step?: InputMaybe<Scalars['Int']>;
};

export type HeatMapOutput = {
  __typename?: 'HeatMapOutput';
  count: Scalars['Int'];
  geo: Point;
};

export enum HintField {
  Address = 'ADDRESS',
  Cadnum = 'CADNUM',
  Cost = 'COST',
}

export type HintInput = {
  hintField: HintField;
  ids: Array<Scalars['ID']>;
  layerId: Scalars['ID'];
};

export type HintOutput = {
  __typename?: 'HintOutput';
  hintValue?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type HomeOwnershipOutput = {
  __typename?: 'HomeOwnershipOutput';
  /** Информация для связи с администрацией */
  contact?: Maybe<ContactInfo>;
  createDateTime?: Maybe<Scalars['DateTime']>;
  deleteDateTime?: Maybe<Scalars['DateTime']>;
  geom?: Maybe<Geometry>;
  id: Scalars['ID'];
  modifyStatus?: Maybe<ModifyStatus>;
  /** Сформированные / отправленые бланки уведомлений */
  notifications?: Maybe<Array<Maybe<NotificationInfo>>>;
  oks?: Maybe<Array<Maybe<Oks>>>;
  plot?: Maybe<PlotDto>;
  plotId: Scalars['ID'];
  /** Статусы номеров и документов по домовладению */
  status?: Maybe<SummaryStatus>;
  updateDateTime?: Maybe<Scalars['DateTime']>;
  visitStatus?: Maybe<VisitStatus>;
  workBorderId: Scalars['ID'];
};

export type HomeOwnershipOutputBo = {
  __typename?: 'HomeOwnershipOutputBo';
  countData?: Maybe<CountData>;
  list?: Maybe<Array<Maybe<HomeOwnershipOutputBoData>>>;
  pagination?: Maybe<PaginationOutput>;
};

export type HomeOwnershipOutputBoData = {
  __typename?: 'HomeOwnershipOutputBoData';
  executor?: Maybe<UserInfo>;
  ho?: Maybe<HomeOwnershipOutput>;
  taskId?: Maybe<Scalars['ID']>;
  taskObjectId?: Maybe<Scalars['ID']>;
};

/** Объект задачи - домовладение */
export type HomeOwnershipTaskObject = TaskObject & {
  __typename?: 'HomeOwnershipTaskObject';
  createDateTime?: Maybe<Scalars['DateTime']>;
  deleteDateTime?: Maybe<Scalars['DateTime']>;
  geo?: Maybe<GeoSearchOutput>;
  homeOwnership?: Maybe<HomeOwnershipOutput>;
  id: Scalars['ID'];
  modifyStatus?: Maybe<ModifyStatus>;
  status?: Maybe<TaskObjectStatus>;
  updateDateTime?: Maybe<Scalars['DateTime']>;
};

/** Статус указывающий на принадлежность объекта к таске */
export enum InTaskStatusInput {
  All = 'ALL',
  InTask = 'IN_TASK',
  NotInTask = 'NOT_IN_TASK',
}

/** Статус указывающий на принадлежность объекта к таске */
export enum InTaskStatusOutput {
  InTask = 'IN_TASK',
  NotInTask = 'NOT_IN_TASK',
}

/** Слой данных */
export type Layer = {
  __typename?: 'Layer';
  color: Scalars['String'];
  contentType: ContentType;
  count: Scalars['Int'];
  editable: Scalars['Boolean'];
  fillColor?: Maybe<Scalars['String']>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** по умолчанию - запрещено */
  isAllowedToExport?: Maybe<Scalars['Boolean']>;
  /** по умолчанию - запрещено */
  isAllowedToImport?: Maybe<Scalars['Boolean']>;
  layerType: LayerType;
  name: Scalars['String'];
  opacity: Scalars['Int'];
  taskLayer?: Maybe<Scalars['Boolean']>;
  updatable: Scalars['Boolean'];
  visible: Scalars['Boolean'];
  zMax: Scalars['Int'];
  zMin: Scalars['Int'];
};

export type LayerAddInput = {
  color?: InputMaybe<Scalars['String']>;
  contentType: ContentType;
  editable: Scalars['Boolean'];
  fillColor?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  /** по умолчанию - запрещено */
  isAllowedToExport?: InputMaybe<Scalars['Boolean']>;
  /** по умолчанию - запрещено */
  isAllowedToImport?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  opacity?: InputMaybe<Scalars['Int']>;
  taskLayer: Scalars['Boolean'];
  type: LayerType;
  updatable: Scalars['Boolean'];
  visible?: InputMaybe<Scalars['Boolean']>;
  zMax?: InputMaybe<Scalars['Int']>;
  zMin?: InputMaybe<Scalars['Int']>;
};

export enum LayerContentType {
  Oks = 'OKS',
  Plot = 'PLOT',
}

export type LayerDto = {
  __typename?: 'LayerDTO';
  color: Scalars['String'];
  contentType: ContentType;
  editable?: Maybe<Scalars['Boolean']>;
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  opacity: Scalars['Int'];
  type?: Maybe<LayerType>;
  visible: Scalars['Boolean'];
  zMax: Scalars['Int'];
  zMin: Scalars['Int'];
};

/** Группы слоев */
export type LayerGroup = {
  __typename?: 'LayerGroup';
  id: Scalars['ID'];
  layers?: Maybe<Array<Maybe<Layer>>>;
  name: Scalars['String'];
  parentId?: Maybe<Scalars['Int']>;
};

/** Объекты на слое */
export type LayerObject = {
  __typename?: 'LayerObject';
  contentType: ContentType;
  editable: Scalars['Boolean'];
  groupId?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<Maybe<MapObject>>>;
  type: LayerType;
};

export enum LayerType {
  BorderArea = 'BORDER_AREA',
  Squatting = 'SQUATTING',
  System = 'SYSTEM',
  SystemDefault = 'SYSTEM_DEFAULT',
  SystemPkk = 'SYSTEM_PKK',
  User = 'USER',
}

export type LayerUpdateInput = {
  color: Scalars['String'];
  editable: Scalars['Boolean'];
  fillColor?: InputMaybe<Scalars['String']>;
  groupId?: InputMaybe<Scalars['Int']>;
  id: Scalars['ID'];
  isAllowedToExport: Scalars['Boolean'];
  isAllowedToImport: Scalars['Boolean'];
  name: Scalars['String'];
  opacity?: InputMaybe<Scalars['Int']>;
  taskLayer: Scalars['Boolean'];
  updatable: Scalars['Boolean'];
  visible?: InputMaybe<Scalars['Boolean']>;
  zMax?: InputMaybe<Scalars['Int']>;
  zMin?: InputMaybe<Scalars['Int']>;
};

/** Получение и фильтрация задач для роли менеджера */
export type ManagerFilterTasksInput = {
  executorIds: Array<Scalars['ID']>;
  regionIds: Array<Scalars['ID']>;
};

export type MapObject = {
  __typename?: 'MapObject';
  address?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  layerId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  type: LayerType;
};

export type MapObjectFileAttachments = {
  __typename?: 'MapObjectFileAttachments';
  docs?: Maybe<Array<Maybe<FileAttachment>>>;
  photos?: Maybe<Array<Maybe<FileAttachment>>>;
};

export type MapObjectIdentityInput = {
  contentType: ContentType;
  objectId: Scalars['ID'];
};

export type MapObjectInput = {
  X: Scalars['Int'];
  Y: Scalars['Int'];
  Z: Scalars['Int'];
  contentType: ContentType;
  /** Область поиска */
  geo: GeoInput;
  /**
   * Список слоев по которым производить поиск на карте.
   * если не указан то поиск производится по всем слоям помеченым флагом
   * taskLayer
   */
  ids?: InputMaybe<Array<Scalars['ID']>>;
  searchType: GeoSearchType;
  status: InTaskStatusInput;
};

export type MapObjectOutput = {
  __typename?: 'MapObjectOutput';
  contentType?: Maybe<ContentType>;
  geo?: Maybe<Geometry>;
  geomId: Scalars['ID'];
  id: Scalars['ID'];
  layerId: Scalars['ID'];
  status?: Maybe<InTaskStatusOutput>;
  /** Признак нахождения объекта в задании когда либо */
  wasInTask: Scalars['Boolean'];
};

export type MergeInput = {
  /**
   * Домовладение источник.
   * откуда мержим.
   */
  sourceId: Scalars['ID'];
  /**
   * Домовладение получатель.
   * куда мержим.
   */
  targetId: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type MergeOutput = {
  __typename?: 'MergeOutput';
  res?: Maybe<Array<Maybe<PlotIntersectionResultOutput>>>;
  /** Участок в домовладении */
  sourceId: Scalars['ID'];
};

export type MetricAnalyticsInput = {
  /** если значение не передано или пусто - по всем доступным значениям */
  ids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Параметр */
  metric: AnalyticsMetricKey;
  /** ссылка на родительские записи. если значение не передано или пусто - по всем доступным значениям */
  parentIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type MetricAnalyticsOutput = {
  id: Scalars['ID'];
  kMetric: AnalyticsMetricKey;
  kName: Scalars['String'];
  unit: Scalars['String'];
  vMetric: Scalars['Int'];
};

export enum ModifyStatus {
  Merged = 'MERGED',
  None = 'NONE',
  Splitted = 'SPLITTED',
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Сохранение адреса дадаты и детализации */
  addAddress?: Maybe<AddressOutput>;
  /** обновление адреса объекта */
  addAddressV2?: Maybe<AddressUpdateOutput>;
  addGroup?: Maybe<LayerGroup>;
  addLayer?: Maybe<Layer>;
  addObjectBreach?: Maybe<ObjectBreachOutput>;
  addObjectBreaches?: Maybe<ObjectBreachOutput>;
  addOks?: Maybe<Oks>;
  addRoom?: Maybe<Room>;
  /** Добавить конфигурацию зарплаты */
  addSalaryTariff?: Maybe<SalaryTariffOutput>;
  addTask?: Maybe<TaskResponse>;
  batchEdit?: Maybe<HomeOwnershipOutput>;
  blockUser?: Maybe<UserResponse>;
  /** Передача задания другому исполнителю */
  changeExecutor?: Maybe<TaskResponse>;
  /**
   * Удаление детализации
   * @deprecated No longer supported
   */
  deleteAddress?: Maybe<Scalars['Boolean']>;
  /** Удаление детализации */
  deleteAddressV2?: Maybe<Scalars['Boolean']>;
  /** Удалить конфигурацию зарплаты */
  deleteAntSalaryTariff?: Maybe<Array<Maybe<SalaryTariffOutput>>>;
  deleteFromHomeOwnership?: Maybe<HomeOwnershipOutput>;
  deleteGroup?: Maybe<Scalars['Boolean']>;
  deleteLayer?: Maybe<Scalars['Boolean']>;
  deleteNotification?: Maybe<Scalars['Boolean']>;
  deleteObjectBreach?: Maybe<Scalars['Boolean']>;
  deleteOks?: Maybe<Scalars['Boolean']>;
  deleteOksAntRole?: Maybe<Scalars['Boolean']>;
  deletePlot?: Maybe<Scalars['Boolean']>;
  deleteRoom?: Maybe<Scalars['Boolean']>;
  deleteTask?: Maybe<Scalars['Boolean']>;
  deleteUser?: Maybe<Scalars['Boolean']>;
  homeOwnershipFromPlots?: Maybe<Array<Maybe<HomeOwnershipOutput>>>;
  mergeHomeOwnershipV2?: Maybe<TaskObject>;
  registerUser?: Maybe<UserResponse>;
  registerUserV2?: Maybe<UserResponse>;
  splitHomeOwnershipV2?: Maybe<Array<Maybe<TaskObject>>>;
  unblockUser?: Maybe<UserResponse>;
  /** Обновить конфигурацию зарплаты */
  updateAntSalaryTariff?: Maybe<SalaryTariffOutput>;
  updateCommentLabels?: Maybe<Array<Maybe<CommentLabelOutput>>>;
  /** обновление примечания */
  updateCommentLabelsV2?: Maybe<CommentLabelUpdateOutput>;
  updateLayer?: Maybe<Layer>;
  /** Изменение слоя объекта на карте. Функционал для администратора */
  updateMapObjectLayer?: Maybe<Scalars['Boolean']>;
  /** апдейт объекта в таске по айди таски и айди объекта */
  updateNoDocReason?: Maybe<NoDocReasonOutput>;
  /** обновление информации об ОКС, зашитой в объекте */
  updateOksInfoV2?: Maybe<UpdateOksOutput>;
  /** обновление информации о ЗУ, зашитой в объекте */
  updatePlotInfoV2?: Maybe<UpdatePlotOutput>;
  updateProcessingStatus?: Maybe<ProcessingStatusOutput>;
  updateRoomInfoV2?: Maybe<UpdateRoomOutput>;
  /** апдейт статуса объекта в таске по айди таски и айди объекта */
  updateTaskObjectStatus?: Maybe<UpdateTaskObjectOutput>;
  updateTaskVisitStatus?: Maybe<TaskVisitOutput>;
  updateUser?: Maybe<UserResponse>;
  updateUserV2?: Maybe<UserResponse>;
};

export type MutationAddAddressArgs = {
  dto: AddressInput;
};

export type MutationAddAddressV2Args = {
  input?: InputMaybe<AddressInputV2>;
};

export type MutationAddGroupArgs = {
  groupDto?: InputMaybe<AddGroupInput>;
};

export type MutationAddLayerArgs = {
  dto: LayerAddInput;
};

export type MutationAddObjectBreachArgs = {
  dto?: InputMaybe<AddBreachInput>;
};

export type MutationAddObjectBreachesArgs = {
  dto?: InputMaybe<AddBreachesInput>;
};

export type MutationAddOksArgs = {
  oksDto: AddOksInput;
};

export type MutationAddRoomArgs = {
  dto: AddRoomInput;
};

export type MutationAddSalaryTariffArgs = {
  input: SalaryTariffInput;
};

export type MutationAddTaskArgs = {
  taskRequest: TaskRequest;
};

export type MutationBatchEditArgs = {
  dto?: InputMaybe<BatchEditRequest>;
};

export type MutationBlockUserArgs = {
  id: Scalars['ID'];
};

export type MutationChangeExecutorArgs = {
  dto: ChangeExecutorInput;
};

export type MutationDeleteAddressArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteAddressV2Args = {
  dto: DeleteAddressInput;
};

export type MutationDeleteAntSalaryTariffArgs = {
  tariffName?: InputMaybe<Scalars['String']>;
};

export type MutationDeleteFromHomeOwnershipArgs = {
  dto?: InputMaybe<DeleteFromHomeOwnership>;
};

export type MutationDeleteGroupArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteLayerArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteNotificationArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteObjectBreachArgs = {
  dto?: InputMaybe<DeleteBreachesInput>;
};

export type MutationDeleteOksArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteOksAntRoleArgs = {
  id: Scalars['ID'];
  taskId: Scalars['ID'];
};

export type MutationDeletePlotArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteRoomArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteTaskArgs = {
  id: Scalars['ID'];
};

export type MutationDeleteUserArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type MutationHomeOwnershipFromPlotsArgs = {
  ids: Array<InputMaybe<Scalars['ID']>>;
  wbId: Scalars['ID'];
};

export type MutationMergeHomeOwnershipV2Args = {
  dto?: InputMaybe<MergeInput>;
};

export type MutationRegisterUserArgs = {
  dto?: InputMaybe<RegisterUserInput>;
};

export type MutationRegisterUserV2Args = {
  dto?: InputMaybe<RegisterUserInputV2>;
};

export type MutationSplitHomeOwnershipV2Args = {
  dto?: InputMaybe<SplitHomeOwnershipInput>;
};

export type MutationUnblockUserArgs = {
  id: Scalars['ID'];
};

export type MutationUpdateAntSalaryTariffArgs = {
  input?: InputMaybe<SalaryTariffInput>;
};

export type MutationUpdateCommentLabelsArgs = {
  dto?: InputMaybe<CommentLabelUpdateInput>;
};

export type MutationUpdateCommentLabelsV2Args = {
  input?: InputMaybe<CommentLabelUpdateInputV2>;
};

export type MutationUpdateLayerArgs = {
  dto: LayerUpdateInput;
};

export type MutationUpdateMapObjectLayerArgs = {
  input: Array<UpdateObjectLayerInput>;
};

export type MutationUpdateNoDocReasonArgs = {
  dto: NoDocReasonInput;
};

export type MutationUpdateOksInfoV2Args = {
  input?: InputMaybe<UpdateObjectInput>;
};

export type MutationUpdatePlotInfoV2Args = {
  input?: InputMaybe<UpdateObjectInput>;
};

export type MutationUpdateProcessingStatusArgs = {
  dto?: InputMaybe<ProcessingStatusRequest>;
};

export type MutationUpdateRoomInfoV2Args = {
  input?: InputMaybe<UpdateObjectInput>;
};

export type MutationUpdateTaskObjectStatusArgs = {
  dto: UpdateTaskObjectStatusInput;
};

export type MutationUpdateTaskVisitStatusArgs = {
  input: UpdateTaskVisitStatusInput;
};

export type MutationUpdateUserArgs = {
  dto?: InputMaybe<UserUpdateRequest>;
};

export type MutationUpdateUserV2Args = {
  dto?: InputMaybe<UserUpdateRequestV2>;
};

export type NoDocReasonInput = {
  /** Ссылка по типу объекта - id участка или окс */
  contentId: Scalars['ID'];
  /** Тип объекта - участок или окс */
  contentType?: InputMaybe<ContentType>;
  /**
   * Требуется указать срок в случае
   * ON_CLEARANCE
   * NOT_OWNER
   *
   * noDocumentDeadline должна быть позже сегодняшней
   *
   * в остальных случаях дата не нужна
   */
  noDocumentDeadline?: InputMaybe<Scalars['DateTime']>;
  noDocumentReason?: InputMaybe<NoDocumentReason>;
  /** Ссылка на Объект Таски */
  objectId: Scalars['ID'];
  /** Ссылка на Таску */
  taskId: Scalars['ID'];
};

export type NoDocReasonOutput = {
  __typename?: 'NoDocReasonOutput';
  contentId?: Maybe<Scalars['ID']>;
  noDocumentDeadline?: Maybe<Scalars['String']>;
  noDocumentReason?: Maybe<NoDocumentReason>;
};

/** Причины отсутствия документа */
export enum NoDocumentReason {
  /**
   * Документы отданы в другую инстанцию
   * Когда документы отданы в суд, в органы социальной защиты для оформления субсидий и т.п.
   * Уточнить инстанцию и как долго документы будут там находиться.
   */
  DocsInAnotherAuthority = 'DOCS_IN_ANOTHER_AUTHORITY',
  /**
   * Документы на оформлении долго
   * Уточнить, как долго документы находятся на оформлении в администрации ОМСУ.
   */
  LongRegistration = 'LONG_REGISTRATION',
  /** Документ утерян */
  Lost = 'LOST',
  None = 'NONE',
  /**
   * - Документ у родственника(
   * Попросить забрать документы у родственника и предоставить
   * копии в администрацию ОМСУ к определенному сроку.
   */
  NotAvailable = 'NOT_AVAILABLE',
  /**
   * Документ у собственника(
   * Когда в домовладении проживает не сам собственник,
   * а его родственник или знакомый)
   */
  NotOwner = 'NOT_OWNER',
  /** Документы не оформлены */
  NoDocument = 'NO_DOCUMENT',
  /** Информация отсутствует (обходчик не знает почему отсутствуют документы) */
  NoInformation = 'NO_INFORMATION',
  /**
   * Документы на оформлении
   * Уточнить взоможный срок завершения оформления
   */
  OnClearance = 'ON_CLEARANCE',
}

export enum NoInfoReason {
  InfoNotCorrect = 'INFO_NOT_CORRECT',
  InfoNotPresent = 'INFO_NOT_PRESENT',
  RefuseToCooperate = 'REFUSE_TO_COOPERATE',
}

/** Отметка об отсутствии информации по полю */
export type NoInfoReasonRequest = {
  field?: InputMaybe<ObjectProperty>;
  noInfoReason?: InputMaybe<NoInfoReason>;
};

/** Ответ на проставленную причину отсутствия информации */
export type NoInfoReasonResponse = {
  __typename?: 'NoInfoReasonResponse';
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['ID']>;
  infoType?: Maybe<ObjectProperty>;
  objectId?: Maybe<Scalars['ID']>;
  objectType?: Maybe<ContentType>;
  reason?: Maybe<NoInfoReason>;
  userId?: Maybe<Scalars['ID']>;
};

/** Информация об уведомлении */
export type NotificationInfo = {
  __typename?: 'NotificationInfo';
  fileId: Scalars['ID'];
  genDt: Scalars['DateTime'];
  generatedBy: Scalars['String'];
  hoId: Scalars['ID'];
  id: Scalars['ID'];
  recipientName?: Maybe<Scalars['String']>;
  recipientPhone?: Maybe<Scalars['String']>;
  responseId?: Maybe<Scalars['String']>;
  responseMessage?: Maybe<Scalars['String']>;
  responseStatus?: Maybe<SendResponseStatus>;
  sendDt?: Maybe<Scalars['DateTime']>;
  sendMethod?: Maybe<SendNotificationMethod>;
  senderName?: Maybe<Scalars['String']>;
  status?: Maybe<NotificationStatus>;
};

export type NotificationInputV2 = {
  /** Домовладение */
  hoId: Scalars['ID'];
  /** Объекты по которым формируем уведомление */
  objects?: InputMaybe<Array<InputMaybe<MapObjectIdentityInput>>>;
};

export type NotificationOutput = {
  __typename?: 'NotificationOutput';
  data?: Maybe<Array<Maybe<FileInfo>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type NotificationOutputV2 = {
  __typename?: 'NotificationOutputV2';
  data?: Maybe<Array<Maybe<FileInfo>>>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Сформированные / отправленые бланки уведомлений */
  notifications?: Maybe<Array<Maybe<NotificationInfo>>>;
};

export enum NotificationStatus {
  Created = 'CREATED',
  None = 'NONE',
  Sent = 'SENT',
}

export type ObjectBreachOutput = {
  __typename?: 'ObjectBreachOutput';
  data?: Maybe<Array<Maybe<BreachOutput>>>;
};

/** Все данные связанные с объектом */
export enum ObjectProperty {
  Addr = 'ADDR',
  Cadnum = 'CADNUM',
  Comment = 'COMMENT',
  CommentLabel = 'COMMENT_LABEL',
  Cost = 'COST',
  Document = 'DOCUMENT',
  Floor = 'FLOOR',
  NoDocReason = 'NO_DOC_REASON',
  Owners = 'OWNERS',
  Phone = 'PHONE',
  Photo = 'PHOTO',
  Usage = 'USAGE',
  UCadnum = 'U_CADNUM',
}

export type ObjectsAnalyticInput = {
  analyze: AnTaskConstraint;
  entityType: AnContentType;
  geoSearch: AllInWindowInput;
  ignoreLayersIds: Array<Scalars['ID']>;
  objectStatus: AnObjectStatus;
};

export type ObjectsAnalyticOutput = {
  __typename?: 'ObjectsAnalyticOutput';
  count: Scalars['Int'];
  hexColor: Scalars['String'];
  label: Scalars['String'];
  labelColor: Scalars['String'];
  status: TaskObjectStatus;
};

export type OfpBorderOutput = {
  __typename?: 'OfpBorderOutput';
  geo: Geometry;
  id: Scalars['ID'];
  name: Scalars['String'];
  ofpName: Scalars['String'];
  workBorders?: Maybe<Array<Maybe<BorderOutput>>>;
};

export type Oks = {
  __typename?: 'Oks';
  /**
   * адрес дадаты и
   * детализация адреса
   * разложенные по объектам
   */
  addr?: Maybe<AddressOutput>;
  address?: Maybe<Scalars['String']>;
  attachments?: Maybe<MapObjectFileAttachments>;
  breachReasonsObj?: Maybe<Array<Maybe<BreachOutput>>>;
  cadNum?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentLabels?: Maybe<Array<Maybe<CommentLabelValue>>>;
  cost?: Maybe<Scalars['Float']>;
  factAddress?: Maybe<Scalars['String']>;
  floor?: Maybe<Scalars['Int']>;
  geo?: Maybe<Geometry>;
  geomId: Scalars['ID'];
  id: Scalars['ID'];
  isAbandoned?: Maybe<Scalars['Boolean']>;
  layerId: Scalars['ID'];
  /** причина отсутствия документа */
  noDocReason?: Maybe<NoDocReasonOutput>;
  noInfoReasons?: Maybe<Array<Maybe<NoInfoReasonResponse>>>;
  owners?: Maybe<Scalars['String']>;
  p_value?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  processStatusObj?: Maybe<ProcessingStatusOutput>;
  rooms?: Maybe<Array<Maybe<Room>>>;
  suggestCadNum?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  unifiedCadNum?: Maybe<Scalars['Boolean']>;
  usage?: Maybe<OksUsage>;
  visitStatus?: Maybe<VisitStatus>;
};

export type OksByIdsInput = {
  ids: Array<Scalars['ID']>;
  layerId: Scalars['ID'];
};

/** Ответ фильтра по заданиям */
export type OksFilterOutput = {
  __typename?: 'OksFilterOutput';
  /** общее количество объектов */
  countData?: Maybe<Scalars['Int']>;
  /** пагинация */
  pagination?: Maybe<PaginationOutput>;
  /** результат */
  result?: Maybe<Array<Maybe<OksTask>>>;
  /** тип задачи */
  taskType: TaskType;
};

export type OksTask = {
  __typename?: 'OksTask';
  /** окс */
  oks: Oks;
  /** информация о посещениях объекта */
  visits?: Maybe<Array<Maybe<TaskVisitInfo>>>;
};

/** Объект задачи - ОКС */
export type OksTaskObject = TaskObject & {
  __typename?: 'OksTaskObject';
  createDateTime?: Maybe<Scalars['DateTime']>;
  deleteDateTime?: Maybe<Scalars['DateTime']>;
  geo?: Maybe<GeoSearchOutput>;
  id: Scalars['ID'];
  modifyStatus?: Maybe<ModifyStatus>;
  oks?: Maybe<Oks>;
  status?: Maybe<TaskObjectStatus>;
  updateDateTime?: Maybe<Scalars['DateTime']>;
};

export enum OksUsage {
  /** СХ здание */
  AgriculturalBuilding = 'AGRICULTURAL_BUILDING',
  /** МКД */
  ApartmentBuilding = 'APARTMENT_BUILDING',
  /** Объект общепита */
  Catering = 'CATERING',
  /** Коттедж */
  Cottage = 'COTTAGE',
  /** Для образовательной деятельности */
  EducationalBuilding = 'EDUCATIONAL_BUILDING',
  /** АЗС */
  GasStation = 'GAS_STATION',
  /** Больница */
  Hospital = 'HOSPITAL',
  /** Хозпостройка */
  HouseholdBuilding = 'HOUSEHOLD_BUILDING',
  /** Промышленное здание */
  IndustrialBuilding = 'INDUSTRIAL_BUILDING',
  /** Жилой дом */
  LivingHouse = 'LIVING_HOUSE',
  /** Офисное здание */
  Office = 'OFFICE',
  /** Иное */
  Other = 'OTHER',
  /** Аптека */
  Pharmacy = 'PHARMACY',
  /** Общественное здание */
  PublicBuilding = 'PUBLIC_BUILDING',
  /** Религиозный объект */
  ReligiousSite = 'RELIGIOUS_SITE',
  /** СТО */
  ServiceStation = 'SERVICE_STATION',
  /** Магазин */
  Shop = 'SHOP',
  /** Таунхаус */
  Townhouse = 'TOWNHOUSE',
  /** Не определен */
  Undefined = 'UNDEFINED',
  /** ОНС */
  Unfinished = 'UNFINISHED',
}

export type Pagination = {
  page?: InputMaybe<Scalars['Int']>;
  size?: InputMaybe<Scalars['Int']>;
};

export type PaginationAndSortingInput = {
  pagination: Pagination;
  sorting?: InputMaybe<Array<InputMaybe<Sorting>>>;
};

export type PaginationOutput = {
  __typename?: 'PaginationOutput';
  hasContent?: Maybe<Scalars['Boolean']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

/** Информация о ФЛ */
export type PersonInfo = {
  /** Имя */
  firstName?: InputMaybe<Scalars['String']>;
  /** Фамилия */
  lastName?: InputMaybe<Scalars['String']>;
  /** Отчество */
  patronymic?: InputMaybe<Scalars['String']>;
  /** Номер телефона */
  phone?: InputMaybe<Scalars['String']>;
};

export type PieRequest = {
  objectStatus: AnObjectStatus;
  workBorderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type PlotByIdsInput = {
  layerId: Scalars['ID'];
  plotIds: Array<Scalars['ID']>;
};

export type PlotDto = {
  __typename?: 'PlotDTO';
  /**
   * адрес дадаты и
   * детализация адреса
   * разложенные по объектам
   */
  addr?: Maybe<AddressOutput>;
  address?: Maybe<Scalars['String']>;
  area?: Maybe<Scalars['Float']>;
  attachments?: Maybe<MapObjectFileAttachments>;
  breachReasonsObj?: Maybe<Array<Maybe<BreachOutput>>>;
  cadNum?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentLabels?: Maybe<Array<Maybe<CommentLabelValue>>>;
  cost?: Maybe<Scalars['Float']>;
  factAddress?: Maybe<Scalars['String']>;
  geo?: Maybe<Geometry>;
  geomId: Scalars['ID'];
  id?: Maybe<Scalars['ID']>;
  isAbandoned?: Maybe<Scalars['Boolean']>;
  layerId?: Maybe<Scalars['ID']>;
  /** причина отсутствия документа */
  noDocReason?: Maybe<NoDocReasonOutput>;
  noInfoReasons?: Maybe<Array<Maybe<NoInfoReasonResponse>>>;
  owners?: Maybe<Scalars['String']>;
  parentId?: Maybe<Scalars['ID']>;
  phone?: Maybe<Scalars['String']>;
  plotType?: Maybe<Scalars['String']>;
  processStatusObj?: Maybe<ProcessingStatusOutput>;
  suggestCadNum?: Maybe<Scalars['String']>;
  unifiedCadNum?: Maybe<Scalars['Boolean']>;
  usage?: Maybe<PlotUsage>;
  visitStatus?: Maybe<VisitStatus>;
  workBorderId: Scalars['ID'];
};

export enum PlotDataStatus {
  Filled = 'FILLED',
  NoData = 'NO_DATA',
  Prefilled = 'PREFILLED',
}

/** Результат пересечения участка с другими участками в другом домовладении */
export type PlotIntersectionResultOutput = {
  __typename?: 'PlotIntersectionResultOutput';
  /** площадь пересечения(увеличеная) */
  area?: Maybe<Scalars['Float']>;
  /** полигон пересечения(немного увеличен для наглядности) */
  geo?: Maybe<Geometry>;
  id?: Maybe<Scalars['ID']>;
  isCrosses?: Maybe<Scalars['Boolean']>;
  /** айди участка назначения */
  targetId?: Maybe<Scalars['ID']>;
};

export enum PlotUsage {
  /** Под МКД */
  Apartment = 'APARTMENT',
  /** Под объект общепита */
  Catering = 'CATERING',
  /** Под коммерчекий объект */
  CommercialObject = 'COMMERCIAL_OBJECT',
  /** Под объект связи */
  Communication = 'COMMUNICATION',
  /** Под историко-культурный объект */
  CulturalObject = 'CULTURAL_OBJECT',
  /** Под оборону и безопастность */
  DefenceAndSecurity = 'DEFENCE_AND_SECURITY',
  /** Под образовательную деятельность */
  Education = 'EDUCATION',
  /** Под лпх */
  Farming = 'FARMING',
  /** Для общего пользования */
  GeneralPurpose = 'GENERAL_PURPOSE',
  /** Под объект здравоохранения */
  HealthObject = 'HEALTH_OBJECT',
  /** Под ИЖС */
  Ilb = 'ILB',
  /** Под объект промышленности */
  Industrial = 'INDUSTRIAL',
  /** Иное */
  Other = 'OTHER',
  /** Под объект энергетики */
  Power = 'POWER',
  /** Под общественное здание */
  PublicObject = 'PUBLIC_OBJECT',
  /** Под религиозный объект */
  Religion = 'RELIGION',
  /** Под объект дорожного сервиса */
  RoadServiceObject = 'ROAD_SERVICE_OBJECT',
  /** Под магазин */
  Shop = 'SHOP',
  /** Под склад */
  Storage = 'STORAGE',
  /** Под объект торговли */
  Trading = 'TRADING',
  /** Под объект транспорта */
  Transport = 'TRANSPORT',
  /** Не определено */
  Undefined = 'UNDEFINED',
}

export type Point = {
  __typename?: 'Point';
  coordinates?: Maybe<Array<Maybe<Scalars['Float']>>>;
  type?: Maybe<GeoType>;
};

export type Polygon = {
  __typename?: 'Polygon';
  coordinates?: Maybe<Array<Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>>>;
  type?: Maybe<GeoType>;
};

/** Макет 4 */
export type ProcessingAnalyticDynamicOutput = {
  __typename?: 'ProcessingAnalyticDynamicOutput';
  details?: Maybe<Array<Maybe<ProcessingDynamicDetails>>>;
  userId: Scalars['ID'];
  userInfo?: Maybe<UserInfo>;
};

/** макет 3 */
export type ProcessingAnalyticOutput = {
  __typename?: 'ProcessingAnalyticOutput';
  details?: Maybe<Array<Maybe<RecordDetails>>>;
  userId?: Maybe<Scalars['ID']>;
  userInfo?: Maybe<UserInfo>;
};

export type ProcessingDynamicDetails = {
  __typename?: 'ProcessingDynamicDetails';
  contentType?: Maybe<ContentType>;
  endCount?: Maybe<Scalars['Int']>;
  endDate?: Maybe<Scalars['Date']>;
  startCount?: Maybe<Scalars['Int']>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<ProcessingStatusValue>;
};

/** Макеты 3,4 */
export type ProcessingRecordDetail = {
  __typename?: 'ProcessingRecordDetail';
  contentType?: Maybe<ContentType>;
  count?: Maybe<Scalars['Int']>;
  status?: Maybe<ProcessingStatusValue>;
};

export type ProcessingStatusAudit = {
  __typename?: 'ProcessingStatusAudit';
  contentType: ContentType;
  fio?: Maybe<Scalars['String']>;
  objectId: Scalars['ID'];
  status: AuditStatus;
  timestamp: Scalars['DateTime'];
  user?: Maybe<Scalars['String']>;
  value: ProcessingStatusValue;
};

export type ProcessingStatusAuditOutput = {
  __typename?: 'ProcessingStatusAuditOutput';
  data?: Maybe<Array<Maybe<ProcessingStatusAudit>>>;
};

export type ProcessingStatusOutput = {
  __typename?: 'ProcessingStatusOutput';
  authorId?: Maybe<Scalars['ID']>;
  contentType: ContentType;
  createDateTime: Scalars['DateTime'];
  id: Scalars['ID'];
  objectId: Scalars['ID'];
  status: ProcessingStatusValue;
  updatedDateTime?: Maybe<Scalars['DateTime']>;
};

export type ProcessingStatusRequest = {
  contentType: ContentType;
  objectId: Scalars['ID'];
  status: ProcessingStatusValue;
};

/** Статус обработки */
export enum ProcessingStatusValue {
  /** Разрешить оформление(viewer) */
  AllowRegistration = 'ALLOW_REGISTRATION',
  /** Анализ */
  Analyze = 'ANALYZE',
  /** Запросить информацию у обходчика */
  AskAnt = 'ASK_ANT',
  /** Запросить информацию у собственника */
  AskOwner = 'ASK_OWNER',
  /** Решение суда */
  CourtDecision = 'COURT_DECISION',
  /** Отказ в оформлении(viewer) */
  DenyRegistration = 'DENY_REGISTRATION',
  /** Недозвон */
  DidNotAnswer = 'DID_NOT_ANSWER',
  /** Собственник отдал документы в МФЦ (Multifunctional Service Delivery Centers) */
  DocumentsInTheMsdc = 'DOCUMENTS_IN_THE_MSDC',
  /** Собственник оформляет договор аренды */
  DrawsUpLeaseAgreement = 'DRAWS_UP_LEASE_AGREEMENT',
  /** Собственник направил документы в суд */
  FiledDocumentsWithTheCourt = 'FILED_DOCUMENTS_WITH_THE_COURT',
  /** В базе ФНС */
  FnsDb = 'FNS_DB',
  /** Собственник получил выписку из ЕГРН (Multifunctional Service Delivery Centers) */
  GotPapersFromMsdc = 'GOT_PAPERS_FROM_MSDC',
  /** Собственность */
  InProperty = 'IN_PROPERTY',
  /** Требуется телефон собственника */
  NeedOwnerPhone = 'NEED_OWNER_PHONE',
  /** Требуется время для проверки(viewer) */
  NeedTimeToCheck = 'NEED_TIME_TO_CHECK',
  /** Отсутствует */
  None = 'NONE',
  /** Уведомление озвучено по телефону (нет ватсапа) */
  NotificationByPhone = 'NOTIFICATION_BY_PHONE',
  /** Не облагается налогом */
  NotTaxed = 'NOT_TAXED',
  /** Собственник заказал кадастровые работы */
  OrderedCadastralWorks = 'ORDERED_CADASTRAL_WORKS',
  /** Собственник обещал предоставить документы */
  PromisedToProvideDocuments = 'PROMISED_TO_PROVIDE_DOCUMENTS',
  /** Собственник готов оформить документы */
  ReadyToPaperwork = 'READY_TO_PAPERWORK',
  /** Собственник получил правоустанавливающий документ в администрации */
  ReceivedDocumentFromAdministration = 'RECEIVED_DOCUMENT_FROM_ADMINISTRATION',
  /** Отказывается оформить документы */
  RefusedToCooperate = 'REFUSED_TO_COOPERATE',
  /** Отказывается предоставить документы */
  RefuseToProvideDocuments = 'REFUSE_TO_PROVIDE_DOCUMENTS',
  /** Начато оформление */
  RegistrationHasBegun = 'REGISTRATION_HAS_BEGUN',
  /** Аренда */
  Rent = 'RENT',
  /** Резерв */
  Reserve = 'RESERVE',
  /** Отправить запрос в администрацию поселения */
  SendAdministration = 'SEND_ADMINISTRATION',
  /** Отправить запрос в ФНС */
  SendFns = 'SEND_FNS',
  /** Отправить уведомление собственнику */
  SendOwner = 'SEND_OWNER',
  /** Отправить запрос в администрацию района */
  SendRegion = 'SEND_REGION',
  /** Направлен на приём в ОМСУ */
  SentForOmsu = 'SENT_FOR_OMSU',
  /** Отправлен запрос в мэрию */
  SentInMayorOffice = 'SENT_IN_MAYOR_OFFICE',
  /** Участник СВО */
  SmoMember = 'SMO_MEMBER',
  /** Безвозмездное срочное пользование */
  UnpaidUrgentUsage = 'UNPAID_URGENT_USAGE',
  /** Выведен из кадастра */
  WithdrawnFromAccounting = 'WITHDRAWN_FROM_ACCOUNTING',
}

export enum QcGeo {
  City = 'CITY',
  Exact = 'EXACT',
  NearestHouse = 'NEAREST_HOUSE',
  Settlement = 'SETTLEMENT',
  Street = 'STREET',
  Undefined = 'UNDEFINED',
}

export type Query = {
  __typename?: 'Query';
  ableToMerge?: Maybe<Array<Maybe<MergeOutput>>>;
  /** получение всех границ работ */
  allBorders?: Maybe<Array<Maybe<BorderOutput>>>;
  allLayers?: Maybe<Array<Maybe<Layer>>>;
  /** получение всех границ регионов */
  allRegionBorders?: Maybe<Array<Maybe<OfpBorderOutput>>>;
  bordersByToken?: Maybe<Array<Maybe<BorderOutput>>>;
  /** Показывать количество объектов внутри области */
  countInWindow?: Maybe<Array<Maybe<ObjectsAnalyticOutput>>>;
  dadataGeoSearch?: Maybe<Array<Maybe<DadataAddressOutput>>>;
  dadataSuggestAddress?: Maybe<Array<Maybe<DadataAddressOutput>>>;
  existByUsername?: Maybe<Scalars['Boolean']>;
  /** Фильтр для бэкофиса Окс и Участков по параметрам v2 */
  filterBoV2?: Maybe<HomeOwnershipOutputBo>;
  filterRoom?: Maybe<RoomFilterOutput>;
  filterTask?: Maybe<OksFilterOutput>;
  /** поиск всех объектов в геограницах  без привязки к слою + ST_PointOnSurface */
  findAllInWindowV2?: Maybe<Array<Maybe<AllInWindowOutput>>>;
  /** Поиск по слою в пределах области(полигон) окс и участки */
  findByWindowV3?: Maybe<Array<Maybe<GeoOutput>>>;
  findUserActions?: Maybe<UserActionOutput>;
  /** получение геоданных по айди и типу */
  geoByIds?: Maybe<Array<Maybe<GeoSearchOutput>>>;
  /** Получение всех конфигураций */
  getAllTariffs?: Maybe<Array<Maybe<SalaryTariffOutput>>>;
  /** Получение зарплаты специалистов по данным фильтра и названию конфигурации (для админа) */
  getAntSalary?: Maybe<Array<Maybe<AntSalaryOutput>>>;
  /** получение данных для бэкофиса */
  getAssignedUserTaskObjects?: Maybe<Array<Maybe<TaskObject>>>;
  /** Получаем задания определенного менеджера. Только для Админа */
  getAuthorTasks?: Maybe<Array<Maybe<TaskResponse>>>;
  getBreachCount?: Maybe<Array<Maybe<BreachAnalyticOutput>>>;
  getBreachDynamic?: Maybe<Array<Maybe<BreachAnalyticDynamicOutput>>>;
  /** Получение уведомлений по домовладению */
  getBreachNotificationsV3?: Maybe<NotificationOutputV2>;
  getBreachesAudit?: Maybe<BreachAuditOutput>;
  /**
   *     getGeom(id:ID!):GeometryResponseDto
   *     getGeomByIds(request:GeometryRequestByIdsDto): [GeometryResponseDto]
   */
  getCachedMapObjects?: Maybe<Array<Maybe<MapObjectOutput>>>;
  /** Получение зарплаты специалистов по данным фильтра и названию конфигурации */
  getCurrentAntSalary?: Maybe<Array<Maybe<AntSalaryOutput>>>;
  getCurrentVisitStatus?: Maybe<TaskVisitOutput>;
  /** ЕГРН - Здания */
  getEgrnOksData?: Maybe<DataSourceEgrnOksSearchOutput>;
  /** ЕГРН - Участки */
  getEgrnPlotData?: Maybe<DataSourceEgrnPlotSearchOutput>;
  getExecutorsTasks?: Maybe<Array<Maybe<TaskResponse>>>;
  getGeometries?: Maybe<Array<Maybe<GeometryResponseDto>>>;
  getHomeOwnership?: Maybe<HomeOwnershipOutput>;
  getHomeOwnershipV2?: Maybe<HomeOwnershipOutput>;
  getHomeOwnerships?: Maybe<Array<Maybe<HomeOwnershipOutput>>>;
  /** Поиск по слою в пределах области(полигон) окс и участки для создания тасок */
  getMapObjects?: Maybe<Array<Maybe<MapObjectOutput>>>;
  getObjectBreaches?: Maybe<ObjectBreachOutput>;
  getProcessingCount?: Maybe<Array<Maybe<ProcessingAnalyticOutput>>>;
  getProcessingDynamic?: Maybe<Array<Maybe<ProcessingAnalyticDynamicOutput>>>;
  getProcessingStatusAudit?: Maybe<ProcessingStatusAuditOutput>;
  getTask?: Maybe<TaskResponse>;
  getTasks?: Maybe<Array<Maybe<TaskResponse>>>;
  getTasksByPlotId?: Maybe<Array<Maybe<TaskResponse>>>;
  getTasksByTypes?: Maybe<Array<TaskResponse>>;
  getTasksByUserAndRegion?: Maybe<Array<Maybe<TaskResponse>>>;
  /** Получение все тасок с пагинацией. Пока только для роли админа */
  getTasksV2?: Maybe<TaskTableOutputV2>;
  /** Запрос на получение задач муравьев по id муравьев и id регионов. Для роли менеджера */
  getTasksV3?: Maybe<Array<Maybe<TaskResponse>>>;
  getUserByID?: Maybe<UserResponse>;
  getUsers?: Maybe<Array<Maybe<UserResponse>>>;
  /**  Deprecated. Use getUsersByWorkBorderId instead */
  getUsersByTerritoryId?: Maybe<Array<Maybe<UserResponse>>>;
  getUsersByToken?: Maybe<Array<Maybe<UserResponse>>>;
  getUsersByWorkBorderId?: Maybe<Array<Maybe<UserResponse>>>;
  groupById?: Maybe<LayerGroup>;
  groups?: Maybe<Array<Maybe<GroupDto>>>;
  groupsLayers?: Maybe<Array<Maybe<LayerGroup>>>;
  heatMap?: Maybe<Array<Maybe<HeatMapOutput>>>;
  hintByIds?: Maybe<Array<Maybe<HintOutput>>>;
  layerById?: Maybe<Layer>;
  layersByGroupID?: Maybe<Array<Maybe<Layer>>>;
  /** Получение списка слоев по токену */
  layersByToken?: Maybe<Array<Maybe<Layer>>>;
  objectsOnLayer?: Maybe<Array<Maybe<LayerObject>>>;
  oksByCadnum?: Maybe<Array<Maybe<Oks>>>;
  /** Поиск участков по списку Id */
  oksById?: Maybe<Array<Maybe<Oks>>>;
  oksByIdV2?: Maybe<Array<Maybe<Oks>>>;
  oksGeomIdByRoomId: Scalars['ID'];
  oksIdByRoomId: Scalars['ID'];
  /** Бублик */
  pieChart?: Maybe<Array<Maybe<ObjectsAnalyticOutput>>>;
  /** Поиск участков по списку Id */
  plotsById?: Maybe<Array<Maybe<PlotDto>>>;
  /** Аналитика по регионам */
  regionAnalytics?: Maybe<Array<Maybe<RegionAnalyticsOutput>>>;
  /** получение регионов по айди */
  regionBordersByIds?: Maybe<Array<Maybe<OfpBorderOutput>>>;
  regionBordersByToken?: Maybe<Array<Maybe<OfpBorderOutput>>>;
  roomById?: Maybe<Room>;
  searchUsers?: Maybe<Array<Maybe<UserResponse>>>;
  /** Отправка уведомления */
  sendBreachNotification?: Maybe<SendBreachNotificationOutput>;
  table1?: Maybe<Array<Maybe<Table1Output>>>;
  table2?: Maybe<Array<Maybe<Table2Output>>>;
  /** DTR-259    Макет 3 */
  table3?: Maybe<Array<Maybe<Table3Output>>>;
  taskTable?: Maybe<TaskTableOutput>;
  /** Аналитика по границам работ */
  wbAnalytics?: Maybe<Array<Maybe<WbAnalyticsOutput>>>;
  workBordersByRegionId?: Maybe<Array<Maybe<BorderOutput>>>;
};

export type QueryAbleToMergeArgs = {
  dto?: InputMaybe<AbleToMergeInput>;
};

export type QueryCountInWindowArgs = {
  dto: ObjectsAnalyticInput;
};

export type QueryDadataGeoSearchArgs = {
  dto: DadataGeoSearchInput;
};

export type QueryDadataSuggestAddressArgs = {
  dto: DadataSuggestAddressInput;
};

export type QueryExistByUsernameArgs = {
  username: Scalars['String'];
};

export type QueryFilterBoV2Args = {
  dto?: InputMaybe<FilterInput>;
};

export type QueryFilterRoomArgs = {
  dto?: InputMaybe<FilterInput>;
};

export type QueryFilterTaskArgs = {
  dto?: InputMaybe<FilterInput>;
};

export type QueryFindAllInWindowV2Args = {
  dto: AllInWindowInput;
};

export type QueryFindByWindowV3Args = {
  windowDto: WindowInput;
};

export type QueryFindUserActionsArgs = {
  input?: InputMaybe<UserActionInput>;
};

export type QueryGeoByIdsArgs = {
  dto: GeoSearchInput;
};

export type QueryGetAntSalaryArgs = {
  input: AntSalaryFilterInput;
};

export type QueryGetAssignedUserTaskObjectsArgs = {
  dto?: InputMaybe<AssignedUserTasksInput>;
};

export type QueryGetAuthorTasksArgs = {
  id: Scalars['ID'];
};

export type QueryGetBreachCountArgs = {
  input?: InputMaybe<BoBreachesCountFilterInput>;
};

export type QueryGetBreachDynamicArgs = {
  input?: InputMaybe<BoBreachesDynamicFilterInput>;
};

export type QueryGetBreachNotificationsV3Args = {
  dto?: InputMaybe<NotificationInputV2>;
};

export type QueryGetBreachesAuditArgs = {
  dto?: InputMaybe<MapObjectIdentityInput>;
};

export type QueryGetCachedMapObjectsArgs = {
  dto: MapObjectInput;
};

export type QueryGetCurrentVisitStatusArgs = {
  taskObjectId: Scalars['ID'];
};

export type QueryGetEgrnOksDataArgs = {
  dto?: InputMaybe<DataSourceSearchInput>;
};

export type QueryGetEgrnPlotDataArgs = {
  dto?: InputMaybe<DataSourceSearchInput>;
};

export type QueryGetExecutorsTasksArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetGeometriesArgs = {
  dto?: InputMaybe<GeometryRequestByIdsDto>;
};

export type QueryGetHomeOwnershipArgs = {
  id: Scalars['ID'];
};

export type QueryGetHomeOwnershipV2Args = {
  id: Scalars['ID'];
  taskObjectId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetHomeOwnershipsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryGetMapObjectsArgs = {
  dto: MapObjectInput;
};

export type QueryGetObjectBreachesArgs = {
  dto?: InputMaybe<MapObjectIdentityInput>;
};

export type QueryGetProcessingCountArgs = {
  input?: InputMaybe<BoProcessingCountFilterInput>;
};

export type QueryGetProcessingDynamicArgs = {
  input?: InputMaybe<BoProcessingDynamicFilterInput>;
};

export type QueryGetProcessingStatusAuditArgs = {
  dto?: InputMaybe<MapObjectIdentityInput>;
};

export type QueryGetTaskArgs = {
  id: Scalars['ID'];
};

export type QueryGetTasksByPlotIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetTasksByTypesArgs = {
  types: Array<InputMaybe<TaskType>>;
};

export type QueryGetTasksByUserAndRegionArgs = {
  regionId?: InputMaybe<Scalars['ID']>;
};

export type QueryGetTasksV2Args = {
  pas?: InputMaybe<PaginationAndSortingInput>;
};

export type QueryGetTasksV3Args = {
  input?: InputMaybe<ManagerFilterTasksInput>;
};

export type QueryGetUserByIdArgs = {
  id?: InputMaybe<Scalars['ID']>;
};

export type QueryGetUsersArgs = {
  workBorderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryGetUsersByTerritoryIdArgs = {
  id: Scalars['ID'];
};

export type QueryGetUsersByWorkBorderIdArgs = {
  id: Scalars['ID'];
};

export type QueryGroupByIdArgs = {
  id: Scalars['ID'];
};

export type QueryHeatMapArgs = {
  dto: HeatMapInput;
};

export type QueryHintByIdsArgs = {
  dto: HintInput;
};

export type QueryLayerByIdArgs = {
  id: Scalars['ID'];
};

export type QueryLayersByGroupIdArgs = {
  id: Scalars['ID'];
};

export type QueryObjectsOnLayerArgs = {
  id: Scalars['ID'];
};

export type QueryOksByCadnumArgs = {
  cadNum: Scalars['String'];
};

export type QueryOksByIdArgs = {
  dto: OksByIdsInput;
};

export type QueryOksByIdV2Args = {
  dto: OksByIdsInput;
  taskObjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryOksGeomIdByRoomIdArgs = {
  roomId: Scalars['ID'];
};

export type QueryOksIdByRoomIdArgs = {
  roomId: Scalars['ID'];
};

export type QueryPieChartArgs = {
  dto: PieRequest;
};

export type QueryPlotsByIdArgs = {
  dto: PlotByIdsInput;
  taskObjectIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type QueryRegionAnalyticsArgs = {
  dto?: InputMaybe<MetricAnalyticsInput>;
};

export type QueryRegionBordersByIdsArgs = {
  ids: Array<Scalars['ID']>;
};

export type QueryRoomByIdArgs = {
  id: Scalars['ID'];
};

export type QuerySearchUsersArgs = {
  dto?: InputMaybe<UserSearchRequest>;
};

export type QuerySendBreachNotificationArgs = {
  dto?: InputMaybe<SendBreachNotificationInput>;
};

export type QueryTable1Args = {
  dto: Table1Request;
};

export type QueryTable2Args = {
  dto: Table2Request;
};

export type QueryTable3Args = {
  dto: Table3Request;
};

export type QueryTaskTableArgs = {
  dto?: InputMaybe<TaskTableInput>;
};

export type QueryWbAnalyticsArgs = {
  dto?: InputMaybe<MetricAnalyticsInput>;
};

export type QueryWorkBordersByRegionIdArgs = {
  id: Scalars['ID'];
};

export type RecordDetails = BreachRecordDetail | ProcessingRecordDetail;

export type RegionAnalyticsOutput = MetricAnalyticsOutput & {
  __typename?: 'RegionAnalyticsOutput';
  /**     MetricAnalyticsOutput */
  id: Scalars['ID'];
  kMetric: AnalyticsMetricKey;
  kName: Scalars['String'];
  /** regionId */
  rId: Scalars['ID'];
  unit: Scalars['String'];
  vMetric: Scalars['Int'];
};

export type RegionContact = {
  __typename?: 'RegionContact';
  address?: Maybe<Scalars['String']>;
  chief?: Maybe<Scalars['String']>;
  chiefSurname?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  headerDescription?: Maybe<Scalars['String']>;
  headerMain?: Maybe<Scalars['String']>;
  iin?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  ogrn?: Maybe<Scalars['String']>;
  okpo?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['ID']>;
};

export type RegisterUserInput = {
  assignedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  defaultRegionId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  roles: Array<InputMaybe<Roles>>;
  username: Scalars['String'];
  workBorders: Array<Scalars['ID']>;
};

export type RegisterUserInputV2 = {
  assignedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  defaultRegionId: Scalars['ID'];
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  password: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  regions: Array<Scalars['ID']>;
  roles: Array<InputMaybe<Roles>>;
  username: Scalars['String'];
  workBorders: Array<Scalars['ID']>;
};

/** Реестры данных */
export type RegistryOutput = {
  __typename?: 'RegistryOutput';
  id: Scalars['ID'];
  name: Scalars['String'];
  subtype: EntitySearchSubtype;
  type: DataSourceKind;
};

export type Role = {
  __typename?: 'Role';
  roleName?: Maybe<Scalars['String']>;
};

export type RoleInput = {
  roleName?: InputMaybe<Scalars['String']>;
};

export enum Roles {
  AdminUser = 'ADMIN_USER',
  Ant = 'ANT',
  AntManager = 'ANT_MANAGER',
  AntOfficer = 'ANT_OFFICER',
  BackOffice = 'BACK_OFFICE',
  Developer = 'DEVELOPER',
  Registry = 'REGISTRY',
  StandardUser = 'STANDARD_USER',
  Viewer = 'VIEWER',
}

export type Room = {
  __typename?: 'Room';
  /** Адрес дадата */
  addr?: Maybe<AddressOutput>;
  address?: Maybe<Scalars['String']>;
  breachReasonsObj?: Maybe<Array<Maybe<BreachOutput>>>;
  cadNum?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentLabels?: Maybe<Array<Maybe<CommentLabelValue>>>;
  id: Scalars['ID'];
  noDocReason?: Maybe<NoDocReasonOutput>;
  noInfoReasons?: Maybe<Array<Maybe<NoInfoReasonResponse>>>;
  owners?: Maybe<Scalars['String']>;
  parentId: Scalars['ID'];
  phone?: Maybe<Scalars['String']>;
  processStatusObj?: Maybe<ProcessingStatusOutput>;
  unifiedCadNum?: Maybe<Scalars['Boolean']>;
  usage?: Maybe<Scalars['String']>;
};

export type RoomFilterOutput = {
  __typename?: 'RoomFilterOutput';
  count?: Maybe<Scalars['Int']>;
  pagination?: Maybe<PaginationOutput>;
  result?: Maybe<Array<Maybe<RoomTask>>>;
};

export type RoomTask = {
  __typename?: 'RoomTask';
  /** Квартира */
  room: Room;
  /** список обходов квартиры */
  visits?: Maybe<Array<Maybe<TaskVisitInfo>>>;
};

/** Элемент конфигурации зарплаты (тип поля/коэффициент) */
export type SalaryTariffDto = {
  type?: InputMaybe<ObjectProperty>;
  value?: InputMaybe<Scalars['Int']>;
};

/** Элемент конфигурации зарплаты (тип поля/коэффициент), при запросе всех конфигураций */
export type SalaryTariffDtoRecord = {
  __typename?: 'SalaryTariffDtoRecord';
  type?: Maybe<ObjectProperty>;
  value?: Maybe<Scalars['Int']>;
};

/** Тело запроса на добавление конфигурации зарплаты */
export type SalaryTariffInput = {
  tariffName: Scalars['String'];
  tariffs: Array<InputMaybe<SalaryTariffDto>>;
};

/** Возвращаемое значение, конфигурация зарплаты */
export type SalaryTariffOutput = {
  __typename?: 'SalaryTariffOutput';
  /** Дата создания конфигурации */
  date?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  tariffs?: Maybe<Array<Maybe<SalaryTariffDtoRecord>>>;
};

/** Отправка уведомления */
export type SendBreachNotificationInput = {
  /** Варианты отправки */
  method: SendNotificationMethod;
  /** Ссылка на уведомление */
  notificationId?: InputMaybe<Scalars['ID']>;
};

export type SendBreachNotificationOutput = {
  __typename?: 'SendBreachNotificationOutput';
  status?: Maybe<SendResponseStatus>;
};

/** Варианты отправки */
export enum SendNotificationMethod {
  Email = 'EMAIL',
  Whatsapp = 'WHATSAPP',
}

export enum SendResponseStatus {
  Error = 'ERROR',
  None = 'NONE',
  Ok = 'OK',
}

export type Sorting = {
  direction?: InputMaybe<Direction>;
  property?: InputMaybe<Scalars['String']>;
};

/** Разделение домовладения */
export type SplitHomeOwnershipInput = {
  /**
   * Пары х,у которые сформируют линию разделения.
   * обязательно должны НАЧИНАТЬСЯ И ЗАКАНЧИВАТЬСЯ ВНЕ границ участка
   */
  blade: Array<InputMaybe<Array<InputMaybe<Scalars['Float']>>>>;
  /** айди домовладения которое делим */
  hoId: Scalars['ID'];
  /**
   * Задание к которому привязать разделенные участки
   * если параметр не передан - привязки к задаче нет
   */
  taskId: Scalars['ID'];
};

export enum SummaryStatus {
  None = 'NONE',
  NoCadnum = 'NO_CADNUM',
  NoDoc = 'NO_DOC',
  NoDocAndCadnum = 'NO_DOC_AND_CADNUM',
  Ok = 'OK',
}

export type Table1Output = {
  __typename?: 'Table1Output';
  details?: Maybe<Array<Maybe<Table1Result>>>;
  userInfo?: Maybe<UserInfo>;
};

export type Table1Request = {
  /** тип выборки - PLOT */
  objectStatuses?: InputMaybe<Array<InputMaybe<TaskObjectStatus>>>;
  /** район - 2 */
  regionId: Scalars['ID'];
  /** границы работ внутри района - 95335 */
  workBorderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Table1Result = {
  __typename?: 'Table1Result';
  objectStatus?: Maybe<TaskObjectStatus>;
  total?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};

export enum Table2ColumnType {
  /** Адрес */
  Address = 'ADDRESS',
  /** количество объектов с хотябы одним комментарием (comment_label) */
  Comments = 'COMMENTS',
  /** поле коммент в окс/зу */
  CommentField = 'COMMENT_FIELD',
  Docs = 'DOCS',
  /** общее количество завершенных объектов */
  Finished = 'FINISHED',
  /** ФИО */
  Fio = 'FIO',
  /** Причина отсутствия документа */
  NoDocumentReason = 'NO_DOCUMENT_REASON',
  /** Телефон */
  Phone = 'PHONE',
  Photos = 'PHOTOS',
  /** ВРИ ОКС */
  Usage = 'USAGE',
  WoCadBorders = 'WO_CAD_BORDERS',
}

export type Table2Output = {
  __typename?: 'Table2Output';
  details?: Maybe<Array<Maybe<Table2Result>>>;
  userInfo?: Maybe<UserInfo>;
};

export type Table2Request = {
  /** колонки. если не передано - все значения */
  columns?: InputMaybe<Array<InputMaybe<Table2ColumnType>>>;
  /** тип выборки. если не передано - все значения */
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  /** район - 2 */
  regionId: Scalars['ID'];
  /** границы работ внутри района. если не передано - все значения */
  workBorderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Table2Result = {
  __typename?: 'Table2Result';
  columnType?: Maybe<Table2ColumnType>;
  contentType?: Maybe<ContentType>;
  total?: Maybe<Scalars['Int']>;
  userId: Scalars['ID'];
};

export type Table3Output = {
  __typename?: 'Table3Output';
  details?: Maybe<Array<Maybe<Table3Result>>>;
  userInfo?: Maybe<UserInfo>;
};

/**
 * Если null - то все записи данного типа
 *
 * по всему региону
 * если
 * region!
 * workBorderIds null
 *
 * по переданным границам работ
 * если
 * region!
 * workBorderIds!
 */
export type Table3Request = {
  /** нарушения - NO_CADASTRAL_REGISTRATION */
  breachReasons?: InputMaybe<Array<InputMaybe<BreachReason>>>;
  /** тип выборки - PLOT */
  contentType?: InputMaybe<Array<InputMaybe<ContentType>>>;
  /** район - 2 */
  regionId: Scalars['ID'];
  /** границы работ внутри района - 95335 */
  workBorderIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
};

export type Table3Result = {
  __typename?: 'Table3Result';
  breachReason?: Maybe<BreachReason>;
  contentType?: Maybe<ContentType>;
  cost?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['ID']>;
};

/** Объект, по которому формируется/выполняется задание */
export type TaskObject = {
  createDateTime?: Maybe<Scalars['DateTime']>;
  deleteDateTime?: Maybe<Scalars['DateTime']>;
  geo?: Maybe<GeoSearchOutput>;
  id: Scalars['ID'];
  modifyStatus?: Maybe<ModifyStatus>;
  status?: Maybe<TaskObjectStatus>;
  updateDateTime?: Maybe<Scalars['DateTime']>;
};

/** Статус объектов */
export enum TaskObjectStatus {
  Complete = 'COMPLETE',
  NotStarted = 'NOT_STARTED',
  Uncompleted = 'UNCOMPLETED',
}

/** Статусы объектов задачи */
export type TaskObjectsStatus = {
  __typename?: 'TaskObjectsStatus';
  count: Scalars['Int'];
  hexColor: Scalars['String'];
  label: Scalars['String'];
  labelColor: Scalars['String'];
  status: TaskObjectStatus;
  taskId: Scalars['Int'];
};

/** Запрос на добавление задачи */
export type TaskRequest = {
  authorId: Scalars['ID'];
  authorName: Scalars['String'];
  deadLine?: InputMaybe<Scalars['Date']>;
  description?: InputMaybe<Scalars['String']>;
  executorId?: InputMaybe<Scalars['ID']>;
  executorName?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  /** Id объектов задач. Домовладение или окс - зависит от типа задачи */
  objIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** Тип задачи */
  type: TaskType;
  workBorderId: Scalars['ID'];
};

/** Ответ на добавление задачи */
export type TaskResponse = {
  __typename?: 'TaskResponse';
  /** Данные автора */
  author?: Maybe<UserInfo>;
  /**
   * deprecated. Use UserInfo
   * @deprecated No longer supported
   */
  authorId: Scalars['ID'];
  /**
   * deprecated. Use UserInfo
   * @deprecated No longer supported
   */
  authorName: Scalars['String'];
  deadLine?: Maybe<Scalars['Date']>;
  description?: Maybe<Scalars['String']>;
  /** Данные исполнителя */
  executor?: Maybe<UserInfo>;
  /**
   * deprecated. Use UserInfo
   * @deprecated No longer supported
   */
  executorId?: Maybe<Scalars['ID']>;
  /** deprecated. Use UserInfo */
  executorName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  objects?: Maybe<Array<Maybe<TaskObject>>>;
  objectsStatuses: Array<Maybe<TaskObjectsStatus>>;
  /** @deprecated No longer supported */
  type: TaskType;
  workBorderId?: Maybe<Scalars['ID']>;
};

export type TaskTableInput = {
  pagination: Pagination;
  sorting?: InputMaybe<Array<InputMaybe<Sorting>>>;
};

export type TaskTableOutput = {
  __typename?: 'TaskTableOutput';
  hasContent?: Maybe<Scalars['Boolean']>;
  hasNext?: Maybe<Scalars['Boolean']>;
  hasPrevious?: Maybe<Scalars['Boolean']>;
  plotInfos?: Maybe<Array<Maybe<PlotDto>>>;
  taskObjectInfos?: Maybe<Array<Maybe<TaskObject>>>;
  totalElements?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type TaskTableOutputV2 = {
  __typename?: 'TaskTableOutputV2';
  pagination?: Maybe<PaginationOutput>;
  tasks?: Maybe<Array<Maybe<TaskResponse>>>;
};

/** Ответ для действия с задачей */
export enum TaskType {
  /** Тип задачи на проверку всего домовладения */
  CheckObjects = 'CHECK_OBJECTS',
  /** Тип задачи на проверку только ОКС */
  CheckOks = 'CHECK_OKS',
}

export type TaskVisitInfo = {
  __typename?: 'TaskVisitInfo';
  /** автор таски */
  author: UserInfo;
  authorId: Scalars['ID'];
  /** дата создания таски */
  createdAt?: Maybe<Scalars['Date']>;
  /** исполнитель таски */
  executor: UserInfo;
  executorId: Scalars['ID'];
  /** айди таски */
  id: Scalars['ID'];
  /** название таски */
  name: Scalars['String'];
  /** айди объекта в контексте задачи */
  taskObjectId: Scalars['ID'];
};

export type TaskVisitOutput = {
  __typename?: 'TaskVisitOutput';
  currentVisitStatus?: Maybe<VisitStatus>;
  taskId: Scalars['ID'];
  taskObjectId: Scalars['ID'];
};

/**
 * Элемент тела запроса при обновлении данных зашитых в сущности
 * (кадастровый номер, этажность,ВРИ, телефон и фио владельца, комментарий, кадастровый неизвестен)
 */
export type UpdateInfoData = {
  field?: InputMaybe<EntityProperty>;
  value?: InputMaybe<Scalars['String']>;
};

/** Тело запроса при обновлении данных зашитых в сущности */
export type UpdateObjectInput = {
  data?: InputMaybe<Array<InputMaybe<UpdateInfoData>>>;
  layerId?: InputMaybe<Scalars['ID']>;
  noInfoReason?: InputMaybe<NoInfoReasonRequest>;
  objectId?: InputMaybe<Scalars['ID']>;
  objectType?: InputMaybe<ContentType>;
  taskId?: InputMaybe<Scalars['ID']>;
  taskObjectId?: InputMaybe<Scalars['ID']>;
};

/** Перемещениe объекта на другой слой.  */
export type UpdateObjectLayerInput = {
  /** Тип */
  contentType?: InputMaybe<LayerContentType>;
  /** Что перемещаем */
  objectId: Scalars['ID'];
  /** Куда перемещаем */
  targetLayerId: Scalars['ID'];
};

/** Ответ на обновление окс */
export type UpdateOksOutput = {
  __typename?: 'UpdateOksOutput';
  noInfoReason?: Maybe<NoInfoReasonResponse>;
  oks?: Maybe<Array<Maybe<Oks>>>;
};

/** Ответ на обновление зу */
export type UpdatePlotOutput = {
  __typename?: 'UpdatePlotOutput';
  noInfoReason?: Maybe<NoInfoReasonResponse>;
  plot?: Maybe<Array<Maybe<PlotDto>>>;
};

/** Ответ на обновление комнаты */
export type UpdateRoomOutput = {
  __typename?: 'UpdateRoomOutput';
  noInfoReason?: Maybe<NoInfoReasonResponse>;
  room?: Maybe<Array<Maybe<Room>>>;
};

export type UpdateTaskObjectOutput = {
  __typename?: 'UpdateTaskObjectOutput';
  objectId: Scalars['ID'];
  status?: Maybe<TaskObjectStatus>;
};

export type UpdateTaskObjectStatusInput = {
  objectId: Scalars['ID'];
  status?: InputMaybe<TaskObjectStatus>;
  taskId: Scalars['ID'];
};

export type UpdateTaskVisitStatusInput = {
  taskId: Scalars['ID'];
  taskObjectId: Scalars['ID'];
  visitStatus?: InputMaybe<VisitStatus>;
};

/** Поля для сортировки username, date и action */
export type UserActionData = {
  __typename?: 'UserActionData';
  action?: Maybe<ActionType>;
  date?: Maybe<Scalars['Date']>;
  id: Scalars['ID'];
  objectId: Scalars['ID'];
  sourceLayerId: Scalars['ID'];
  sourceLayerName?: Maybe<Scalars['String']>;
  targetLayerId: Scalars['ID'];
  targetLayerName?: Maybe<Scalars['String']>;
  type?: Maybe<ActionContent>;
  username?: Maybe<Scalars['String']>;
};

/**
 * Запрос на получение пользовательских действий
 * actionContent: [PLOT,OKS]
 * objId - id объекта
 * actionType - тип действия [CHANGE_OBJECT_LAYER,COPY_OBJECT_ON_LAYER]
 * pas - объект пагинации и сортировки
 * dateInput - даты отбора значений с .. по (включая даты с/по)
 */
export type UserActionInput = {
  actionContent: ActionContent;
  actionType?: InputMaybe<Array<ActionType>>;
  dateInput?: InputMaybe<FilterDateInput>;
  objId: Scalars['ID'];
  pas: PaginationAndSortingInput;
};

export type UserActionOutput = {
  __typename?: 'UserActionOutput';
  list?: Maybe<Array<Maybe<UserActionData>>>;
  pagination?: Maybe<PaginationOutput>;
};

/** Информация о пользователе */
export type UserInfo = {
  __typename?: 'UserInfo';
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  username: Scalars['String'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  assignedUsers?: Maybe<Array<Maybe<AssignedUserResponse>>>;
  /** текущий район работ для пользователя */
  defaultRegionId?: Maybe<Scalars['ID']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  firstName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastName?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  regions?: Maybe<Array<Maybe<OfpBorderOutput>>>;
  /** доступные источники данных пользователя */
  registries?: Maybe<Array<Maybe<RegistryOutput>>>;
  roles?: Maybe<Array<Maybe<Role>>>;
  username: Scalars['String'];
  workBorders?: Maybe<Array<Maybe<BorderOutput>>>;
};

export type UserSearchRequest = {
  firstName?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

/** userName - используется для поиска пользователя и не изменяем */
export type UserUpdateRequest = {
  assignedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  username: Scalars['String'];
  workBorderIds: Array<Scalars['ID']>;
};

/** userName - используется для поиска пользователя и не изменяем */
export type UserUpdateRequestV2 = {
  assignedUsers?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  defaultRegionId?: InputMaybe<Scalars['ID']>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  regions: Array<Scalars['ID']>;
  username: Scalars['String'];
  workBorderIds: Array<Scalars['ID']>;
};

export enum VisitStatus {
  Abandoned = 'ABANDONED',
  NoOneHome = 'NO_ONE_HOME',
  NoStatus = 'NO_STATUS',
  Ok = 'OK',
  RefuseToTalk = 'REFUSE_TO_TALK',
}

export type WbAnalyticsGeometry = {
  __typename?: 'WbAnalyticsGeometry';
  /** Геометрия */
  geo: Geometry;
  id: Scalars['ID'];
  /** идентификатор объекта */
  objId: Scalars['ID'];
  /** Тип объекта */
  objType?: Maybe<ContentType>;
  /** WbAnalyticsReason ID */
  reasonId: Scalars['Int'];
};

export type WbAnalyticsOutput = MetricAnalyticsOutput & {
  __typename?: 'WbAnalyticsOutput';
  /**     MetricAnalyticsOutput */
  id: Scalars['ID'];
  kMetric: AnalyticsMetricKey;
  kName: Scalars['String'];
  rId: Scalars['ID'];
  reasons?: Maybe<Array<Maybe<WbAnalyticsReason>>>;
  unit: Scalars['String'];
  vMetric: Scalars['Int'];
  wbId: Scalars['ID'];
};

export type WbAnalyticsReason = {
  __typename?: 'WbAnalyticsReason';
  color?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  objects?: Maybe<Array<Maybe<WbAnalyticsGeometry>>>;
  /** порядок сортировки */
  order?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  total?: Maybe<Scalars['Int']>;
  /** WbAnalyticsOutput ID */
  wbAnId: Scalars['ID'];
};

export type WindowInput = {
  geo: GeoInput;
  hintField: HintField;
  layerId: Scalars['ID'];
  searchType: GeoSearchType;
};

export type WorkBorderContact = {
  __typename?: 'WorkBorderContact';
  address?: Maybe<Scalars['String']>;
  chief?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  place?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['ID']>;
  workBorderId?: Maybe<Scalars['ID']>;
};

/** фильтр по границам работ */
export type WorkBorderFilterInput = {
  /** айди границ работ */
  workBorders: Array<Scalars['ID']>;
};

export enum WorkBorderType {
  City = 'CITY',
  Settlement = 'SETTLEMENT',
}

export type GetGeometriesQueryVariables = Exact<{
  dto: GeometryRequestByIdsDto;
}>;

export type GetGeometriesQuery = {
  __typename?: 'Query';
  getGeometries?: Array<{
    __typename?: 'GeometryResponseDto';
    id: string;
    geo: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    };
  } | null> | null;
};

export type CountInWindowQueryVariables = Exact<{
  dto: ObjectsAnalyticInput;
}>;

export type CountInWindowQuery = {
  __typename?: 'Query';
  countInWindow?: Array<{
    __typename?: 'ObjectsAnalyticOutput';
    labelColor: string;
    label: string;
    status: TaskObjectStatus;
    count: number;
  } | null> | null;
};

export type AntsCurrentSalariesQueryVariables = Exact<{ [key: string]: never }>;

export type AntsCurrentSalariesQuery = {
  __typename?: 'Query';
  getCurrentAntSalary?: Array<{
    __typename?: 'AntSalaryOutput';
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'AntSalaryRecordOutput';
      total?: number | null;
      columnType?: ObjectProperty | null;
      contentType?: ContentType | null;
    } | null> | null;
  } | null> | null;
};

export type GetAnalyticsHosQueryVariables = Exact<{ [key: string]: never }>;

export type GetAnalyticsHosQuery = {
  __typename?: 'Query';
  getTasks?: Array<{
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    executor?: {
      __typename?: 'UserInfo';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    objects?: Array<
      | {
          __typename?: 'HomeOwnershipTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          updateDateTime?: any | null;
          homeOwnership?: {
            __typename?: 'HomeOwnershipOutput';
            id: string;
            plotId: string;
            plot?: {
              __typename?: 'PlotDTO';
              id?: string | null;
              phone?: string | null;
              owners?: string | null;
              usage?: PlotUsage | null;
              address?: string | null;
              addr?: {
                __typename?: 'AddressOutput';
                dadataAddress?: {
                  __typename: 'DadataAddress';
                  id?: string | null;
                  address?: string | null;
                  fiasId?: any | null;
                  fiasLevel: FiasLevel;
                  fiasActualityState: FiasActuality;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
                addressDetail?: {
                  __typename: 'AddressDetail';
                  id: string;
                  addressId?: string | null;
                  objId: string;
                  address?: string | null;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  room?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
              } | null;
            } | null;
            oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
          } | null;
        }
      | {
          __typename?: 'OksTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          updateDateTime?: any | null;
          oks?: {
            __typename?: 'Oks';
            id: string;
            phone?: string | null;
            owners?: string | null;
            usage?: OksUsage | null;
            address?: string | null;
            addr?: {
              __typename?: 'AddressOutput';
              dadataAddress?: {
                __typename: 'DadataAddress';
                id?: string | null;
                address?: string | null;
                fiasId?: any | null;
                fiasLevel: FiasLevel;
                fiasActualityState: FiasActuality;
                city?: string | null;
                cityTypeFull?: string | null;
                area?: string | null;
                areaTypeFull?: string | null;
                settlement?: string | null;
                settlementTypeFull?: string | null;
                street?: string | null;
                streetTypeFull?: string | null;
                house?: string | null;
                houseTypeFull?: string | null;
              } | null;
              addressDetail?: {
                __typename: 'AddressDetail';
                id: string;
                addressId?: string | null;
                objId: string;
                address?: string | null;
                city?: string | null;
                cityTypeFull?: string | null;
                area?: string | null;
                areaTypeFull?: string | null;
                settlement?: string | null;
                room?: string | null;
                settlementTypeFull?: string | null;
                street?: string | null;
                streetTypeFull?: string | null;
                house?: string | null;
                houseTypeFull?: string | null;
              } | null;
            } | null;
          } | null;
        }
      | null
    > | null;
  } | null> | null;
};

export type AntsSalariesQueryVariables = Exact<{
  dto: AntSalaryFilterInput;
}>;

export type AntsSalariesQuery = {
  __typename?: 'Query';
  getAntSalary?: Array<{
    __typename?: 'AntSalaryOutput';
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'AntSalaryRecordOutput';
      total?: number | null;
      columnType?: ObjectProperty | null;
      contentType?: ContentType | null;
    } | null> | null;
  } | null> | null;
};

export type GetConfigurationQueryVariables = Exact<{ [key: string]: never }>;

export type GetConfigurationQuery = {
  __typename?: 'Query';
  getAllTariffs?: Array<{
    __typename?: 'SalaryTariffOutput';
    name?: string | null;
    date?: any | null;
    tariffs?: Array<{
      __typename?: 'SalaryTariffDtoRecord';
      value?: number | null;
      type?: ObjectProperty | null;
    } | null> | null;
  } | null> | null;
};

export type GetHoGeoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetHoGeoQuery = {
  __typename?: 'Query';
  getHomeOwnership?: {
    __typename?: 'HomeOwnershipOutput';
    id: string;
    plotId: string;
    geom?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    } | null;
  } | null;
};

export type Table1QueryVariables = Exact<{
  dto: Table1Request;
}>;

export type Table1Query = {
  __typename?: 'Query';
  table1?: Array<{
    __typename?: 'Table1Output';
    details?: Array<{
      __typename?: 'Table1Result';
      objectStatus?: TaskObjectStatus | null;
      total?: number | null;
    } | null> | null;
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
  } | null> | null;
};

export type Table2QueryVariables = Exact<{
  dto: Table2Request;
}>;

export type Table2Query = {
  __typename?: 'Query';
  table2?: Array<{
    __typename?: 'Table2Output';
    userInfo?: {
      __typename?: 'UserInfo';
      firstName?: string | null;
      lastName?: string | null;
      phone?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'Table2Result';
      total?: number | null;
      contentType?: ContentType | null;
      columnType?: Table2ColumnType | null;
      userId: string;
    } | null> | null;
  } | null> | null;
};

export type Table3QueryVariables = Exact<{
  dto: Table3Request;
}>;

export type Table3Query = {
  __typename?: 'Query';
  table3?: Array<{
    __typename?: 'Table3Output';
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'Table3Result';
      breachReason?: BreachReason | null;
      contentType?: ContentType | null;
      cost?: number | null;
    } | null> | null;
  } | null> | null;
};

export type TasksPieQueryVariables = Exact<{
  dto: PieRequest;
}>;

export type TasksPieQuery = {
  __typename?: 'Query';
  pieChart?: Array<{
    __typename?: 'ObjectsAnalyticOutput';
    labelColor: string;
    label: string;
    status: TaskObjectStatus;
    hexColor: string;
    count: number;
  } | null> | null;
};

export type GetBreachesCountQueryVariables = Exact<{
  dto: BoBreachesCountFilterInput;
}>;

export type GetBreachesCountQuery = {
  __typename?: 'Query';
  getBreachCount?: Array<{
    __typename?: 'BreachAnalyticOutput';
    details?: Array<
      | {
          __typename?: 'BreachRecordDetail';
          contentType?: ContentType | null;
          count?: number | null;
          breach?: BreachReason | null;
        }
      | { __typename?: 'ProcessingRecordDetail' }
      | null
    > | null;
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
  } | null> | null;
};

export type GetBreachesDynamicQueryVariables = Exact<{
  dto: BoBreachesDynamicFilterInput;
}>;

export type GetBreachesDynamicQuery = {
  __typename?: 'Query';
  getBreachDynamic?: Array<{
    __typename?: 'BreachAnalyticDynamicOutput';
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'BreachDynamicDetails';
      breach?: BreachReason | null;
      endCount?: number | null;
      endDate?: any | null;
      startCount?: number | null;
      startDate?: any | null;
      contentType?: ContentType | null;
    } | null> | null;
  } | null> | null;
};

export type GetProcessingDynamicQueryVariables = Exact<{
  dto: BoProcessingDynamicFilterInput;
}>;

export type GetProcessingDynamicQuery = {
  __typename?: 'Query';
  getProcessingDynamic?: Array<{
    __typename?: 'ProcessingAnalyticDynamicOutput';
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    details?: Array<{
      __typename?: 'ProcessingDynamicDetails';
      status?: ProcessingStatusValue | null;
      startCount?: number | null;
      startDate?: any | null;
      endCount?: number | null;
      contentType?: ContentType | null;
    } | null> | null;
  } | null> | null;
};

export type GetProcessingStatusesQueryVariables = Exact<{
  dto: BoProcessingCountFilterInput;
}>;

export type GetProcessingStatusesQuery = {
  __typename?: 'Query';
  getProcessingCount?: Array<{
    __typename?: 'ProcessingAnalyticOutput';
    details?: Array<
      | { __typename?: 'BreachRecordDetail' }
      | {
          __typename?: 'ProcessingRecordDetail';
          contentType?: ContentType | null;
          count?: number | null;
          status?: ProcessingStatusValue | null;
        }
      | null
    > | null;
    userInfo?: {
      __typename?: 'UserInfo';
      lastName?: string | null;
      firstName?: string | null;
    } | null;
  } | null> | null;
};

export type RegionsAnalyticsQueryVariables = Exact<{
  dto: MetricAnalyticsInput;
}>;

export type RegionsAnalyticsQuery = {
  __typename?: 'Query';
  regionAnalytics?: Array<{
    __typename?: 'RegionAnalyticsOutput';
    kName: string;
    kMetric: AnalyticsMetricKey;
    vMetric: number;
    unit: string;
    id: string;
    rId: string;
  } | null> | null;
};

type RegionsAnalyticsMetrics_RegionAnalyticsOutput_Fragment = {
  __typename?: 'RegionAnalyticsOutput';
  kName: string;
  kMetric: AnalyticsMetricKey;
  vMetric: number;
  unit: string;
  id: string;
};

type RegionsAnalyticsMetrics_WbAnalyticsOutput_Fragment = {
  __typename?: 'WbAnalyticsOutput';
  kName: string;
  kMetric: AnalyticsMetricKey;
  vMetric: number;
  unit: string;
  id: string;
};

export type RegionsAnalyticsMetricsFragment =
  | RegionsAnalyticsMetrics_RegionAnalyticsOutput_Fragment
  | RegionsAnalyticsMetrics_WbAnalyticsOutput_Fragment;

export type WbAnalyticsQueryVariables = Exact<{
  dto: MetricAnalyticsInput;
}>;

export type WbAnalyticsQuery = {
  __typename?: 'Query';
  wbAnalytics?: Array<{
    __typename?: 'WbAnalyticsOutput';
    kName: string;
    kMetric: AnalyticsMetricKey;
    vMetric: number;
    unit: string;
    id: string;
    wbId: string;
    reasons?: Array<{
      __typename?: 'WbAnalyticsReason';
      total?: number | null;
      reason?: string | null;
      id: string;
      color?: string | null;
    } | null> | null;
  } | null> | null;
};

export type WbAnalyticsGeoQueryVariables = Exact<{
  dto: MetricAnalyticsInput;
}>;

export type WbAnalyticsGeoQuery = {
  __typename?: 'Query';
  wbAnalytics?: Array<{
    __typename?: 'WbAnalyticsOutput';
    reasons?: Array<{
      __typename?: 'WbAnalyticsReason';
      id: string;
      objects?: Array<{
        __typename?: 'WbAnalyticsGeometry';
        objType?: ContentType | null;
        objId: string;
        geo: {
          __typename?: 'Geometry';
          coordinates?: Array<Array<Array<number | null> | null> | null> | null;
        };
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type AddObjectBreachMutationVariables = Exact<{
  reason: AddBreachInput;
}>;

export type AddObjectBreachMutation = {
  __typename?: 'Mutation';
  addObjectBreach?: {
    __typename?: 'ObjectBreachOutput';
    data?: Array<{
      __typename?: 'BreachOutput';
      id: string;
      authorId?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteObjectBreachMutationVariables = Exact<{
  dto: DeleteBreachesInput;
}>;

export type DeleteObjectBreachMutation = {
  __typename?: 'Mutation';
  deleteObjectBreach?: boolean | null;
};

export type GetEgrnDataQueryVariables = Exact<{
  dto: DataSourceSearchInput;
  isPlot?: InputMaybe<Scalars['Boolean']>;
  isOks?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetEgrnDataQuery = {
  __typename?: 'Query';
  getEgrnPlotData?: {
    __typename?: 'DataSourceEgrnPlotSearchOutput';
    data?: Array<{
      __typename?: 'EgrnPlotResponse';
      id: string;
      addressEgrn?: string | null;
      addressFias?: string | null;
      cad_number: string;
      grpDate?: string | null;
      grpNum?: string | null;
      grpType?: string | null;
      opoDate?: string | null;
      opoNum?: string | null;
      opoType?: string | null;
    } | null> | null;
  } | null;
  getEgrnOksData?: {
    __typename?: 'DataSourceEgrnOksSearchOutput';
    data?: Array<{
      __typename?: 'EgrnOksResponse';
      id: string;
      cad_number: string;
      grpDate?: string | null;
      grpNum?: string | null;
      grpType?: string | null;
      opoDate?: string | null;
      opoNum?: string | null;
      opoType?: string | null;
    } | null> | null;
  } | null;
};

export type FilterBoQueryVariables = Exact<{
  dto: FilterInput;
}>;

export type FilterBoQuery = {
  __typename?: 'Query';
  filterBoV2?: {
    __typename?: 'HomeOwnershipOutputBo';
    countData?: {
      __typename?: 'CountData';
      hoCount?: number | null;
      plotCount?: number | null;
      oksCount?: number | null;
    } | null;
    list?: Array<{
      __typename?: 'HomeOwnershipOutputBoData';
      taskId?: string | null;
      taskObjectId?: string | null;
      ho?: {
        __typename?: 'HomeOwnershipOutput';
        id: string;
        plot?: {
          __typename?: 'PlotDTO';
          id?: string | null;
          addr?: {
            __typename?: 'AddressOutput';
            dadataAddress?: {
              __typename: 'DadataAddress';
              id?: string | null;
              address?: string | null;
              fiasId?: any | null;
              fiasLevel: FiasLevel;
              fiasActualityState: FiasActuality;
              city?: string | null;
              cityTypeFull?: string | null;
              area?: string | null;
              areaTypeFull?: string | null;
              settlement?: string | null;
              settlementTypeFull?: string | null;
              street?: string | null;
              streetTypeFull?: string | null;
              house?: string | null;
              houseTypeFull?: string | null;
            } | null;
            addressDetail?: {
              __typename: 'AddressDetail';
              id: string;
              addressId?: string | null;
              objId: string;
              address?: string | null;
              city?: string | null;
              cityTypeFull?: string | null;
              area?: string | null;
              areaTypeFull?: string | null;
              settlement?: string | null;
              room?: string | null;
              settlementTypeFull?: string | null;
              street?: string | null;
              streetTypeFull?: string | null;
              house?: string | null;
              houseTypeFull?: string | null;
            } | null;
          } | null;
        } | null;
        oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
      } | null;
      executor?: {
        __typename?: 'UserInfo';
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
      } | null;
    } | null> | null;
    pagination?: {
      __typename?: 'PaginationOutput';
      hasPrevious?: boolean | null;
      totalElements?: number | null;
      totalPages?: number | null;
      hasContent?: boolean | null;
      hasNext?: boolean | null;
    } | null;
  } | null;
};

export type OksFilterFragmentFragment = {
  __typename?: 'OksFilterOutput';
  taskType: TaskType;
  countData?: number | null;
  result?: Array<{
    __typename?: 'OksTask';
    oks: {
      __typename?: 'Oks';
      id: string;
      cost?: number | null;
      phone?: string | null;
      usage?: OksUsage | null;
      floor?: number | null;
      cadNum?: string | null;
      owners?: string | null;
      geomId: string;
      address?: string | null;
      comment?: string | null;
      layerId: string;
      parentId?: string | null;
      suggestCadNum?: string | null;
      unifiedCadNum?: boolean | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      visitStatus?: VisitStatus | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
      rooms?: Array<{
        __typename?: 'Room';
        id: string;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
      } | null> | null;
    };
    visits?: Array<{
      __typename?: 'TaskVisitInfo';
      id: string;
      taskObjectId: string;
      executor: {
        __typename?: 'UserInfo';
        id: string;
        username: string;
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
      };
    } | null> | null;
  } | null> | null;
  pagination?: {
    __typename?: 'PaginationOutput';
    hasPrevious?: boolean | null;
    totalElements?: number | null;
    totalPages?: number | null;
    hasContent?: boolean | null;
    hasNext?: boolean | null;
  } | null;
};

export type FilterTaskOksQueryVariables = Exact<{
  dto: FilterInput;
  needOks?: InputMaybe<Scalars['Boolean']>;
}>;

export type FilterTaskOksQuery = {
  __typename?: 'Query';
  filterTask?: {
    __typename?: 'OksFilterOutput';
    taskType: TaskType;
    countData?: number | null;
    result?: Array<{
      __typename?: 'OksTask';
      oks: {
        __typename?: 'Oks';
        id: string;
        cost?: number | null;
        phone?: string | null;
        usage?: OksUsage | null;
        floor?: number | null;
        cadNum?: string | null;
        owners?: string | null;
        geomId: string;
        address?: string | null;
        comment?: string | null;
        layerId: string;
        parentId?: string | null;
        suggestCadNum?: string | null;
        unifiedCadNum?: boolean | null;
        commentLabels?: Array<CommentLabelValue | null> | null;
        visitStatus?: VisitStatus | null;
        addr?: {
          __typename?: 'AddressOutput';
          dadataAddress?: {
            __typename: 'DadataAddress';
            id?: string | null;
            address?: string | null;
            fiasId?: any | null;
            fiasLevel: FiasLevel;
            fiasActualityState: FiasActuality;
            city?: string | null;
            cityTypeFull?: string | null;
            area?: string | null;
            areaTypeFull?: string | null;
            settlement?: string | null;
            settlementTypeFull?: string | null;
            street?: string | null;
            streetTypeFull?: string | null;
            house?: string | null;
            houseTypeFull?: string | null;
          } | null;
          addressDetail?: {
            __typename: 'AddressDetail';
            id: string;
            addressId?: string | null;
            objId: string;
            address?: string | null;
            city?: string | null;
            cityTypeFull?: string | null;
            area?: string | null;
            areaTypeFull?: string | null;
            settlement?: string | null;
            room?: string | null;
            settlementTypeFull?: string | null;
            street?: string | null;
            streetTypeFull?: string | null;
            house?: string | null;
            houseTypeFull?: string | null;
          } | null;
        } | null;
        noDocReason?: {
          __typename?: 'NoDocReasonOutput';
          noDocumentReason?: NoDocumentReason | null;
          noDocumentDeadline?: string | null;
        } | null;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
        rooms?: Array<{
          __typename?: 'Room';
          id: string;
          noInfoReasons?: Array<{
            __typename?: 'NoInfoReasonResponse';
            infoType?: ObjectProperty | null;
            reason?: NoInfoReason | null;
          } | null> | null;
        } | null> | null;
      };
      visits?: Array<{
        __typename?: 'TaskVisitInfo';
        id: string;
        taskObjectId: string;
        executor: {
          __typename?: 'UserInfo';
          id: string;
          username: string;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
        };
      } | null> | null;
    } | null> | null;
    pagination?: {
      __typename?: 'PaginationOutput';
      hasPrevious?: boolean | null;
      totalElements?: number | null;
      totalPages?: number | null;
      hasContent?: boolean | null;
      hasNext?: boolean | null;
    } | null;
  } | null;
};

export type FilterRoomQueryVariables = Exact<{
  dto: FilterInput;
}>;

export type FilterRoomQuery = {
  __typename?: 'Query';
  filterRoom?: {
    __typename?: 'RoomFilterOutput';
    count?: number | null;
    result?: Array<{
      __typename?: 'RoomTask';
      room: {
        __typename?: 'Room';
        id: string;
        parentId: string;
        cadNum?: string | null;
        usage?: string | null;
        address?: string | null;
        owners?: string | null;
        phone?: string | null;
        comment?: string | null;
        commentLabels?: Array<CommentLabelValue | null> | null;
        unifiedCadNum?: boolean | null;
        addr?: {
          __typename?: 'AddressOutput';
          dadataAddress?: {
            __typename: 'DadataAddress';
            id?: string | null;
            address?: string | null;
            fiasId?: any | null;
            fiasLevel: FiasLevel;
            fiasActualityState: FiasActuality;
            city?: string | null;
            cityTypeFull?: string | null;
            area?: string | null;
            areaTypeFull?: string | null;
            settlement?: string | null;
            settlementTypeFull?: string | null;
            street?: string | null;
            streetTypeFull?: string | null;
            house?: string | null;
            houseTypeFull?: string | null;
          } | null;
          addressDetail?: {
            __typename: 'AddressDetail';
            id: string;
            addressId?: string | null;
            objId: string;
            address?: string | null;
            city?: string | null;
            cityTypeFull?: string | null;
            area?: string | null;
            areaTypeFull?: string | null;
            settlement?: string | null;
            room?: string | null;
            settlementTypeFull?: string | null;
            street?: string | null;
            streetTypeFull?: string | null;
            house?: string | null;
            houseTypeFull?: string | null;
          } | null;
        } | null;
        breachReasonsObj?: Array<{
          __typename?: 'BreachOutput';
          id: string;
          objectId: string;
          contentType: ContentType;
          breachReason: BreachReason;
          createDateTime: any;
          authorId?: string | null;
        } | null> | null;
        processStatusObj?: {
          __typename?: 'ProcessingStatusOutput';
          id: string;
          objectId: string;
          contentType: ContentType;
          status: ProcessingStatusValue;
          createDateTime: any;
          updatedDateTime?: any | null;
          authorId?: string | null;
        } | null;
        noDocReason?: {
          __typename?: 'NoDocReasonOutput';
          contentId?: string | null;
          noDocumentReason?: NoDocumentReason | null;
          noDocumentDeadline?: string | null;
        } | null;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          id?: string | null;
          userId?: string | null;
          objectId?: string | null;
          objectType?: ContentType | null;
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
      };
      visits?: Array<{
        __typename?: 'TaskVisitInfo';
        id: string;
        taskObjectId: string;
        executor: {
          __typename?: 'UserInfo';
          id: string;
          username: string;
          firstName?: string | null;
          lastName?: string | null;
          phone?: string | null;
        };
      } | null> | null;
    } | null> | null;
    pagination?: {
      __typename?: 'PaginationOutput';
      hasPrevious?: boolean | null;
      totalElements?: number | null;
      totalPages?: number | null;
      hasContent?: boolean | null;
      hasNext?: boolean | null;
    } | null;
  } | null;
};

export type GetBreachesAndStatusAuditQueryVariables = Exact<{
  dto: MapObjectIdentityInput;
  needBreach?: InputMaybe<Scalars['Boolean']>;
  needProcessing?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetBreachesAndStatusAuditQuery = {
  __typename?: 'Query';
  getBreachesAudit?: {
    __typename?: 'BreachAuditOutput';
    data?: Array<{
      __typename?: 'BreachAudit';
      objectId: string;
      contentType: ContentType;
      timestamp: any;
      value: BreachReason;
      status: AuditStatus;
      fio?: string | null;
    } | null> | null;
  } | null;
  getProcessingStatusAudit?: {
    __typename?: 'ProcessingStatusAuditOutput';
    data?: Array<{
      __typename?: 'ProcessingStatusAudit';
      objectId: string;
      contentType: ContentType;
      timestamp: any;
      value: ProcessingStatusValue;
      status: AuditStatus;
      fio?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  deleteNotification?: boolean | null;
};

export type GetBreachNotificationsQueryVariables = Exact<{
  dto: NotificationInputV2;
}>;

export type GetBreachNotificationsQuery = {
  __typename?: 'Query';
  getBreachNotificationsV3?: {
    __typename?: 'NotificationOutputV2';
    errors?: Array<string | null> | null;
    data?: Array<{ __typename?: 'FileInfo'; link: any; fileId: string } | null> | null;
  } | null;
};

export type GetBreachNotificationsV3QueryVariables = Exact<{
  dto: NotificationInputV2;
}>;

export type GetBreachNotificationsV3Query = {
  __typename?: 'Query';
  getBreachNotificationsV3?: {
    __typename?: 'NotificationOutputV2';
    errors?: Array<string | null> | null;
    data?: Array<{ __typename?: 'FileInfo'; fileId: string; link: any } | null> | null;
  } | null;
};

export type GetHoNotificationsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetHoNotificationsQuery = {
  __typename?: 'Query';
  getHomeOwnership?: {
    __typename?: 'HomeOwnershipOutput';
    notifications?: Array<{
      __typename?: 'NotificationInfo';
      id: string;
      hoId: string;
      genDt: any;
      sendDt?: any | null;
      status?: NotificationStatus | null;
      recipientName?: string | null;
      recipientPhone?: string | null;
      fileId: string;
    } | null> | null;
  } | null;
};

export type NotificationFragment = {
  __typename?: 'NotificationInfo';
  id: string;
  hoId: string;
  genDt: any;
  sendDt?: any | null;
  status?: NotificationStatus | null;
  recipientName?: string | null;
  recipientPhone?: string | null;
  fileId: string;
};

export type SendNotificationQueryVariables = Exact<{
  dto: SendBreachNotificationInput;
}>;

export type SendNotificationQuery = {
  __typename?: 'Query';
  sendBreachNotification?: {
    __typename?: 'SendBreachNotificationOutput';
    status?: SendResponseStatus | null;
  } | null;
};

export type PaginationFragment = {
  __typename?: 'PaginationOutput';
  hasPrevious?: boolean | null;
  totalElements?: number | null;
  totalPages?: number | null;
  hasContent?: boolean | null;
  hasNext?: boolean | null;
};

export type GetTasksPaginationQueryVariables = Exact<{
  pas?: InputMaybe<PaginationAndSortingInput>;
}>;

export type GetTasksPaginationQuery = {
  __typename?: 'Query';
  getTasksV2?: {
    __typename?: 'TaskTableOutputV2';
    tasks?: Array<{
      __typename?: 'TaskResponse';
      id: string;
      name?: string | null;
      executor?: {
        __typename?: 'UserInfo';
        firstName?: string | null;
        lastName?: string | null;
        phone?: string | null;
      } | null;
      objects?: Array<
        | {
            __typename?: 'HomeOwnershipTaskObject';
            id: string;
            status?: TaskObjectStatus | null;
            updateDateTime?: any | null;
            homeOwnership?: {
              __typename?: 'HomeOwnershipOutput';
              id: string;
              status?: SummaryStatus | null;
              plot?: {
                __typename?: 'PlotDTO';
                id?: string | null;
                phone?: string | null;
                owners?: string | null;
                usage?: PlotUsage | null;
                address?: string | null;
                addr?: {
                  __typename?: 'AddressOutput';
                  dadataAddress?: {
                    __typename: 'DadataAddress';
                    id?: string | null;
                    address?: string | null;
                    fiasId?: any | null;
                    fiasLevel: FiasLevel;
                    fiasActualityState: FiasActuality;
                    city?: string | null;
                    cityTypeFull?: string | null;
                    area?: string | null;
                    areaTypeFull?: string | null;
                    settlement?: string | null;
                    settlementTypeFull?: string | null;
                    street?: string | null;
                    streetTypeFull?: string | null;
                    house?: string | null;
                    houseTypeFull?: string | null;
                  } | null;
                  addressDetail?: {
                    __typename: 'AddressDetail';
                    id: string;
                    addressId?: string | null;
                    objId: string;
                    address?: string | null;
                    city?: string | null;
                    cityTypeFull?: string | null;
                    area?: string | null;
                    areaTypeFull?: string | null;
                    settlement?: string | null;
                    room?: string | null;
                    settlementTypeFull?: string | null;
                    street?: string | null;
                    streetTypeFull?: string | null;
                    house?: string | null;
                    houseTypeFull?: string | null;
                  } | null;
                } | null;
              } | null;
              oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
            } | null;
          }
        | {
            __typename?: 'OksTaskObject';
            id: string;
            status?: TaskObjectStatus | null;
            updateDateTime?: any | null;
            oks?: {
              __typename?: 'Oks';
              id: string;
              phone?: string | null;
              owners?: string | null;
              usage?: OksUsage | null;
              addr?: {
                __typename?: 'AddressOutput';
                dadataAddress?: {
                  __typename: 'DadataAddress';
                  id?: string | null;
                  address?: string | null;
                  fiasId?: any | null;
                  fiasLevel: FiasLevel;
                  fiasActualityState: FiasActuality;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
                addressDetail?: {
                  __typename: 'AddressDetail';
                  id: string;
                  addressId?: string | null;
                  objId: string;
                  address?: string | null;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  room?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
              } | null;
            } | null;
          }
        | null
      > | null;
    } | null> | null;
    pagination?: {
      __typename?: 'PaginationOutput';
      hasContent?: boolean | null;
      hasNext?: boolean | null;
      hasPrevious?: boolean | null;
      totalElements?: number | null;
      totalPages?: number | null;
    } | null;
  } | null;
};

export type UpdateProcessStatusMutationVariables = Exact<{
  dto: ProcessingStatusRequest;
}>;

export type UpdateProcessStatusMutation = {
  __typename?: 'Mutation';
  updateProcessingStatus?: { __typename?: 'ProcessingStatusOutput'; id: string } | null;
};

export type AbleToMergeQueryVariables = Exact<{
  dto: AbleToMergeInput;
}>;

export type AbleToMergeQuery = {
  __typename?: 'Query';
  ableToMerge?: Array<{
    __typename?: 'MergeOutput';
    sourceId: string;
    res?: Array<{
      __typename?: 'PlotIntersectionResultOutput';
      isCrosses?: boolean | null;
      geo?: {
        __typename?: 'Geometry';
        coordinates?: Array<Array<Array<number | null> | null> | null> | null;
        type: GeoType;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type MergeObjectsMutationVariables = Exact<{
  dto: MergeInput;
}>;

export type MergeObjectsMutation = {
  __typename?: 'Mutation';
  mergeHomeOwnershipV2?:
    | {
        __typename?: 'HomeOwnershipTaskObject';
        id: string;
        homeOwnership?: {
          __typename?: 'HomeOwnershipOutput';
          id: string;
          plotId: string;
          geom?: {
            __typename?: 'Geometry';
            coordinates?: Array<Array<Array<number | null> | null> | null> | null;
          } | null;
        } | null;
      }
    | { __typename?: 'OksTaskObject'; id: string }
    | null;
};

export type SplitObjectsMutationVariables = Exact<{
  dto: SplitHomeOwnershipInput;
}>;

export type SplitObjectsMutation = {
  __typename?: 'Mutation';
  splitHomeOwnershipV2?: Array<
    | {
        __typename?: 'HomeOwnershipTaskObject';
        id: string;
        homeOwnership?: {
          __typename?: 'HomeOwnershipOutput';
          id: string;
          status?: SummaryStatus | null;
          plotId: string;
          geom?: {
            __typename?: 'Geometry';
            coordinates?: Array<Array<Array<number | null> | null> | null> | null;
          } | null;
        } | null;
      }
    | { __typename?: 'OksTaskObject'; id: string }
    | null
  > | null;
};

export type GetAllInGeoQueryVariables = Exact<{
  dto: AllInWindowInput;
}>;

export type GetAllInGeoQuery = {
  __typename?: 'Query';
  findAllInWindowV2?: Array<{
    __typename?: 'AllInWindowOutput';
    id: string;
    layerId: string;
    contentType?: ContentType | null;
    geo?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    } | null;
  } | null> | null;
};

export type GetAllInGeo2QueryVariables = Exact<{
  dto: AllInWindowInput;
}>;

export type GetAllInGeo2Query = {
  __typename?: 'Query';
  findAllInWindowV2?: Array<{
    __typename?: 'AllInWindowOutput';
    id: string;
    layerId: string;
    contentType?: ContentType | null;
    geo?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    } | null;
  } | null> | null;
};

export type GetAllInGeo3QueryVariables = Exact<{
  dto: AllInWindowInput;
}>;

export type GetAllInGeo3Query = {
  __typename?: 'Query';
  findAllInWindowV2?: Array<{
    __typename?: 'AllInWindowOutput';
    id: string;
    layerId: string;
    geomId: string;
    contentType?: ContentType | null;
  } | null> | null;
};

export type GetCurrentVisitStatusQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetCurrentVisitStatusQuery = {
  __typename?: 'Query';
  getCurrentVisitStatus?: {
    __typename?: 'TaskVisitOutput';
    currentVisitStatus?: VisitStatus | null;
  } | null;
};

export type AddHomeownershipsMutationVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
  wbId: Scalars['ID'];
}>;

export type AddHomeownershipsMutation = {
  __typename?: 'Mutation';
  homeOwnershipFromPlots?: Array<{
    __typename?: 'HomeOwnershipOutput';
    id: string;
    plotId: string;
    plot?: { __typename?: 'PlotDTO'; id?: string | null } | null;
    oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
  } | null> | null;
};

export type GetHomeownershipsQueryVariables = Exact<{
  ids: Array<Scalars['ID']> | Scalars['ID'];
}>;

export type GetHomeownershipsQuery = {
  __typename?: 'Query';
  getHomeOwnerships?: Array<{
    __typename?: 'HomeOwnershipOutput';
    id: string;
    plotId: string;
    plot?: { __typename?: 'PlotDTO'; id?: string | null } | null;
    oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
  } | null> | null;
};

export type GetHoByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  withReasons?: InputMaybe<Scalars['Boolean']>;
  withProcessStatus?: InputMaybe<Scalars['Boolean']>;
  withNotifications?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetHoByIdQuery = {
  __typename?: 'Query';
  getHomeOwnership?: {
    __typename?: 'HomeOwnershipOutput';
    id: string;
    workBorderId: string;
    plot?: {
      __typename?: 'PlotDTO';
      geomId: string;
      id?: string | null;
      cadNum?: string | null;
      plotType?: string | null;
      address?: string | null;
      phone?: string | null;
      comment?: string | null;
      category?: string | null;
      owners?: string | null;
      area?: number | null;
      usage?: PlotUsage | null;
      cost?: number | null;
      layerId?: string | null;
      parentId?: string | null;
      unifiedCadNum?: boolean | null;
      suggestCadNum?: string | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      breachReasonsObj?: Array<{
        __typename?: 'BreachOutput';
        breachReason: BreachReason;
        createDateTime: any;
      } | null> | null;
      processStatusObj?: {
        __typename?: 'ProcessingStatusOutput';
        createDateTime: any;
        updatedDateTime?: any | null;
        status: ProcessingStatusValue;
      } | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentDeadline?: string | null;
        noDocumentReason?: NoDocumentReason | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        reason?: NoInfoReason | null;
        infoType?: ObjectProperty | null;
      } | null> | null;
    } | null;
    oks?: Array<{
      __typename?: 'Oks';
      geomId: string;
      id: string;
      cost?: number | null;
      phone?: string | null;
      usage?: OksUsage | null;
      floor?: number | null;
      cadNum?: string | null;
      owners?: string | null;
      address?: string | null;
      comment?: string | null;
      layerId: string;
      parentId?: string | null;
      suggestCadNum?: string | null;
      unifiedCadNum?: boolean | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      visitStatus?: VisitStatus | null;
      breachReasonsObj?: Array<{
        __typename?: 'BreachOutput';
        breachReason: BreachReason;
        createDateTime: any;
      } | null> | null;
      processStatusObj?: {
        __typename?: 'ProcessingStatusOutput';
        createDateTime: any;
        updatedDateTime?: any | null;
        status: ProcessingStatusValue;
      } | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
      rooms?: Array<{
        __typename?: 'Room';
        id: string;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    notifications?: Array<{
      __typename?: 'NotificationInfo';
      id: string;
      hoId: string;
      genDt: any;
      sendDt?: any | null;
      status?: NotificationStatus | null;
      recipientName?: string | null;
      recipientPhone?: string | null;
      fileId: string;
    } | null> | null;
  } | null;
};

export type GetHoByIdv2QueryVariables = Exact<{
  id: Scalars['ID'];
  taskObjectId: Scalars['ID'];
  withReasons?: InputMaybe<Scalars['Boolean']>;
  withProcessStatus?: InputMaybe<Scalars['Boolean']>;
  withNotifications?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetHoByIdv2Query = {
  __typename?: 'Query';
  getHomeOwnershipV2?: {
    __typename?: 'HomeOwnershipOutput';
    id: string;
    workBorderId: string;
    visitStatus?: VisitStatus | null;
    plot?: {
      __typename?: 'PlotDTO';
      geomId: string;
      id?: string | null;
      cadNum?: string | null;
      plotType?: string | null;
      address?: string | null;
      phone?: string | null;
      comment?: string | null;
      category?: string | null;
      owners?: string | null;
      area?: number | null;
      usage?: PlotUsage | null;
      cost?: number | null;
      layerId?: string | null;
      parentId?: string | null;
      unifiedCadNum?: boolean | null;
      suggestCadNum?: string | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      breachReasonsObj?: Array<{
        __typename?: 'BreachOutput';
        breachReason: BreachReason;
        createDateTime: any;
      } | null> | null;
      processStatusObj?: {
        __typename?: 'ProcessingStatusOutput';
        createDateTime: any;
        updatedDateTime?: any | null;
        status: ProcessingStatusValue;
      } | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentDeadline?: string | null;
        noDocumentReason?: NoDocumentReason | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        reason?: NoInfoReason | null;
        infoType?: ObjectProperty | null;
      } | null> | null;
    } | null;
    oks?: Array<{
      __typename?: 'Oks';
      geomId: string;
      id: string;
      cost?: number | null;
      phone?: string | null;
      usage?: OksUsage | null;
      floor?: number | null;
      cadNum?: string | null;
      owners?: string | null;
      address?: string | null;
      comment?: string | null;
      layerId: string;
      parentId?: string | null;
      suggestCadNum?: string | null;
      unifiedCadNum?: boolean | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      visitStatus?: VisitStatus | null;
      breachReasonsObj?: Array<{
        __typename?: 'BreachOutput';
        breachReason: BreachReason;
        createDateTime: any;
      } | null> | null;
      processStatusObj?: {
        __typename?: 'ProcessingStatusOutput';
        createDateTime: any;
        updatedDateTime?: any | null;
        status: ProcessingStatusValue;
      } | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
      rooms?: Array<{
        __typename?: 'Room';
        id: string;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
    notifications?: Array<{
      __typename?: 'NotificationInfo';
      id: string;
      hoId: string;
      genDt: any;
      sendDt?: any | null;
      status?: NotificationStatus | null;
      recipientName?: string | null;
      recipientPhone?: string | null;
      fileId: string;
    } | null> | null;
  } | null;
};

export type GetHoVisitStatusByIdQueryVariables = Exact<{
  id: Scalars['ID'];
  taskObjectId: Scalars['ID'];
}>;

export type GetHoVisitStatusByIdQuery = {
  __typename?: 'Query';
  getHomeOwnershipV2?: {
    __typename?: 'HomeOwnershipOutput';
    id: string;
    visitStatus?: VisitStatus | null;
  } | null;
};

export type AddOksMutationVariables = Exact<{
  oks: AddOksInput;
}>;

export type AddOksMutation = {
  __typename?: 'Mutation';
  addOks?: {
    __typename?: 'Oks';
    id: string;
    parentId?: string | null;
    floor?: number | null;
    cadNum?: string | null;
    address?: string | null;
    phone?: string | null;
    owners?: string | null;
    comment?: string | null;
    geomId: string;
  } | null;
};

export type DeleteOksMutationVariables = Exact<{
  id: Scalars['ID'];
  taskId: Scalars['ID'];
}>;

export type DeleteOksMutation = { __typename?: 'Mutation'; deleteOksAntRole?: boolean | null };

export type GetOksByIdsQueryVariables = Exact<{
  dto: OksByIdsInput;
  withReasons?: InputMaybe<Scalars['Boolean']>;
  withProcessStatus?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOksByIdsQuery = {
  __typename?: 'Query';
  oksById?: Array<{
    __typename?: 'Oks';
    id: string;
    cost?: number | null;
    phone?: string | null;
    usage?: OksUsage | null;
    floor?: number | null;
    cadNum?: string | null;
    owners?: string | null;
    geomId: string;
    address?: string | null;
    comment?: string | null;
    layerId: string;
    parentId?: string | null;
    suggestCadNum?: string | null;
    unifiedCadNum?: boolean | null;
    commentLabels?: Array<CommentLabelValue | null> | null;
    visitStatus?: VisitStatus | null;
    breachReasonsObj?: Array<{
      __typename?: 'BreachOutput';
      breachReason: BreachReason;
      createDateTime: any;
    } | null> | null;
    processStatusObj?: {
      __typename?: 'ProcessingStatusOutput';
      createDateTime: any;
      updatedDateTime?: any | null;
      status: ProcessingStatusValue;
    } | null;
    addr?: {
      __typename?: 'AddressOutput';
      dadataAddress?: {
        __typename: 'DadataAddress';
        id?: string | null;
        address?: string | null;
        fiasId?: any | null;
        fiasLevel: FiasLevel;
        fiasActualityState: FiasActuality;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
    noDocReason?: {
      __typename?: 'NoDocReasonOutput';
      noDocumentReason?: NoDocumentReason | null;
      noDocumentDeadline?: string | null;
    } | null;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      infoType?: ObjectProperty | null;
      reason?: NoInfoReason | null;
    } | null> | null;
    rooms?: Array<{
      __typename?: 'Room';
      id: string;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type GetOksByIdsv2QueryVariables = Exact<{
  dto: OksByIdsInput;
  taskObjectId?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
  withReasons?: InputMaybe<Scalars['Boolean']>;
  withProcessStatus?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetOksByIdsv2Query = {
  __typename?: 'Query';
  oksByIdV2?: Array<{
    __typename?: 'Oks';
    visitStatus?: VisitStatus | null;
    id: string;
    cost?: number | null;
    phone?: string | null;
    usage?: OksUsage | null;
    floor?: number | null;
    cadNum?: string | null;
    owners?: string | null;
    geomId: string;
    address?: string | null;
    comment?: string | null;
    layerId: string;
    parentId?: string | null;
    suggestCadNum?: string | null;
    unifiedCadNum?: boolean | null;
    commentLabels?: Array<CommentLabelValue | null> | null;
    breachReasonsObj?: Array<{
      __typename?: 'BreachOutput';
      breachReason: BreachReason;
      createDateTime: any;
    } | null> | null;
    processStatusObj?: {
      __typename?: 'ProcessingStatusOutput';
      createDateTime: any;
      updatedDateTime?: any | null;
      status: ProcessingStatusValue;
    } | null;
    addr?: {
      __typename?: 'AddressOutput';
      dadataAddress?: {
        __typename: 'DadataAddress';
        id?: string | null;
        address?: string | null;
        fiasId?: any | null;
        fiasLevel: FiasLevel;
        fiasActualityState: FiasActuality;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
    noDocReason?: {
      __typename?: 'NoDocReasonOutput';
      noDocumentReason?: NoDocumentReason | null;
      noDocumentDeadline?: string | null;
    } | null;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      infoType?: ObjectProperty | null;
      reason?: NoInfoReason | null;
    } | null> | null;
    rooms?: Array<{
      __typename?: 'Room';
      id: string;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
    } | null> | null;
  } | null> | null;
};

export type OksFragmentFragment = {
  __typename?: 'Oks';
  id: string;
  cost?: number | null;
  phone?: string | null;
  usage?: OksUsage | null;
  floor?: number | null;
  cadNum?: string | null;
  owners?: string | null;
  geomId: string;
  address?: string | null;
  comment?: string | null;
  layerId: string;
  parentId?: string | null;
  suggestCadNum?: string | null;
  unifiedCadNum?: boolean | null;
  commentLabels?: Array<CommentLabelValue | null> | null;
  visitStatus?: VisitStatus | null;
  addr?: {
    __typename?: 'AddressOutput';
    dadataAddress?: {
      __typename: 'DadataAddress';
      id?: string | null;
      address?: string | null;
      fiasId?: any | null;
      fiasLevel: FiasLevel;
      fiasActualityState: FiasActuality;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
    addressDetail?: {
      __typename: 'AddressDetail';
      id: string;
      addressId?: string | null;
      objId: string;
      address?: string | null;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      room?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
  } | null;
  noDocReason?: {
    __typename?: 'NoDocReasonOutput';
    noDocumentReason?: NoDocumentReason | null;
    noDocumentDeadline?: string | null;
  } | null;
  noInfoReasons?: Array<{
    __typename?: 'NoInfoReasonResponse';
    infoType?: ObjectProperty | null;
    reason?: NoInfoReason | null;
  } | null> | null;
  rooms?: Array<{
    __typename?: 'Room';
    id: string;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      infoType?: ObjectProperty | null;
      reason?: NoInfoReason | null;
    } | null> | null;
  } | null> | null;
};

export type UpdateOksMutationVariables = Exact<{
  input: UpdateObjectInput;
}>;

export type UpdateOksMutation = {
  __typename?: 'Mutation';
  updateOksInfoV2?: {
    __typename?: 'UpdateOksOutput';
    oks?: Array<{
      __typename?: 'Oks';
      id: string;
      cost?: number | null;
      phone?: string | null;
      usage?: OksUsage | null;
      floor?: number | null;
      cadNum?: string | null;
      owners?: string | null;
      geomId: string;
      address?: string | null;
      comment?: string | null;
      layerId: string;
      parentId?: string | null;
      suggestCadNum?: string | null;
      unifiedCadNum?: boolean | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      visitStatus?: VisitStatus | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
      rooms?: Array<{
        __typename?: 'Room';
        id: string;
        noInfoReasons?: Array<{
          __typename?: 'NoInfoReasonResponse';
          infoType?: ObjectProperty | null;
          reason?: NoInfoReason | null;
        } | null> | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type GetPlotByIdQueryVariables = Exact<{
  plots: PlotByIdsInput;
}>;

export type GetPlotByIdQuery = {
  __typename?: 'Query';
  plotsById?: Array<{
    __typename?: 'PlotDTO';
    id?: string | null;
    cadNum?: string | null;
    plotType?: string | null;
    address?: string | null;
    geomId: string;
    phone?: string | null;
    comment?: string | null;
    category?: string | null;
    owners?: string | null;
    area?: number | null;
    usage?: PlotUsage | null;
    cost?: number | null;
    layerId?: string | null;
    parentId?: string | null;
    unifiedCadNum?: boolean | null;
    suggestCadNum?: string | null;
    commentLabels?: Array<CommentLabelValue | null> | null;
    addr?: {
      __typename?: 'AddressOutput';
      dadataAddress?: {
        __typename: 'DadataAddress';
        id?: string | null;
        address?: string | null;
        fiasId?: any | null;
        fiasLevel: FiasLevel;
        fiasActualityState: FiasActuality;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
    noDocReason?: {
      __typename?: 'NoDocReasonOutput';
      noDocumentDeadline?: string | null;
      noDocumentReason?: NoDocumentReason | null;
    } | null;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      reason?: NoInfoReason | null;
      infoType?: ObjectProperty | null;
    } | null> | null;
  } | null> | null;
};

export type CardInfoFragmentFragment = {
  __typename?: 'PlotDTO';
  id?: string | null;
  cadNum?: string | null;
  plotType?: string | null;
  address?: string | null;
  geomId: string;
  phone?: string | null;
  comment?: string | null;
  category?: string | null;
  owners?: string | null;
  area?: number | null;
  usage?: PlotUsage | null;
  cost?: number | null;
  layerId?: string | null;
  parentId?: string | null;
  unifiedCadNum?: boolean | null;
  suggestCadNum?: string | null;
  commentLabels?: Array<CommentLabelValue | null> | null;
  addr?: {
    __typename?: 'AddressOutput';
    dadataAddress?: {
      __typename: 'DadataAddress';
      id?: string | null;
      address?: string | null;
      fiasId?: any | null;
      fiasLevel: FiasLevel;
      fiasActualityState: FiasActuality;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
    addressDetail?: {
      __typename: 'AddressDetail';
      id: string;
      addressId?: string | null;
      objId: string;
      address?: string | null;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      room?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
  } | null;
  noDocReason?: {
    __typename?: 'NoDocReasonOutput';
    noDocumentDeadline?: string | null;
    noDocumentReason?: NoDocumentReason | null;
  } | null;
  noInfoReasons?: Array<{
    __typename?: 'NoInfoReasonResponse';
    reason?: NoInfoReason | null;
    infoType?: ObjectProperty | null;
  } | null> | null;
};

export type UpdateCardInfoMutationVariables = Exact<{
  input: UpdateObjectInput;
}>;

export type UpdateCardInfoMutation = {
  __typename?: 'Mutation';
  updatePlotInfoV2?: {
    __typename?: 'UpdatePlotOutput';
    plot?: Array<{
      __typename?: 'PlotDTO';
      id?: string | null;
      cadNum?: string | null;
      plotType?: string | null;
      address?: string | null;
      geomId: string;
      phone?: string | null;
      comment?: string | null;
      category?: string | null;
      owners?: string | null;
      area?: number | null;
      usage?: PlotUsage | null;
      cost?: number | null;
      layerId?: string | null;
      parentId?: string | null;
      unifiedCadNum?: boolean | null;
      suggestCadNum?: string | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        noDocumentDeadline?: string | null;
        noDocumentReason?: NoDocumentReason | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        reason?: NoInfoReason | null;
        infoType?: ObjectProperty | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type AddRoomMutationVariables = Exact<{
  dto: AddRoomInput;
}>;

export type AddRoomMutation = {
  __typename?: 'Mutation';
  addRoom?: {
    __typename: 'Room';
    id: string;
    parentId: string;
    cadNum?: string | null;
    unifiedCadNum?: boolean | null;
    usage?: string | null;
    address?: string | null;
    comment?: string | null;
    owners?: string | null;
    phone?: string | null;
    commentLabels?: Array<CommentLabelValue | null> | null;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      infoType?: ObjectProperty | null;
      reason?: NoInfoReason | null;
    } | null> | null;
    noDocReason?: {
      __typename?: 'NoDocReasonOutput';
      contentId?: string | null;
      noDocumentReason?: NoDocumentReason | null;
      noDocumentDeadline?: string | null;
    } | null;
    addr?: {
      __typename?: 'AddressOutput';
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteRoomMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteRoomMutation = { __typename?: 'Mutation'; deleteRoom?: boolean | null };

export type FindRoomParentGeomIdQueryVariables = Exact<{
  roomId: Scalars['ID'];
}>;

export type FindRoomParentGeomIdQuery = { __typename?: 'Query'; oksGeomIdByRoomId: string };

export type GetOksRoomsQueryVariables = Exact<{
  dto: OksByIdsInput;
}>;

export type GetOksRoomsQuery = {
  __typename?: 'Query';
  oksById?: Array<{
    __typename?: 'Oks';
    id: string;
    rooms?: Array<{
      __typename: 'Room';
      id: string;
      parentId: string;
      cadNum?: string | null;
      unifiedCadNum?: boolean | null;
      usage?: string | null;
      address?: string | null;
      comment?: string | null;
      owners?: string | null;
      phone?: string | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        contentId?: string | null;
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      addr?: {
        __typename?: 'AddressOutput';
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
    } | null> | null;
  } | null> | null;
};

export type GetRoomByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetRoomByIdQuery = {
  __typename?: 'Query';
  roomById?: {
    __typename?: 'Room';
    id: string;
    parentId: string;
    cadNum?: string | null;
    usage?: string | null;
    address?: string | null;
    owners?: string | null;
    phone?: string | null;
    comment?: string | null;
    commentLabels?: Array<CommentLabelValue | null> | null;
    unifiedCadNum?: boolean | null;
    addr?: {
      __typename?: 'AddressOutput';
      dadataAddress?: {
        __typename: 'DadataAddress';
        id?: string | null;
        address?: string | null;
        fiasId?: any | null;
        fiasLevel: FiasLevel;
        fiasActualityState: FiasActuality;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
    breachReasonsObj?: Array<{
      __typename?: 'BreachOutput';
      id: string;
      objectId: string;
      contentType: ContentType;
      breachReason: BreachReason;
      createDateTime: any;
      authorId?: string | null;
    } | null> | null;
    processStatusObj?: {
      __typename?: 'ProcessingStatusOutput';
      id: string;
      objectId: string;
      contentType: ContentType;
      status: ProcessingStatusValue;
      createDateTime: any;
      updatedDateTime?: any | null;
      authorId?: string | null;
    } | null;
    noDocReason?: {
      __typename?: 'NoDocReasonOutput';
      contentId?: string | null;
      noDocumentReason?: NoDocumentReason | null;
      noDocumentDeadline?: string | null;
    } | null;
    noInfoReasons?: Array<{
      __typename?: 'NoInfoReasonResponse';
      id?: string | null;
      userId?: string | null;
      objectId?: string | null;
      objectType?: ContentType | null;
      infoType?: ObjectProperty | null;
      reason?: NoInfoReason | null;
    } | null> | null;
  } | null;
};

export type RoomFragment = {
  __typename: 'Room';
  id: string;
  parentId: string;
  cadNum?: string | null;
  unifiedCadNum?: boolean | null;
  usage?: string | null;
  address?: string | null;
  comment?: string | null;
  owners?: string | null;
  phone?: string | null;
  commentLabels?: Array<CommentLabelValue | null> | null;
  noInfoReasons?: Array<{
    __typename?: 'NoInfoReasonResponse';
    infoType?: ObjectProperty | null;
    reason?: NoInfoReason | null;
  } | null> | null;
  noDocReason?: {
    __typename?: 'NoDocReasonOutput';
    contentId?: string | null;
    noDocumentReason?: NoDocumentReason | null;
    noDocumentDeadline?: string | null;
  } | null;
  addr?: {
    __typename?: 'AddressOutput';
    addressDetail?: {
      __typename: 'AddressDetail';
      id: string;
      addressId?: string | null;
      objId: string;
      address?: string | null;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      room?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
  } | null;
};

export type UpdateRoomMutationVariables = Exact<{
  input: UpdateObjectInput;
}>;

export type UpdateRoomMutation = {
  __typename?: 'Mutation';
  updateRoomInfoV2?: {
    __typename?: 'UpdateRoomOutput';
    room?: Array<{
      __typename?: 'Room';
      id: string;
      parentId: string;
      cadNum?: string | null;
      usage?: string | null;
      address?: string | null;
      owners?: string | null;
      phone?: string | null;
      comment?: string | null;
      commentLabels?: Array<CommentLabelValue | null> | null;
      unifiedCadNum?: boolean | null;
      addr?: {
        __typename?: 'AddressOutput';
        dadataAddress?: {
          __typename: 'DadataAddress';
          id?: string | null;
          address?: string | null;
          fiasId?: any | null;
          fiasLevel: FiasLevel;
          fiasActualityState: FiasActuality;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
        addressDetail?: {
          __typename: 'AddressDetail';
          id: string;
          addressId?: string | null;
          objId: string;
          address?: string | null;
          city?: string | null;
          cityTypeFull?: string | null;
          area?: string | null;
          areaTypeFull?: string | null;
          settlement?: string | null;
          room?: string | null;
          settlementTypeFull?: string | null;
          street?: string | null;
          streetTypeFull?: string | null;
          house?: string | null;
          houseTypeFull?: string | null;
        } | null;
      } | null;
      breachReasonsObj?: Array<{
        __typename?: 'BreachOutput';
        id: string;
        objectId: string;
        contentType: ContentType;
        breachReason: BreachReason;
        createDateTime: any;
        authorId?: string | null;
      } | null> | null;
      processStatusObj?: {
        __typename?: 'ProcessingStatusOutput';
        id: string;
        objectId: string;
        contentType: ContentType;
        status: ProcessingStatusValue;
        createDateTime: any;
        updatedDateTime?: any | null;
        authorId?: string | null;
      } | null;
      noDocReason?: {
        __typename?: 'NoDocReasonOutput';
        contentId?: string | null;
        noDocumentReason?: NoDocumentReason | null;
        noDocumentDeadline?: string | null;
      } | null;
      noInfoReasons?: Array<{
        __typename?: 'NoInfoReasonResponse';
        id?: string | null;
        userId?: string | null;
        objectId?: string | null;
        objectType?: ContentType | null;
        infoType?: ObjectProperty | null;
        reason?: NoInfoReason | null;
      } | null> | null;
    } | null> | null;
  } | null;
};

export type UpdateAntSalaryTariffMutationVariables = Exact<{
  input: SalaryTariffInput;
}>;

export type UpdateAntSalaryTariffMutation = {
  __typename?: 'Mutation';
  updateAntSalaryTariff?: {
    __typename?: 'SalaryTariffOutput';
    tariffs?: Array<{
      __typename?: 'SalaryTariffDtoRecord';
      value?: number | null;
      type?: ObjectProperty | null;
    } | null> | null;
  } | null;
};

export type UpdateCommentLabelsMutationVariables = Exact<{
  input: CommentLabelUpdateInputV2;
}>;

export type UpdateCommentLabelsMutation = {
  __typename?: 'Mutation';
  updateCommentLabelsV2?: {
    __typename?: 'CommentLabelUpdateOutput';
    commentLabel?: Array<{ __typename?: 'CommentLabelOutput'; id: string } | null> | null;
  } | null;
};

export type UpdateCurrentVisitStatusMutationVariables = Exact<{
  input: UpdateTaskVisitStatusInput;
}>;

export type UpdateCurrentVisitStatusMutation = {
  __typename?: 'Mutation';
  updateTaskVisitStatus?: { __typename: 'TaskVisitOutput' } | null;
};

export type UpdateNoDocReasonMutationVariables = Exact<{
  dto: NoDocReasonInput;
}>;

export type UpdateNoDocReasonMutation = {
  __typename?: 'Mutation';
  updateNoDocReason?: {
    __typename?: 'NoDocReasonOutput';
    contentId?: string | null;
    noDocumentReason?: NoDocumentReason | null;
    noDocumentDeadline?: string | null;
  } | null;
};

export type BreachReasonsObjFragment = {
  __typename?: 'BreachOutput';
  id: string;
  objectId: string;
  contentType: ContentType;
  breachReason: BreachReason;
  createDateTime: any;
  authorId?: string | null;
};

export type DadataAddressFragment = {
  __typename: 'DadataAddress';
  id?: string | null;
  address?: string | null;
  fiasId?: any | null;
  fiasLevel: FiasLevel;
  fiasActualityState: FiasActuality;
  city?: string | null;
  cityTypeFull?: string | null;
  area?: string | null;
  areaTypeFull?: string | null;
  settlement?: string | null;
  settlementTypeFull?: string | null;
  street?: string | null;
  streetTypeFull?: string | null;
  house?: string | null;
  houseTypeFull?: string | null;
};

export type AddressDetailFragment = {
  __typename: 'AddressDetail';
  id: string;
  addressId?: string | null;
  objId: string;
  address?: string | null;
  city?: string | null;
  cityTypeFull?: string | null;
  area?: string | null;
  areaTypeFull?: string | null;
  settlement?: string | null;
  room?: string | null;
  settlementTypeFull?: string | null;
  street?: string | null;
  streetTypeFull?: string | null;
  house?: string | null;
  houseTypeFull?: string | null;
};

export type CardInfoAddrFragmentFragment = {
  __typename?: 'AddressOutput';
  dadataAddress?: {
    __typename: 'DadataAddress';
    id?: string | null;
    address?: string | null;
    fiasId?: any | null;
    fiasLevel: FiasLevel;
    fiasActualityState: FiasActuality;
    city?: string | null;
    cityTypeFull?: string | null;
    area?: string | null;
    areaTypeFull?: string | null;
    settlement?: string | null;
    settlementTypeFull?: string | null;
    street?: string | null;
    streetTypeFull?: string | null;
    house?: string | null;
    houseTypeFull?: string | null;
  } | null;
  addressDetail?: {
    __typename: 'AddressDetail';
    id: string;
    addressId?: string | null;
    objId: string;
    address?: string | null;
    city?: string | null;
    cityTypeFull?: string | null;
    area?: string | null;
    areaTypeFull?: string | null;
    settlement?: string | null;
    room?: string | null;
    settlementTypeFull?: string | null;
    street?: string | null;
    streetTypeFull?: string | null;
    house?: string | null;
    houseTypeFull?: string | null;
  } | null;
};

export type NoDocReasonFragment = {
  __typename?: 'NoDocReasonOutput';
  contentId?: string | null;
  noDocumentReason?: NoDocumentReason | null;
  noDocumentDeadline?: string | null;
};

export type NoInfoReasonsFragment = {
  __typename?: 'NoInfoReasonResponse';
  id?: string | null;
  userId?: string | null;
  objectId?: string | null;
  objectType?: ContentType | null;
  infoType?: ObjectProperty | null;
  reason?: NoInfoReason | null;
};

export type ProcessStatusObjFragment = {
  __typename?: 'ProcessingStatusOutput';
  id: string;
  objectId: string;
  contentType: ContentType;
  status: ProcessingStatusValue;
  createDateTime: any;
  updatedDateTime?: any | null;
  authorId?: string | null;
};

export type RoomFragmentFragment = {
  __typename?: 'Room';
  id: string;
  parentId: string;
  cadNum?: string | null;
  usage?: string | null;
  address?: string | null;
  owners?: string | null;
  phone?: string | null;
  comment?: string | null;
  commentLabels?: Array<CommentLabelValue | null> | null;
  unifiedCadNum?: boolean | null;
  addr?: {
    __typename?: 'AddressOutput';
    dadataAddress?: {
      __typename: 'DadataAddress';
      id?: string | null;
      address?: string | null;
      fiasId?: any | null;
      fiasLevel: FiasLevel;
      fiasActualityState: FiasActuality;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
    addressDetail?: {
      __typename: 'AddressDetail';
      id: string;
      addressId?: string | null;
      objId: string;
      address?: string | null;
      city?: string | null;
      cityTypeFull?: string | null;
      area?: string | null;
      areaTypeFull?: string | null;
      settlement?: string | null;
      room?: string | null;
      settlementTypeFull?: string | null;
      street?: string | null;
      streetTypeFull?: string | null;
      house?: string | null;
      houseTypeFull?: string | null;
    } | null;
  } | null;
  breachReasonsObj?: Array<{
    __typename?: 'BreachOutput';
    id: string;
    objectId: string;
    contentType: ContentType;
    breachReason: BreachReason;
    createDateTime: any;
    authorId?: string | null;
  } | null> | null;
  processStatusObj?: {
    __typename?: 'ProcessingStatusOutput';
    id: string;
    objectId: string;
    contentType: ContentType;
    status: ProcessingStatusValue;
    createDateTime: any;
    updatedDateTime?: any | null;
    authorId?: string | null;
  } | null;
  noDocReason?: {
    __typename?: 'NoDocReasonOutput';
    contentId?: string | null;
    noDocumentReason?: NoDocumentReason | null;
    noDocumentDeadline?: string | null;
  } | null;
  noInfoReasons?: Array<{
    __typename?: 'NoInfoReasonResponse';
    id?: string | null;
    userId?: string | null;
    objectId?: string | null;
    objectType?: ContentType | null;
    infoType?: ObjectProperty | null;
    reason?: NoInfoReason | null;
  } | null> | null;
};

export type FindByWindowQueryVariables = Exact<{
  tile: WindowInput;
}>;

export type FindByWindowQuery = {
  __typename?: 'Query';
  findByWindowV3?: Array<{
    __typename?: 'GeoOutput';
    id: string;
    hintValue?: string | null;
    status?: PlotDataStatus | null;
    geo?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    } | null;
  } | null> | null;
};

export type FindByWindow2QueryVariables = Exact<{
  tile: WindowInput;
}>;

export type FindByWindow2Query = {
  __typename?: 'Query';
  findByWindowV3?: Array<{
    __typename?: 'GeoOutput';
    id: string;
    hintValue?: string | null;
    status?: PlotDataStatus | null;
    geo?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    } | null;
  } | null> | null;
};

export type FindByWindow3QueryVariables = Exact<{
  tile: WindowInput;
}>;

export type FindByWindow3Query = {
  __typename?: 'Query';
  findByWindowV3?: Array<{
    __typename?: 'GeoOutput';
    id: string;
    hintValue?: string | null;
    status?: PlotDataStatus | null;
    geomId: string;
  } | null> | null;
};

export type GetBordersQueryVariables = Exact<{
  withGeo?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetBordersQuery = {
  __typename?: 'Query';
  bordersByToken?: Array<{
    __typename?: 'BorderOutput';
    id: string;
    name: string;
    bType?: WorkBorderType | null;
    cityName?: string | null;
    parentId?: string | null;
    geo?: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
      type: GeoType;
    };
  } | null> | null;
};

export type GetMapObjectsQueryVariables = Exact<{
  tile: MapObjectInput;
}>;

export type GetMapObjectsQuery = {
  __typename?: 'Query';
  getMapObjects?: Array<{
    __typename?: 'MapObjectOutput';
    id: string;
    geomId: string;
    contentType?: ContentType | null;
    status?: InTaskStatusOutput | null;
    wasInTask: boolean;
    layerId: string;
  } | null> | null;
};

export type GetCachedMapObjectsQueryVariables = Exact<{
  tile: MapObjectInput;
}>;

export type GetCachedMapObjectsQuery = {
  __typename?: 'Query';
  getCachedMapObjects?: Array<{
    __typename?: 'MapObjectOutput';
    id: string;
    geomId: string;
    contentType?: ContentType | null;
    status?: InTaskStatusOutput | null;
    wasInTask: boolean;
    layerId: string;
  } | null> | null;
};

export type AllRegionsQueryVariables = Exact<{ [key: string]: never }>;

export type AllRegionsQuery = {
  __typename?: 'Query';
  regionBordersByToken?: Array<{
    __typename?: 'OfpBorderOutput';
    id: string;
    name: string;
    ofpName: string;
    geo: {
      __typename?: 'Geometry';
      coordinates?: Array<Array<Array<number | null> | null> | null> | null;
    };
  } | null> | null;
};

export type HeatMapQueryVariables = Exact<{
  dto: HeatMapInput;
}>;

export type HeatMapQuery = {
  __typename?: 'Query';
  heatMap?: Array<{
    __typename?: 'HeatMapOutput';
    count: number;
    geo: { __typename?: 'Point'; coordinates?: Array<number | null> | null; type?: GeoType | null };
  } | null> | null;
};

export type WorkBordersByRegionQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WorkBordersByRegionQuery = {
  __typename?: 'Query';
  workBordersByRegionId?: Array<{ __typename?: 'BorderOutput'; id: string } | null> | null;
};

export type AddGroupMutationVariables = Exact<{
  group: AddGroupInput;
}>;

export type AddGroupMutation = {
  __typename?: 'Mutation';
  addGroup?: {
    __typename?: 'LayerGroup';
    id: string;
    name: string;
    parentId?: number | null;
  } | null;
};

export type AddLayerMutationVariables = Exact<{
  layer: LayerAddInput;
}>;

export type AddLayerMutation = {
  __typename?: 'Mutation';
  addLayer?: {
    __typename?: 'Layer';
    id: string;
    name: string;
    layerType: LayerType;
    contentType: ContentType;
    groupId?: number | null;
    opacity: number;
    editable: boolean;
    color: string;
    visible: boolean;
  } | null;
};

export type DeleteLGroupMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLGroupMutation = { __typename?: 'Mutation'; deleteGroup?: boolean | null };

export type DeleteLayerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteLayerMutation = { __typename?: 'Mutation'; deleteLayer?: boolean | null };

export type GetAllLayoutsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllLayoutsQuery = {
  __typename?: 'Query';
  allLayers?: Array<{
    __typename?: 'Layer';
    id: string;
    name: string;
    groupId?: number | null;
    opacity: number;
    editable: boolean;
    updatable: boolean;
    color: string;
    visible: boolean;
    contentType: ContentType;
    layerType: LayerType;
    zMax: number;
    zMin: number;
    count: number;
  } | null> | null;
  groups?: Array<{
    __typename?: 'GroupDTO';
    id?: string | null;
    name: string;
    parentId?: number | null;
  } | null> | null;
};

export type LayersByTokenQueryVariables = Exact<{ [key: string]: never }>;

export type LayersByTokenQuery = {
  __typename?: 'Query';
  layersByToken?: Array<{
    __typename?: 'Layer';
    id: string;
    name: string;
    groupId?: number | null;
    opacity: number;
    editable: boolean;
    updatable: boolean;
    color: string;
    visible: boolean;
    contentType: ContentType;
    layerType: LayerType;
    zMax: number;
    zMin: number;
    count: number;
    isAllowedToExport?: boolean | null;
    isAllowedToImport?: boolean | null;
    taskLayer?: boolean | null;
  } | null> | null;
};

export type UpdateLayerMutationVariables = Exact<{
  layer: LayerUpdateInput;
}>;

export type UpdateLayerMutation = {
  __typename?: 'Mutation';
  updateLayer?: { __typename?: 'Layer'; id: string } | null;
};

export type UpdateMapObjectLayerMutationVariables = Exact<{
  dto: Array<UpdateObjectLayerInput> | UpdateObjectLayerInput;
}>;

export type UpdateMapObjectLayerMutation = {
  __typename?: 'Mutation';
  updateMapObjectLayer?: boolean | null;
};

export type AddTaskMutationVariables = Exact<{
  task: TaskRequest;
}>;

export type AddTaskMutation = {
  __typename?: 'Mutation';
  addTask?: { __typename?: 'TaskResponse'; id: string } | null;
};

export type DeleteTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type DeleteTaskMutation = { __typename?: 'Mutation'; deleteTask?: boolean | null };

export type UpdateTaskStatusMutationVariables = Exact<{
  dto: UpdateTaskObjectStatusInput;
}>;

export type UpdateTaskStatusMutation = {
  __typename?: 'Mutation';
  updateTaskObjectStatus?: {
    __typename?: 'UpdateTaskObjectOutput';
    objectId: string;
    status?: TaskObjectStatus | null;
  } | null;
};

export type GetTasksQueryVariables = Exact<{ [key: string]: never }>;

export type GetTasksQuery = {
  __typename?: 'Query';
  getTasks?: Array<{
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    authorName: string;
    deadLine?: any | null;
    executorName?: string | null;
    type: TaskType;
    objects?: Array<
      | {
          __typename?: 'HomeOwnershipTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          homeOwnership?: {
            __typename?: 'HomeOwnershipOutput';
            id: string;
            plotId: string;
            plot?: { __typename?: 'PlotDTO'; geomId: string } | null;
          } | null;
        }
      | {
          __typename?: 'OksTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          oks?: { __typename?: 'Oks'; id: string; geomId: string; layerId: string } | null;
        }
      | null
    > | null;
    objectsStatuses: Array<{
      __typename?: 'TaskObjectsStatus';
      count: number;
      status: TaskObjectStatus;
      labelColor: string;
      hexColor: string;
      label: string;
    } | null>;
  } | null> | null;
};

export type GetTasksByRegionAndExecutorQueryVariables = Exact<{
  dto: ManagerFilterTasksInput;
}>;

export type GetTasksByRegionAndExecutorQuery = {
  __typename?: 'Query';
  getTasksV3?: Array<{
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    type: TaskType;
    deadLine?: any | null;
    author?: {
      __typename?: 'UserInfo';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    executor?: {
      __typename?: 'UserInfo';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    objectsStatuses: Array<{
      __typename?: 'TaskObjectsStatus';
      count: number;
      status: TaskObjectStatus;
      labelColor: string;
      hexColor: string;
      label: string;
    } | null>;
  } | null> | null;
};

export type GetTasksMinimalQueryVariables = Exact<{ [key: string]: never }>;

export type GetTasksMinimalQuery = {
  __typename?: 'Query';
  getTasks?: Array<{
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    type: TaskType;
    deadLine?: any | null;
    author?: {
      __typename?: 'UserInfo';
      id: string;
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    executor?: {
      __typename?: 'UserInfo';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    objects?: Array<
      | {
          __typename?: 'HomeOwnershipTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          homeOwnership?: {
            __typename?: 'HomeOwnershipOutput';
            id: string;
            plot?: { __typename?: 'PlotDTO'; geomId: string } | null;
          } | null;
        }
      | {
          __typename?: 'OksTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          oks?: { __typename?: 'Oks'; id: string; layerId: string } | null;
        }
      | null
    > | null;
    objectsStatuses: Array<{
      __typename?: 'TaskObjectsStatus';
      status: TaskObjectStatus;
      labelColor: string;
      hexColor: string;
      count: number;
      label: string;
    } | null>;
  } | null> | null;
};

export type GetAuthorTasksQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetAuthorTasksQuery = {
  __typename?: 'Query';
  getAuthorTasks?: Array<{
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    type: TaskType;
    deadLine?: any | null;
    author?: {
      __typename?: 'UserInfo';
      id: string;
      lastName?: string | null;
      firstName?: string | null;
    } | null;
    executor?: {
      __typename?: 'UserInfo';
      id: string;
      firstName?: string | null;
      lastName?: string | null;
    } | null;
    objectsStatuses: Array<{
      __typename?: 'TaskObjectsStatus';
      status: TaskObjectStatus;
      labelColor: string;
      hexColor: string;
      count: number;
      label: string;
    } | null>;
  } | null> | null;
};

export type GetTaskByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTaskByIdQuery = {
  __typename?: 'Query';
  getTask?: {
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    type: TaskType;
    objects?: Array<
      | {
          __typename?: 'HomeOwnershipTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          homeOwnership?: { __typename?: 'HomeOwnershipOutput'; id: string } | null;
        }
      | {
          __typename?: 'OksTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          oks?: { __typename?: 'Oks'; id: string; layerId: string; type?: string | null } | null;
        }
      | null
    > | null;
  } | null;
};

export type GetTaskGeoQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetTaskGeoQuery = {
  __typename?: 'Query';
  getTask?: {
    __typename?: 'TaskResponse';
    id: string;
    name?: string | null;
    type: TaskType;
    objects?: Array<
      | {
          __typename?: 'HomeOwnershipTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          homeOwnership?: {
            __typename?: 'HomeOwnershipOutput';
            id: string;
            plotId: string;
            plot?: {
              __typename?: 'PlotDTO';
              geomId: string;
              address?: string | null;
              addr?: {
                __typename?: 'AddressOutput';
                dadataAddress?: {
                  __typename: 'DadataAddress';
                  id?: string | null;
                  address?: string | null;
                  fiasId?: any | null;
                  fiasLevel: FiasLevel;
                  fiasActualityState: FiasActuality;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
                addressDetail?: {
                  __typename: 'AddressDetail';
                  id: string;
                  addressId?: string | null;
                  objId: string;
                  address?: string | null;
                  city?: string | null;
                  cityTypeFull?: string | null;
                  area?: string | null;
                  areaTypeFull?: string | null;
                  settlement?: string | null;
                  room?: string | null;
                  settlementTypeFull?: string | null;
                  street?: string | null;
                  streetTypeFull?: string | null;
                  house?: string | null;
                  houseTypeFull?: string | null;
                } | null;
              } | null;
            } | null;
          } | null;
        }
      | {
          __typename?: 'OksTaskObject';
          id: string;
          status?: TaskObjectStatus | null;
          oks?: {
            __typename?: 'Oks';
            id: string;
            geomId: string;
            layerId: string;
            address?: string | null;
            addr?: {
              __typename?: 'AddressOutput';
              dadataAddress?: {
                __typename: 'DadataAddress';
                id?: string | null;
                address?: string | null;
                fiasId?: any | null;
                fiasLevel: FiasLevel;
                fiasActualityState: FiasActuality;
                city?: string | null;
                cityTypeFull?: string | null;
                area?: string | null;
                areaTypeFull?: string | null;
                settlement?: string | null;
                settlementTypeFull?: string | null;
                street?: string | null;
                streetTypeFull?: string | null;
                house?: string | null;
                houseTypeFull?: string | null;
              } | null;
              addressDetail?: {
                __typename: 'AddressDetail';
                id: string;
                addressId?: string | null;
                objId: string;
                address?: string | null;
                city?: string | null;
                cityTypeFull?: string | null;
                area?: string | null;
                areaTypeFull?: string | null;
                settlement?: string | null;
                room?: string | null;
                settlementTypeFull?: string | null;
                street?: string | null;
                streetTypeFull?: string | null;
                house?: string | null;
                houseTypeFull?: string | null;
              } | null;
            } | null;
          } | null;
        }
      | null
    > | null;
  } | null;
};

export type TaskFragment = {
  __typename?: 'TaskResponse';
  id: string;
  name?: string | null;
  authorName: string;
  deadLine?: any | null;
  description?: string | null;
  executorName?: string | null;
  objects?: Array<
    | {
        __typename?: 'HomeOwnershipTaskObject';
        id: string;
        status?: TaskObjectStatus | null;
        homeOwnership?: {
          __typename?: 'HomeOwnershipOutput';
          id: string;
          plotId: string;
          plot?: { __typename?: 'PlotDTO'; id?: string | null } | null;
          oks?: Array<{ __typename?: 'Oks'; id: string } | null> | null;
        } | null;
        geo?: {
          __typename?: 'GeoSearchOutput';
          geo?: {
            __typename?: 'Geometry';
            coordinates?: Array<Array<Array<number | null> | null> | null> | null;
          } | null;
        } | null;
      }
    | {
        __typename?: 'OksTaskObject';
        id: string;
        status?: TaskObjectStatus | null;
        oks?: { __typename?: 'Oks'; id: string; layerId: string } | null;
        geo?: {
          __typename?: 'GeoSearchOutput';
          geo?: {
            __typename?: 'Geometry';
            coordinates?: Array<Array<Array<number | null> | null> | null> | null;
          } | null;
        } | null;
      }
    | null
  > | null;
  objectsStatuses: Array<{
    __typename?: 'TaskObjectsStatus';
    count: number;
    status: TaskObjectStatus;
    labelColor: string;
    label: string;
  } | null>;
};

export type TaskStatusFragment = {
  __typename?: 'TaskObjectsStatus';
  status: TaskObjectStatus;
  labelColor: string;
  hexColor: string;
  count: number;
  label: string;
};

export type BlockUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type BlockUserMutation = {
  __typename?: 'Mutation';
  blockUser?: { __typename?: 'UserResponse'; id: string } | null;
};

export type DeleteUserMutationVariables = Exact<{
  userId: Scalars['ID'];
}>;

export type DeleteUserMutation = { __typename?: 'Mutation'; deleteUser?: boolean | null };

export type AllRegionsWithOutGeoQueryVariables = Exact<{ [key: string]: never }>;

export type AllRegionsWithOutGeoQuery = {
  __typename?: 'Query';
  regionBordersByToken?: Array<{
    __typename?: 'OfpBorderOutput';
    id: string;
    name: string;
    ofpName: string;
  } | null> | null;
};

export type WorkBordersByRegionWithNameQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type WorkBordersByRegionWithNameQuery = {
  __typename?: 'Query';
  workBordersByRegionId?: Array<{
    __typename?: 'BorderOutput';
    id: string;
    name: string;
  } | null> | null;
};

export type GetUsersByBorderQueryVariables = Exact<{
  id: Scalars['ID'];
}>;

export type GetUsersByBorderQuery = {
  __typename?: 'Query';
  getUsersByWorkBorderId?: Array<{
    __typename?: 'UserResponse';
    id: string;
    username: string;
    lastName?: string | null;
    firstName?: string | null;
    phone?: string | null;
    assignedUsers?: Array<{ __typename?: 'AssignedUserResponse'; id: string } | null> | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      parentId?: string | null;
      geo: {
        __typename?: 'Geometry';
        coordinates?: Array<Array<Array<number | null> | null> | null> | null;
      };
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null> | null;
};

export type GetUsersByBordersQueryVariables = Exact<{
  ids?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;

export type GetUsersByBordersQuery = {
  __typename?: 'Query';
  getUsers?: Array<{
    __typename?: 'UserResponse';
    id: string;
    username: string;
    lastName?: string | null;
    firstName?: string | null;
    phone?: string | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      parentId?: string | null;
      geo: {
        __typename?: 'Geometry';
        coordinates?: Array<Array<Array<number | null> | null> | null> | null;
      };
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null> | null;
};

export type GetUsersByTerritoryIdQueryVariables = Exact<{
  territoryId: Scalars['ID'];
}>;

export type GetUsersByTerritoryIdQuery = {
  __typename?: 'Query';
  getUsersByTerritoryId?: Array<{
    __typename?: 'UserResponse';
    id: string;
    username: string;
    lastName?: string | null;
    firstName?: string | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      geo: {
        __typename?: 'Geometry';
        coordinates?: Array<Array<Array<number | null> | null> | null> | null;
      };
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null> | null;
};

export type GetUsersByTokenQueryVariables = Exact<{
  withRegistries?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUsersByTokenQuery = {
  __typename?: 'Query';
  getUsersByToken?: Array<{
    __typename?: 'UserResponse';
    id: string;
    username: string;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    deletedAt?: any | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      id: string;
      name: string;
      parentId?: string | null;
    } | null> | null;
    assignedUsers?: Array<{
      __typename?: 'AssignedUserResponse';
      id: string;
      username: string;
      phone?: string | null;
      roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
    } | null> | null;
    registries?: Array<{
      __typename?: 'RegistryOutput';
      id: string;
      name: string;
      type: DataSourceKind;
      subtype: EntitySearchSubtype;
    } | null> | null;
  } | null> | null;
};

export type AssignedUsersFragment = {
  __typename?: 'AssignedUserResponse';
  id: string;
  username: string;
  userInfo?: {
    __typename?: 'UserInfo';
    firstName?: string | null;
    lastName?: string | null;
  } | null;
  roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
};

export type WorkBordersFragment = {
  __typename?: 'BorderOutput';
  name: string;
  id: string;
  parentId?: string | null;
};

export type RolesFragment = { __typename?: 'Role'; roleName?: string | null };

export type RegistriesFragment = {
  __typename?: 'RegistryOutput';
  id: string;
  name: string;
  type: DataSourceKind;
  subtype: EntitySearchSubtype;
};

export type GetUserByIdQueryVariables = Exact<{
  userId: Scalars['ID'];
  needAssignedUsers?: InputMaybe<Scalars['Boolean']>;
  needWorkBorders?: InputMaybe<Scalars['Boolean']>;
  needRoles?: InputMaybe<Scalars['Boolean']>;
  needRegistries?: InputMaybe<Scalars['Boolean']>;
}>;

export type GetUserByIdQuery = {
  __typename?: 'Query';
  getUserByID?: {
    __typename?: 'UserResponse';
    id: string;
    username: string;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    defaultRegionId?: string | null;
    regions?: Array<{ __typename?: 'OfpBorderOutput'; name: string; id: string } | null> | null;
    assignedUsers?: Array<{
      __typename?: 'AssignedUserResponse';
      id: string;
      username: string;
      userInfo?: {
        __typename?: 'UserInfo';
        firstName?: string | null;
        lastName?: string | null;
      } | null;
      roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
    } | null> | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      parentId?: string | null;
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
    registries?: Array<{
      __typename?: 'RegistryOutput';
      id: string;
      name: string;
      type: DataSourceKind;
      subtype: EntitySearchSubtype;
    } | null> | null;
  } | null;
};

export type RegisterUserV2MutationVariables = Exact<{
  user?: InputMaybe<RegisterUserInputV2>;
}>;

export type RegisterUserV2Mutation = {
  __typename?: 'Mutation';
  registerUserV2?: {
    __typename?: 'UserResponse';
    id: string;
    username: string;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      parentId?: string | null;
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null;
};

export type UnblockUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;

export type UnblockUserMutation = {
  __typename?: 'Mutation';
  unblockUser?: { __typename?: 'UserResponse'; id: string } | null;
};

export type UpdateUserV2MutationVariables = Exact<{
  user?: InputMaybe<UserUpdateRequestV2>;
}>;

export type UpdateUserV2Mutation = {
  __typename?: 'Mutation';
  updateUserV2?: {
    __typename?: 'UserResponse';
    id: string;
    username: string;
    firstName?: string | null;
    lastName?: string | null;
    phone?: string | null;
    workBorders?: Array<{
      __typename?: 'BorderOutput';
      name: string;
      id: string;
      parentId?: string | null;
    } | null> | null;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null;
};

export type UserFragment = {
  __typename?: 'UserResponse';
  id: string;
  username: string;
  lastName?: string | null;
  firstName?: string | null;
  workBorders?: Array<{ __typename?: 'BorderOutput'; name: string; id: string } | null> | null;
  roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
};

export type UserNotGeoFragment = {
  __typename?: 'UserResponse';
  id: string;
  username: string;
  firstName?: string | null;
  lastName?: string | null;
  phone?: string | null;
  assignedUsers?: Array<{
    __typename?: 'AssignedUserResponse';
    id: string;
    username: string;
    roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  } | null> | null;
  workBorders?: Array<{
    __typename?: 'BorderOutput';
    name: string;
    id: string;
    parentId?: string | null;
  } | null> | null;
  roles?: Array<{ __typename?: 'Role'; roleName?: string | null } | null> | null;
  registries?: Array<{
    __typename?: 'RegistryOutput';
    name: string;
    subtype: EntitySearchSubtype;
    type: DataSourceKind;
  } | null> | null;
};

export type AddressFragment = {
  __typename?: 'DadataAddressOutput';
  address?: string | null;
  area?: string | null;
  areaTypeFull?: string | null;
  city?: string | null;
  cityTypeFull?: string | null;
  fiasActualityState?: FiasActuality | null;
  fiasId?: any | null;
  fiasLevel?: FiasLevel | null;
  house?: string | null;
  houseTypeFull?: string | null;
  settlement?: string | null;
  settlementTypeFull?: string | null;
  streetTypeFull?: string | null;
  street?: string | null;
  qcGeo?: QcGeo | null;
  lon?: number | null;
  lat?: number | null;
};

export type AddAddressMutationVariables = Exact<{
  input: AddressInputV2;
  withDetails?: InputMaybe<Scalars['Boolean']>;
}>;

export type AddAddressMutation = {
  __typename?: 'Mutation';
  addAddressV2?: {
    __typename?: 'AddressUpdateOutput';
    address?: {
      __typename?: 'AddressOutput';
      dadataAddress?: { __typename?: 'DadataAddress'; address?: string | null } | null;
      addressDetail?: {
        __typename: 'AddressDetail';
        id: string;
        addressId?: string | null;
        objId: string;
        address?: string | null;
        city?: string | null;
        cityTypeFull?: string | null;
        area?: string | null;
        areaTypeFull?: string | null;
        settlement?: string | null;
        room?: string | null;
        settlementTypeFull?: string | null;
        street?: string | null;
        streetTypeFull?: string | null;
        house?: string | null;
        houseTypeFull?: string | null;
      } | null;
    } | null;
  } | null;
};

export type DeleteAddressMutationVariables = Exact<{
  dto: DeleteAddressInput;
}>;

export type DeleteAddressMutation = { __typename?: 'Mutation'; deleteAddressV2?: boolean | null };

export type GetAddressByCoordsQueryVariables = Exact<{
  options: DadataGeoSearchInput;
}>;

export type GetAddressByCoordsQuery = {
  __typename?: 'Query';
  dadataGeoSearch?: Array<{
    __typename?: 'DadataAddressOutput';
    address?: string | null;
    area?: string | null;
    areaTypeFull?: string | null;
    city?: string | null;
    cityTypeFull?: string | null;
    fiasActualityState?: FiasActuality | null;
    fiasId?: any | null;
    fiasLevel?: FiasLevel | null;
    house?: string | null;
    houseTypeFull?: string | null;
    settlement?: string | null;
    settlementTypeFull?: string | null;
    streetTypeFull?: string | null;
    street?: string | null;
    qcGeo?: QcGeo | null;
    lon?: number | null;
    lat?: number | null;
  } | null> | null;
};

export type GetAddressByStringQueryVariables = Exact<{
  dto: DadataSuggestAddressInput;
}>;

export type GetAddressByStringQuery = {
  __typename?: 'Query';
  dadataSuggestAddress?: Array<{
    __typename?: 'DadataAddressOutput';
    address?: string | null;
    area?: string | null;
    areaTypeFull?: string | null;
    city?: string | null;
    cityTypeFull?: string | null;
    fiasActualityState?: FiasActuality | null;
    fiasId?: any | null;
    fiasLevel?: FiasLevel | null;
    house?: string | null;
    houseTypeFull?: string | null;
    settlement?: string | null;
    settlementTypeFull?: string | null;
    streetTypeFull?: string | null;
    street?: string | null;
    qcGeo?: QcGeo | null;
    lon?: number | null;
    lat?: number | null;
  } | null> | null;
};

export const RegionsAnalyticsMetricsFragmentDoc = gql`
  fragment regionsAnalyticsMetrics on MetricAnalyticsOutput {
    kName
    kMetric
    vMetric
    unit
    id
  }
`;
export const DadataAddressFragmentDoc = gql`
  fragment dadataAddress on DadataAddress {
    __typename
    id
    address
    fiasId
    fiasLevel
    fiasActualityState
    city
    cityTypeFull
    area
    areaTypeFull
    settlement
    settlementTypeFull
    street
    streetTypeFull
    house
    houseTypeFull
  }
`;
export const AddressDetailFragmentDoc = gql`
  fragment addressDetail on AddressDetail {
    __typename
    id
    addressId
    objId
    address
    city
    cityTypeFull
    area
    areaTypeFull
    settlement
    room
    settlementTypeFull
    street
    streetTypeFull
    house
    houseTypeFull
    room
  }
`;
export const CardInfoAddrFragmentFragmentDoc = gql`
  fragment cardInfoAddrFragment on AddressOutput {
    dadataAddress {
      ...dadataAddress
    }
    addressDetail {
      ...addressDetail
    }
  }
  ${DadataAddressFragmentDoc}
  ${AddressDetailFragmentDoc}
`;
export const OksFragmentFragmentDoc = gql`
  fragment oksFragment on Oks {
    id
    addr {
      ...cardInfoAddrFragment
    }
    cost
    phone
    usage
    floor
    cadNum
    owners
    geomId
    address
    comment
    layerId
    parentId
    suggestCadNum
    unifiedCadNum
    commentLabels
    visitStatus
    noDocReason {
      noDocumentReason
      noDocumentDeadline
    }
    noInfoReasons {
      infoType
      reason
    }
    rooms {
      id
      noInfoReasons {
        infoType
        reason
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
`;
export const PaginationFragmentDoc = gql`
  fragment pagination on PaginationOutput {
    hasPrevious
    totalElements
    totalPages
    hasContent
    hasNext
  }
`;
export const OksFilterFragmentFragmentDoc = gql`
  fragment oksFilterFragment on OksFilterOutput {
    taskType
    countData
    result {
      oks {
        ...oksFragment
      }
      visits {
        id
        taskObjectId
        executor {
          id
          username
          firstName
          lastName
          phone
        }
      }
    }
    pagination {
      ...pagination
    }
  }
  ${OksFragmentFragmentDoc}
  ${PaginationFragmentDoc}
`;
export const NotificationFragmentDoc = gql`
  fragment notification on NotificationInfo {
    id
    hoId
    genDt
    sendDt
    status
    recipientName
    recipientPhone
    fileId
  }
`;
export const CardInfoFragmentFragmentDoc = gql`
  fragment cardInfoFragment on PlotDTO {
    id
    cadNum
    plotType
    address
    geomId
    addr {
      ...cardInfoAddrFragment
    }
    phone
    comment
    category
    owners
    area
    usage
    cost
    layerId
    parentId
    unifiedCadNum
    suggestCadNum
    commentLabels
    noDocReason {
      noDocumentDeadline
      noDocumentReason
    }
    noInfoReasons {
      reason
      infoType
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
`;
export const RoomFragmentDoc = gql`
  fragment room on Room {
    __typename
    id
    parentId
    cadNum
    unifiedCadNum
    usage
    address
    comment
    owners
    phone
    noInfoReasons {
      infoType
      reason
    }
    noDocReason {
      contentId
      noDocumentReason
      noDocumentDeadline
    }
    commentLabels
    addr {
      addressDetail {
        ...addressDetail
      }
    }
  }
  ${AddressDetailFragmentDoc}
`;
export const BreachReasonsObjFragmentDoc = gql`
  fragment breachReasonsObj on BreachOutput {
    id
    objectId
    contentType
    breachReason
    createDateTime
    authorId
  }
`;
export const ProcessStatusObjFragmentDoc = gql`
  fragment processStatusObj on ProcessingStatusOutput {
    id
    objectId
    contentType
    status
    createDateTime
    updatedDateTime
    authorId
  }
`;
export const NoDocReasonFragmentDoc = gql`
  fragment noDocReason on NoDocReasonOutput {
    contentId
    noDocumentReason
    noDocumentDeadline
  }
`;
export const NoInfoReasonsFragmentDoc = gql`
  fragment noInfoReasons on NoInfoReasonResponse {
    id
    userId
    objectId
    objectType
    infoType
    reason
  }
`;
export const RoomFragmentFragmentDoc = gql`
  fragment roomFragment on Room {
    id
    parentId
    cadNum
    usage
    address
    owners
    phone
    comment
    commentLabels
    unifiedCadNum
    addr {
      ...cardInfoAddrFragment
    }
    breachReasonsObj {
      ...breachReasonsObj
    }
    processStatusObj {
      ...processStatusObj
    }
    noDocReason {
      ...noDocReason
    }
    noInfoReasons {
      ...noInfoReasons
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
  ${BreachReasonsObjFragmentDoc}
  ${ProcessStatusObjFragmentDoc}
  ${NoDocReasonFragmentDoc}
  ${NoInfoReasonsFragmentDoc}
`;
export const TaskFragmentDoc = gql`
  fragment task on TaskResponse {
    id
    name
    authorName
    deadLine
    description
    executorName
    objects {
      id
      ... on HomeOwnershipTaskObject {
        homeOwnership {
          id
          plotId
          plot {
            id
          }
          oks {
            id
          }
        }
      }
      ... on OksTaskObject {
        id
        oks {
          id
          layerId
        }
      }
      status
      geo {
        geo {
          coordinates
        }
      }
    }
    objectsStatuses {
      count
      status
      labelColor
      label
    }
  }
`;
export const TaskStatusFragmentDoc = gql`
  fragment taskStatus on TaskObjectsStatus {
    status
    labelColor
    hexColor
    count
    label
  }
`;
export const AssignedUsersFragmentDoc = gql`
  fragment assignedUsers on AssignedUserResponse {
    id
    username
    userInfo {
      firstName
      lastName
    }
    roles {
      roleName
    }
  }
`;
export const WorkBordersFragmentDoc = gql`
  fragment workBorders on BorderOutput {
    name
    id
    parentId
  }
`;
export const RolesFragmentDoc = gql`
  fragment roles on Role {
    roleName
  }
`;
export const RegistriesFragmentDoc = gql`
  fragment registries on RegistryOutput {
    id
    name
    type
    subtype
  }
`;
export const UserFragmentDoc = gql`
  fragment user on UserResponse {
    id
    username
    lastName
    firstName
    workBorders {
      name
      id
    }
    roles {
      roleName
    }
  }
`;
export const UserNotGeoFragmentDoc = gql`
  fragment userNotGeo on UserResponse {
    id
    assignedUsers {
      id
      username
      roles {
        roleName
      }
    }
    username
    firstName
    lastName
    phone
    workBorders {
      name
      id
      parentId
    }
    roles {
      roleName
    }
    registries {
      name
      subtype
      type
    }
  }
`;
export const AddressFragmentDoc = gql`
  fragment address on DadataAddressOutput {
    address
    area
    areaTypeFull
    city
    cityTypeFull
    fiasActualityState
    fiasId
    fiasLevel
    house
    houseTypeFull
    settlement
    settlementTypeFull
    streetTypeFull
    street
    qcGeo
    lon
    lat
  }
`;
export const GetGeometriesDocument = gql`
  query getGeometries($dto: GeometryRequestByIdsDto!) {
    getGeometries(dto: $dto) {
      id
      geo {
        coordinates
      }
    }
  }
`;

/**
 * __useGetGeometriesQuery__
 *
 * To run a query within a React component, call `useGetGeometriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeometriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeometriesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetGeometriesQuery(
  baseOptions: Apollo.QueryHookOptions<GetGeometriesQuery, GetGeometriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGeometriesQuery, GetGeometriesQueryVariables>(
    GetGeometriesDocument,
    options
  );
}
export function useGetGeometriesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGeometriesQuery, GetGeometriesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGeometriesQuery, GetGeometriesQueryVariables>(
    GetGeometriesDocument,
    options
  );
}
export type GetGeometriesQueryHookResult = ReturnType<typeof useGetGeometriesQuery>;
export type GetGeometriesLazyQueryHookResult = ReturnType<typeof useGetGeometriesLazyQuery>;
export type GetGeometriesQueryResult = Apollo.QueryResult<
  GetGeometriesQuery,
  GetGeometriesQueryVariables
>;
export const CountInWindowDocument = gql`
  query countInWindow($dto: ObjectsAnalyticInput!) {
    countInWindow(dto: $dto) {
      labelColor
      label
      status
      count
    }
  }
`;

/**
 * __useCountInWindowQuery__
 *
 * To run a query within a React component, call `useCountInWindowQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountInWindowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountInWindowQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useCountInWindowQuery(
  baseOptions: Apollo.QueryHookOptions<CountInWindowQuery, CountInWindowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountInWindowQuery, CountInWindowQueryVariables>(
    CountInWindowDocument,
    options
  );
}
export function useCountInWindowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CountInWindowQuery, CountInWindowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountInWindowQuery, CountInWindowQueryVariables>(
    CountInWindowDocument,
    options
  );
}
export type CountInWindowQueryHookResult = ReturnType<typeof useCountInWindowQuery>;
export type CountInWindowLazyQueryHookResult = ReturnType<typeof useCountInWindowLazyQuery>;
export type CountInWindowQueryResult = Apollo.QueryResult<
  CountInWindowQuery,
  CountInWindowQueryVariables
>;
export const AntsCurrentSalariesDocument = gql`
  query antsCurrentSalaries {
    getCurrentAntSalary {
      userInfo {
        lastName
        firstName
      }
      details {
        total
        columnType
        contentType
      }
    }
  }
`;

/**
 * __useAntsCurrentSalariesQuery__
 *
 * To run a query within a React component, call `useAntsCurrentSalariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAntsCurrentSalariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAntsCurrentSalariesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAntsCurrentSalariesQuery(
  baseOptions?: Apollo.QueryHookOptions<AntsCurrentSalariesQuery, AntsCurrentSalariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AntsCurrentSalariesQuery, AntsCurrentSalariesQueryVariables>(
    AntsCurrentSalariesDocument,
    options
  );
}
export function useAntsCurrentSalariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AntsCurrentSalariesQuery,
    AntsCurrentSalariesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AntsCurrentSalariesQuery, AntsCurrentSalariesQueryVariables>(
    AntsCurrentSalariesDocument,
    options
  );
}
export type AntsCurrentSalariesQueryHookResult = ReturnType<typeof useAntsCurrentSalariesQuery>;
export type AntsCurrentSalariesLazyQueryHookResult = ReturnType<
  typeof useAntsCurrentSalariesLazyQuery
>;
export type AntsCurrentSalariesQueryResult = Apollo.QueryResult<
  AntsCurrentSalariesQuery,
  AntsCurrentSalariesQueryVariables
>;
export const GetAnalyticsHosDocument = gql`
  query getAnalyticsHos {
    getTasks {
      id
      name
      executor {
        firstName
        lastName
        phone
      }
      objects {
        id
        status
        updateDateTime
        ... on HomeOwnershipTaskObject {
          homeOwnership {
            id
            plotId
            plot {
              id
              phone
              owners
              usage
              addr {
                ...cardInfoAddrFragment
              }
              address
            }
            oks {
              id
            }
          }
        }
        ... on OksTaskObject {
          oks {
            id
            phone
            owners
            usage
            addr {
              ...cardInfoAddrFragment
            }
            address
          }
        }
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
`;

/**
 * __useGetAnalyticsHosQuery__
 *
 * To run a query within a React component, call `useGetAnalyticsHosQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticsHosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticsHosQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticsHosQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAnalyticsHosQuery, GetAnalyticsHosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAnalyticsHosQuery, GetAnalyticsHosQueryVariables>(
    GetAnalyticsHosDocument,
    options
  );
}
export function useGetAnalyticsHosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticsHosQuery, GetAnalyticsHosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAnalyticsHosQuery, GetAnalyticsHosQueryVariables>(
    GetAnalyticsHosDocument,
    options
  );
}
export type GetAnalyticsHosQueryHookResult = ReturnType<typeof useGetAnalyticsHosQuery>;
export type GetAnalyticsHosLazyQueryHookResult = ReturnType<typeof useGetAnalyticsHosLazyQuery>;
export type GetAnalyticsHosQueryResult = Apollo.QueryResult<
  GetAnalyticsHosQuery,
  GetAnalyticsHosQueryVariables
>;
export const AntsSalariesDocument = gql`
  query antsSalaries($dto: AntSalaryFilterInput!) {
    getAntSalary(input: $dto) {
      userInfo {
        lastName
        firstName
      }
      details {
        total
        columnType
        contentType
      }
    }
  }
`;

/**
 * __useAntsSalariesQuery__
 *
 * To run a query within a React component, call `useAntsSalariesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAntsSalariesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAntsSalariesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useAntsSalariesQuery(
  baseOptions: Apollo.QueryHookOptions<AntsSalariesQuery, AntsSalariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AntsSalariesQuery, AntsSalariesQueryVariables>(
    AntsSalariesDocument,
    options
  );
}
export function useAntsSalariesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AntsSalariesQuery, AntsSalariesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AntsSalariesQuery, AntsSalariesQueryVariables>(
    AntsSalariesDocument,
    options
  );
}
export type AntsSalariesQueryHookResult = ReturnType<typeof useAntsSalariesQuery>;
export type AntsSalariesLazyQueryHookResult = ReturnType<typeof useAntsSalariesLazyQuery>;
export type AntsSalariesQueryResult = Apollo.QueryResult<
  AntsSalariesQuery,
  AntsSalariesQueryVariables
>;
export const GetConfigurationDocument = gql`
  query getConfiguration {
    getAllTariffs {
      name
      tariffs {
        value
        type
      }
      date
    }
  }
`;

/**
 * __useGetConfigurationQuery__
 *
 * To run a query within a React component, call `useGetConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(
    GetConfigurationDocument,
    options
  );
}
export function useGetConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationQuery, GetConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetConfigurationQuery, GetConfigurationQueryVariables>(
    GetConfigurationDocument,
    options
  );
}
export type GetConfigurationQueryHookResult = ReturnType<typeof useGetConfigurationQuery>;
export type GetConfigurationLazyQueryHookResult = ReturnType<typeof useGetConfigurationLazyQuery>;
export type GetConfigurationQueryResult = Apollo.QueryResult<
  GetConfigurationQuery,
  GetConfigurationQueryVariables
>;
export const GetHoGeoDocument = gql`
  query getHoGeo($id: ID!) {
    getHomeOwnership(id: $id) {
      id
      plotId
      geom {
        coordinates
      }
    }
  }
`;

/**
 * __useGetHoGeoQuery__
 *
 * To run a query within a React component, call `useGetHoGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoGeoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHoGeoQuery(
  baseOptions: Apollo.QueryHookOptions<GetHoGeoQuery, GetHoGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHoGeoQuery, GetHoGeoQueryVariables>(GetHoGeoDocument, options);
}
export function useGetHoGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHoGeoQuery, GetHoGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHoGeoQuery, GetHoGeoQueryVariables>(GetHoGeoDocument, options);
}
export type GetHoGeoQueryHookResult = ReturnType<typeof useGetHoGeoQuery>;
export type GetHoGeoLazyQueryHookResult = ReturnType<typeof useGetHoGeoLazyQuery>;
export type GetHoGeoQueryResult = Apollo.QueryResult<GetHoGeoQuery, GetHoGeoQueryVariables>;
export const Table1Document = gql`
  query table1($dto: Table1Request!) {
    table1(dto: $dto) {
      details {
        objectStatus
        total
      }
      userInfo {
        lastName
        firstName
      }
    }
  }
`;

/**
 * __useTable1Query__
 *
 * To run a query within a React component, call `useTable1Query` and pass it any options that fit your needs.
 * When your component renders, `useTable1Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTable1Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useTable1Query(
  baseOptions: Apollo.QueryHookOptions<Table1Query, Table1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Table1Query, Table1QueryVariables>(Table1Document, options);
}
export function useTable1LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Table1Query, Table1QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Table1Query, Table1QueryVariables>(Table1Document, options);
}
export type Table1QueryHookResult = ReturnType<typeof useTable1Query>;
export type Table1LazyQueryHookResult = ReturnType<typeof useTable1LazyQuery>;
export type Table1QueryResult = Apollo.QueryResult<Table1Query, Table1QueryVariables>;
export const Table2Document = gql`
  query table2($dto: Table2Request!) {
    table2(dto: $dto) {
      userInfo {
        firstName
        lastName
        phone
      }
      details {
        total
        contentType
        columnType
        userId
      }
    }
  }
`;

/**
 * __useTable2Query__
 *
 * To run a query within a React component, call `useTable2Query` and pass it any options that fit your needs.
 * When your component renders, `useTable2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTable2Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useTable2Query(
  baseOptions: Apollo.QueryHookOptions<Table2Query, Table2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Table2Query, Table2QueryVariables>(Table2Document, options);
}
export function useTable2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Table2Query, Table2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Table2Query, Table2QueryVariables>(Table2Document, options);
}
export type Table2QueryHookResult = ReturnType<typeof useTable2Query>;
export type Table2LazyQueryHookResult = ReturnType<typeof useTable2LazyQuery>;
export type Table2QueryResult = Apollo.QueryResult<Table2Query, Table2QueryVariables>;
export const Table3Document = gql`
  query table3($dto: Table3Request!) {
    table3(dto: $dto) {
      userInfo {
        lastName
        firstName
      }
      details {
        breachReason
        contentType
        cost
      }
    }
  }
`;

/**
 * __useTable3Query__
 *
 * To run a query within a React component, call `useTable3Query` and pass it any options that fit your needs.
 * When your component renders, `useTable3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTable3Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useTable3Query(
  baseOptions: Apollo.QueryHookOptions<Table3Query, Table3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<Table3Query, Table3QueryVariables>(Table3Document, options);
}
export function useTable3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<Table3Query, Table3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<Table3Query, Table3QueryVariables>(Table3Document, options);
}
export type Table3QueryHookResult = ReturnType<typeof useTable3Query>;
export type Table3LazyQueryHookResult = ReturnType<typeof useTable3LazyQuery>;
export type Table3QueryResult = Apollo.QueryResult<Table3Query, Table3QueryVariables>;
export const TasksPieDocument = gql`
  query tasksPie($dto: PieRequest!) {
    pieChart(dto: $dto) {
      labelColor
      label
      status
      hexColor
      count
    }
  }
`;

/**
 * __useTasksPieQuery__
 *
 * To run a query within a React component, call `useTasksPieQuery` and pass it any options that fit your needs.
 * When your component renders, `useTasksPieQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTasksPieQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useTasksPieQuery(
  baseOptions: Apollo.QueryHookOptions<TasksPieQuery, TasksPieQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TasksPieQuery, TasksPieQueryVariables>(TasksPieDocument, options);
}
export function useTasksPieLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TasksPieQuery, TasksPieQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TasksPieQuery, TasksPieQueryVariables>(TasksPieDocument, options);
}
export type TasksPieQueryHookResult = ReturnType<typeof useTasksPieQuery>;
export type TasksPieLazyQueryHookResult = ReturnType<typeof useTasksPieLazyQuery>;
export type TasksPieQueryResult = Apollo.QueryResult<TasksPieQuery, TasksPieQueryVariables>;
export const GetBreachesCountDocument = gql`
  query getBreachesCount($dto: BoBreachesCountFilterInput!) {
    getBreachCount(input: $dto) {
      details {
        ... on BreachRecordDetail {
          contentType
          count
          breach
        }
      }
      userInfo {
        lastName
        firstName
      }
    }
  }
`;

/**
 * __useGetBreachesCountQuery__
 *
 * To run a query within a React component, call `useGetBreachesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachesCountQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetBreachesCountQuery(
  baseOptions: Apollo.QueryHookOptions<GetBreachesCountQuery, GetBreachesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBreachesCountQuery, GetBreachesCountQueryVariables>(
    GetBreachesCountDocument,
    options
  );
}
export function useGetBreachesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBreachesCountQuery, GetBreachesCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBreachesCountQuery, GetBreachesCountQueryVariables>(
    GetBreachesCountDocument,
    options
  );
}
export type GetBreachesCountQueryHookResult = ReturnType<typeof useGetBreachesCountQuery>;
export type GetBreachesCountLazyQueryHookResult = ReturnType<typeof useGetBreachesCountLazyQuery>;
export type GetBreachesCountQueryResult = Apollo.QueryResult<
  GetBreachesCountQuery,
  GetBreachesCountQueryVariables
>;
export const GetBreachesDynamicDocument = gql`
  query getBreachesDynamic($dto: BoBreachesDynamicFilterInput!) {
    getBreachDynamic(input: $dto) {
      userInfo {
        lastName
        firstName
      }
      details {
        breach
        endCount
        endDate
        startCount
        startDate
        contentType
      }
    }
  }
`;

/**
 * __useGetBreachesDynamicQuery__
 *
 * To run a query within a React component, call `useGetBreachesDynamicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachesDynamicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachesDynamicQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetBreachesDynamicQuery(
  baseOptions: Apollo.QueryHookOptions<GetBreachesDynamicQuery, GetBreachesDynamicQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBreachesDynamicQuery, GetBreachesDynamicQueryVariables>(
    GetBreachesDynamicDocument,
    options
  );
}
export function useGetBreachesDynamicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBreachesDynamicQuery,
    GetBreachesDynamicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBreachesDynamicQuery, GetBreachesDynamicQueryVariables>(
    GetBreachesDynamicDocument,
    options
  );
}
export type GetBreachesDynamicQueryHookResult = ReturnType<typeof useGetBreachesDynamicQuery>;
export type GetBreachesDynamicLazyQueryHookResult = ReturnType<
  typeof useGetBreachesDynamicLazyQuery
>;
export type GetBreachesDynamicQueryResult = Apollo.QueryResult<
  GetBreachesDynamicQuery,
  GetBreachesDynamicQueryVariables
>;
export const GetProcessingDynamicDocument = gql`
  query getProcessingDynamic($dto: BoProcessingDynamicFilterInput!) {
    getProcessingDynamic(input: $dto) {
      userInfo {
        lastName
        firstName
      }
      details {
        status
        startCount
        startDate
        endCount
        startDate
        contentType
      }
    }
  }
`;

/**
 * __useGetProcessingDynamicQuery__
 *
 * To run a query within a React component, call `useGetProcessingDynamicQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessingDynamicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessingDynamicQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetProcessingDynamicQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProcessingDynamicQuery,
    GetProcessingDynamicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProcessingDynamicQuery, GetProcessingDynamicQueryVariables>(
    GetProcessingDynamicDocument,
    options
  );
}
export function useGetProcessingDynamicLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcessingDynamicQuery,
    GetProcessingDynamicQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProcessingDynamicQuery, GetProcessingDynamicQueryVariables>(
    GetProcessingDynamicDocument,
    options
  );
}
export type GetProcessingDynamicQueryHookResult = ReturnType<typeof useGetProcessingDynamicQuery>;
export type GetProcessingDynamicLazyQueryHookResult = ReturnType<
  typeof useGetProcessingDynamicLazyQuery
>;
export type GetProcessingDynamicQueryResult = Apollo.QueryResult<
  GetProcessingDynamicQuery,
  GetProcessingDynamicQueryVariables
>;
export const GetProcessingStatusesDocument = gql`
  query getProcessingStatuses($dto: BoProcessingCountFilterInput!) {
    getProcessingCount(input: $dto) {
      details {
        ... on ProcessingRecordDetail {
          contentType
          count
          status
        }
      }
      userInfo {
        lastName
        firstName
      }
    }
  }
`;

/**
 * __useGetProcessingStatusesQuery__
 *
 * To run a query within a React component, call `useGetProcessingStatusesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProcessingStatusesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProcessingStatusesQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetProcessingStatusesQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProcessingStatusesQuery,
    GetProcessingStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProcessingStatusesQuery, GetProcessingStatusesQueryVariables>(
    GetProcessingStatusesDocument,
    options
  );
}
export function useGetProcessingStatusesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProcessingStatusesQuery,
    GetProcessingStatusesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProcessingStatusesQuery, GetProcessingStatusesQueryVariables>(
    GetProcessingStatusesDocument,
    options
  );
}
export type GetProcessingStatusesQueryHookResult = ReturnType<typeof useGetProcessingStatusesQuery>;
export type GetProcessingStatusesLazyQueryHookResult = ReturnType<
  typeof useGetProcessingStatusesLazyQuery
>;
export type GetProcessingStatusesQueryResult = Apollo.QueryResult<
  GetProcessingStatusesQuery,
  GetProcessingStatusesQueryVariables
>;
export const RegionsAnalyticsDocument = gql`
  query regionsAnalytics($dto: MetricAnalyticsInput!) {
    regionAnalytics(dto: $dto) {
      kName
      kMetric
      vMetric
      unit
      id
      rId
    }
  }
`;

/**
 * __useRegionsAnalyticsQuery__
 *
 * To run a query within a React component, call `useRegionsAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegionsAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegionsAnalyticsQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useRegionsAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<RegionsAnalyticsQuery, RegionsAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RegionsAnalyticsQuery, RegionsAnalyticsQueryVariables>(
    RegionsAnalyticsDocument,
    options
  );
}
export function useRegionsAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RegionsAnalyticsQuery, RegionsAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RegionsAnalyticsQuery, RegionsAnalyticsQueryVariables>(
    RegionsAnalyticsDocument,
    options
  );
}
export type RegionsAnalyticsQueryHookResult = ReturnType<typeof useRegionsAnalyticsQuery>;
export type RegionsAnalyticsLazyQueryHookResult = ReturnType<typeof useRegionsAnalyticsLazyQuery>;
export type RegionsAnalyticsQueryResult = Apollo.QueryResult<
  RegionsAnalyticsQuery,
  RegionsAnalyticsQueryVariables
>;
export const WbAnalyticsDocument = gql`
  query wbAnalytics($dto: MetricAnalyticsInput!) {
    wbAnalytics(dto: $dto) {
      kName
      kMetric
      vMetric
      unit
      id
      wbId
      reasons {
        total
        reason
        id
        color
      }
    }
  }
`;

/**
 * __useWbAnalyticsQuery__
 *
 * To run a query within a React component, call `useWbAnalyticsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAnalyticsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAnalyticsQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useWbAnalyticsQuery(
  baseOptions: Apollo.QueryHookOptions<WbAnalyticsQuery, WbAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WbAnalyticsQuery, WbAnalyticsQueryVariables>(WbAnalyticsDocument, options);
}
export function useWbAnalyticsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WbAnalyticsQuery, WbAnalyticsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WbAnalyticsQuery, WbAnalyticsQueryVariables>(
    WbAnalyticsDocument,
    options
  );
}
export type WbAnalyticsQueryHookResult = ReturnType<typeof useWbAnalyticsQuery>;
export type WbAnalyticsLazyQueryHookResult = ReturnType<typeof useWbAnalyticsLazyQuery>;
export type WbAnalyticsQueryResult = Apollo.QueryResult<
  WbAnalyticsQuery,
  WbAnalyticsQueryVariables
>;
export const WbAnalyticsGeoDocument = gql`
  query wbAnalyticsGeo($dto: MetricAnalyticsInput!) {
    wbAnalytics(dto: $dto) {
      reasons {
        id
        objects {
          objType
          objId
          geo {
            coordinates
          }
        }
      }
    }
  }
`;

/**
 * __useWbAnalyticsGeoQuery__
 *
 * To run a query within a React component, call `useWbAnalyticsGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWbAnalyticsGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWbAnalyticsGeoQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useWbAnalyticsGeoQuery(
  baseOptions: Apollo.QueryHookOptions<WbAnalyticsGeoQuery, WbAnalyticsGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WbAnalyticsGeoQuery, WbAnalyticsGeoQueryVariables>(
    WbAnalyticsGeoDocument,
    options
  );
}
export function useWbAnalyticsGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WbAnalyticsGeoQuery, WbAnalyticsGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WbAnalyticsGeoQuery, WbAnalyticsGeoQueryVariables>(
    WbAnalyticsGeoDocument,
    options
  );
}
export type WbAnalyticsGeoQueryHookResult = ReturnType<typeof useWbAnalyticsGeoQuery>;
export type WbAnalyticsGeoLazyQueryHookResult = ReturnType<typeof useWbAnalyticsGeoLazyQuery>;
export type WbAnalyticsGeoQueryResult = Apollo.QueryResult<
  WbAnalyticsGeoQuery,
  WbAnalyticsGeoQueryVariables
>;
export const AddObjectBreachDocument = gql`
  mutation addObjectBreach($reason: AddBreachInput!) {
    addObjectBreach(dto: $reason) {
      data {
        id
        authorId
      }
    }
  }
`;
export type AddObjectBreachMutationFn = Apollo.MutationFunction<
  AddObjectBreachMutation,
  AddObjectBreachMutationVariables
>;

/**
 * __useAddObjectBreachMutation__
 *
 * To run a mutation, you first call `useAddObjectBreachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddObjectBreachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addObjectBreachMutation, { data, loading, error }] = useAddObjectBreachMutation({
 *   variables: {
 *      reason: // value for 'reason'
 *   },
 * });
 */
export function useAddObjectBreachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddObjectBreachMutation,
    AddObjectBreachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddObjectBreachMutation, AddObjectBreachMutationVariables>(
    AddObjectBreachDocument,
    options
  );
}
export type AddObjectBreachMutationHookResult = ReturnType<typeof useAddObjectBreachMutation>;
export type AddObjectBreachMutationResult = Apollo.MutationResult<AddObjectBreachMutation>;
export type AddObjectBreachMutationOptions = Apollo.BaseMutationOptions<
  AddObjectBreachMutation,
  AddObjectBreachMutationVariables
>;
export const DeleteObjectBreachDocument = gql`
  mutation deleteObjectBreach($dto: DeleteBreachesInput!) {
    deleteObjectBreach(dto: $dto)
  }
`;
export type DeleteObjectBreachMutationFn = Apollo.MutationFunction<
  DeleteObjectBreachMutation,
  DeleteObjectBreachMutationVariables
>;

/**
 * __useDeleteObjectBreachMutation__
 *
 * To run a mutation, you first call `useDeleteObjectBreachMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteObjectBreachMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteObjectBreachMutation, { data, loading, error }] = useDeleteObjectBreachMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useDeleteObjectBreachMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteObjectBreachMutation,
    DeleteObjectBreachMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteObjectBreachMutation, DeleteObjectBreachMutationVariables>(
    DeleteObjectBreachDocument,
    options
  );
}
export type DeleteObjectBreachMutationHookResult = ReturnType<typeof useDeleteObjectBreachMutation>;
export type DeleteObjectBreachMutationResult = Apollo.MutationResult<DeleteObjectBreachMutation>;
export type DeleteObjectBreachMutationOptions = Apollo.BaseMutationOptions<
  DeleteObjectBreachMutation,
  DeleteObjectBreachMutationVariables
>;
export const GetEgrnDataDocument = gql`
  query getEgrnData(
    $dto: DataSourceSearchInput!
    $isPlot: Boolean = false
    $isOks: Boolean = false
  ) {
    getEgrnPlotData(dto: $dto) @include(if: $isPlot) {
      data {
        id
        addressEgrn
        addressFias
        cad_number
        grpDate
        grpNum
        grpType
        opoDate
        opoNum
        opoType
      }
    }
    getEgrnOksData(dto: $dto) @include(if: $isOks) {
      data {
        id
        cad_number
        grpDate
        grpNum
        grpType
        opoDate
        opoNum
        opoType
      }
    }
  }
`;

/**
 * __useGetEgrnDataQuery__
 *
 * To run a query within a React component, call `useGetEgrnDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEgrnDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEgrnDataQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      isPlot: // value for 'isPlot'
 *      isOks: // value for 'isOks'
 *   },
 * });
 */
export function useGetEgrnDataQuery(
  baseOptions: Apollo.QueryHookOptions<GetEgrnDataQuery, GetEgrnDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetEgrnDataQuery, GetEgrnDataQueryVariables>(GetEgrnDataDocument, options);
}
export function useGetEgrnDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetEgrnDataQuery, GetEgrnDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetEgrnDataQuery, GetEgrnDataQueryVariables>(
    GetEgrnDataDocument,
    options
  );
}
export type GetEgrnDataQueryHookResult = ReturnType<typeof useGetEgrnDataQuery>;
export type GetEgrnDataLazyQueryHookResult = ReturnType<typeof useGetEgrnDataLazyQuery>;
export type GetEgrnDataQueryResult = Apollo.QueryResult<
  GetEgrnDataQuery,
  GetEgrnDataQueryVariables
>;
export const FilterBoDocument = gql`
  query filterBo($dto: FilterInput!) {
    filterBoV2(dto: $dto) {
      countData {
        hoCount
        plotCount
        oksCount
      }
      list {
        ho {
          id
          plot {
            id
            addr {
              ...cardInfoAddrFragment
            }
          }
          oks {
            id
          }
        }
        taskId
        taskObjectId
        executor {
          firstName
          lastName
          phone
        }
      }
      pagination {
        ...pagination
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useFilterBoQuery__
 *
 * To run a query within a React component, call `useFilterBoQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterBoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterBoQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useFilterBoQuery(
  baseOptions: Apollo.QueryHookOptions<FilterBoQuery, FilterBoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterBoQuery, FilterBoQueryVariables>(FilterBoDocument, options);
}
export function useFilterBoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterBoQuery, FilterBoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterBoQuery, FilterBoQueryVariables>(FilterBoDocument, options);
}
export type FilterBoQueryHookResult = ReturnType<typeof useFilterBoQuery>;
export type FilterBoLazyQueryHookResult = ReturnType<typeof useFilterBoLazyQuery>;
export type FilterBoQueryResult = Apollo.QueryResult<FilterBoQuery, FilterBoQueryVariables>;
export const FilterTaskOksDocument = gql`
  query filterTaskOks($dto: FilterInput!, $needOks: Boolean = true) {
    filterTask(dto: $dto) {
      ...oksFilterFragment @include(if: $needOks)
    }
  }
  ${OksFilterFragmentFragmentDoc}
`;

/**
 * __useFilterTaskOksQuery__
 *
 * To run a query within a React component, call `useFilterTaskOksQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterTaskOksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterTaskOksQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      needOks: // value for 'needOks'
 *   },
 * });
 */
export function useFilterTaskOksQuery(
  baseOptions: Apollo.QueryHookOptions<FilterTaskOksQuery, FilterTaskOksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterTaskOksQuery, FilterTaskOksQueryVariables>(
    FilterTaskOksDocument,
    options
  );
}
export function useFilterTaskOksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterTaskOksQuery, FilterTaskOksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterTaskOksQuery, FilterTaskOksQueryVariables>(
    FilterTaskOksDocument,
    options
  );
}
export type FilterTaskOksQueryHookResult = ReturnType<typeof useFilterTaskOksQuery>;
export type FilterTaskOksLazyQueryHookResult = ReturnType<typeof useFilterTaskOksLazyQuery>;
export type FilterTaskOksQueryResult = Apollo.QueryResult<
  FilterTaskOksQuery,
  FilterTaskOksQueryVariables
>;
export const FilterRoomDocument = gql`
  query filterRoom($dto: FilterInput!) {
    filterRoom(dto: $dto) {
      result {
        room {
          ...roomFragment
        }
        visits {
          id
          taskObjectId
          executor {
            id
            username
            firstName
            lastName
            phone
          }
        }
      }
      count
      pagination {
        ...pagination
      }
    }
  }
  ${RoomFragmentFragmentDoc}
  ${PaginationFragmentDoc}
`;

/**
 * __useFilterRoomQuery__
 *
 * To run a query within a React component, call `useFilterRoomQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilterRoomQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilterRoomQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useFilterRoomQuery(
  baseOptions: Apollo.QueryHookOptions<FilterRoomQuery, FilterRoomQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FilterRoomQuery, FilterRoomQueryVariables>(FilterRoomDocument, options);
}
export function useFilterRoomLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FilterRoomQuery, FilterRoomQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FilterRoomQuery, FilterRoomQueryVariables>(
    FilterRoomDocument,
    options
  );
}
export type FilterRoomQueryHookResult = ReturnType<typeof useFilterRoomQuery>;
export type FilterRoomLazyQueryHookResult = ReturnType<typeof useFilterRoomLazyQuery>;
export type FilterRoomQueryResult = Apollo.QueryResult<FilterRoomQuery, FilterRoomQueryVariables>;
export const GetBreachesAndStatusAuditDocument = gql`
  query getBreachesAndStatusAudit(
    $dto: MapObjectIdentityInput!
    $needBreach: Boolean = false
    $needProcessing: Boolean = false
  ) {
    getBreachesAudit(dto: $dto) @include(if: $needBreach) {
      data {
        objectId
        contentType
        timestamp
        value
        status
        fio
      }
    }
    getProcessingStatusAudit(dto: $dto) @include(if: $needProcessing) {
      data {
        objectId
        contentType
        timestamp
        value
        status
        fio
      }
    }
  }
`;

/**
 * __useGetBreachesAndStatusAuditQuery__
 *
 * To run a query within a React component, call `useGetBreachesAndStatusAuditQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachesAndStatusAuditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachesAndStatusAuditQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      needBreach: // value for 'needBreach'
 *      needProcessing: // value for 'needProcessing'
 *   },
 * });
 */
export function useGetBreachesAndStatusAuditQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBreachesAndStatusAuditQuery,
    GetBreachesAndStatusAuditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBreachesAndStatusAuditQuery, GetBreachesAndStatusAuditQueryVariables>(
    GetBreachesAndStatusAuditDocument,
    options
  );
}
export function useGetBreachesAndStatusAuditLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBreachesAndStatusAuditQuery,
    GetBreachesAndStatusAuditQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBreachesAndStatusAuditQuery,
    GetBreachesAndStatusAuditQueryVariables
  >(GetBreachesAndStatusAuditDocument, options);
}
export type GetBreachesAndStatusAuditQueryHookResult = ReturnType<
  typeof useGetBreachesAndStatusAuditQuery
>;
export type GetBreachesAndStatusAuditLazyQueryHookResult = ReturnType<
  typeof useGetBreachesAndStatusAuditLazyQuery
>;
export type GetBreachesAndStatusAuditQueryResult = Apollo.QueryResult<
  GetBreachesAndStatusAuditQuery,
  GetBreachesAndStatusAuditQueryVariables
>;
export const DeleteNotificationDocument = gql`
  mutation deleteNotification($id: ID!) {
    deleteNotification(id: $id)
  }
`;
export type DeleteNotificationMutationFn = Apollo.MutationFunction<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;

/**
 * __useDeleteNotificationMutation__
 *
 * To run a mutation, you first call `useDeleteNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteNotificationMutation, { data, loading, error }] = useDeleteNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteNotificationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteNotificationMutation,
    DeleteNotificationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteNotificationMutation, DeleteNotificationMutationVariables>(
    DeleteNotificationDocument,
    options
  );
}
export type DeleteNotificationMutationHookResult = ReturnType<typeof useDeleteNotificationMutation>;
export type DeleteNotificationMutationResult = Apollo.MutationResult<DeleteNotificationMutation>;
export type DeleteNotificationMutationOptions = Apollo.BaseMutationOptions<
  DeleteNotificationMutation,
  DeleteNotificationMutationVariables
>;
export const GetBreachNotificationsDocument = gql`
  query getBreachNotifications($dto: NotificationInputV2!) {
    getBreachNotificationsV3(dto: $dto) {
      data {
        link
        fileId
      }
      errors
    }
  }
`;

/**
 * __useGetBreachNotificationsQuery__
 *
 * To run a query within a React component, call `useGetBreachNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachNotificationsQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetBreachNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBreachNotificationsQuery,
    GetBreachNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBreachNotificationsQuery, GetBreachNotificationsQueryVariables>(
    GetBreachNotificationsDocument,
    options
  );
}
export function useGetBreachNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBreachNotificationsQuery,
    GetBreachNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBreachNotificationsQuery, GetBreachNotificationsQueryVariables>(
    GetBreachNotificationsDocument,
    options
  );
}
export type GetBreachNotificationsQueryHookResult = ReturnType<
  typeof useGetBreachNotificationsQuery
>;
export type GetBreachNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetBreachNotificationsLazyQuery
>;
export type GetBreachNotificationsQueryResult = Apollo.QueryResult<
  GetBreachNotificationsQuery,
  GetBreachNotificationsQueryVariables
>;
export const GetBreachNotificationsV3Document = gql`
  query getBreachNotificationsV3($dto: NotificationInputV2!) {
    getBreachNotificationsV3(dto: $dto) {
      data {
        fileId
        link
      }
      errors
    }
  }
`;

/**
 * __useGetBreachNotificationsV3Query__
 *
 * To run a query within a React component, call `useGetBreachNotificationsV3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetBreachNotificationsV3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBreachNotificationsV3Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetBreachNotificationsV3Query(
  baseOptions: Apollo.QueryHookOptions<
    GetBreachNotificationsV3Query,
    GetBreachNotificationsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBreachNotificationsV3Query, GetBreachNotificationsV3QueryVariables>(
    GetBreachNotificationsV3Document,
    options
  );
}
export function useGetBreachNotificationsV3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBreachNotificationsV3Query,
    GetBreachNotificationsV3QueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBreachNotificationsV3Query, GetBreachNotificationsV3QueryVariables>(
    GetBreachNotificationsV3Document,
    options
  );
}
export type GetBreachNotificationsV3QueryHookResult = ReturnType<
  typeof useGetBreachNotificationsV3Query
>;
export type GetBreachNotificationsV3LazyQueryHookResult = ReturnType<
  typeof useGetBreachNotificationsV3LazyQuery
>;
export type GetBreachNotificationsV3QueryResult = Apollo.QueryResult<
  GetBreachNotificationsV3Query,
  GetBreachNotificationsV3QueryVariables
>;
export const GetHoNotificationsDocument = gql`
  query getHONotifications($id: ID!) {
    getHomeOwnership(id: $id) {
      notifications {
        ...notification
      }
    }
  }
  ${NotificationFragmentDoc}
`;

/**
 * __useGetHoNotificationsQuery__
 *
 * To run a query within a React component, call `useGetHoNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoNotificationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetHoNotificationsQuery(
  baseOptions: Apollo.QueryHookOptions<GetHoNotificationsQuery, GetHoNotificationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHoNotificationsQuery, GetHoNotificationsQueryVariables>(
    GetHoNotificationsDocument,
    options
  );
}
export function useGetHoNotificationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHoNotificationsQuery,
    GetHoNotificationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHoNotificationsQuery, GetHoNotificationsQueryVariables>(
    GetHoNotificationsDocument,
    options
  );
}
export type GetHoNotificationsQueryHookResult = ReturnType<typeof useGetHoNotificationsQuery>;
export type GetHoNotificationsLazyQueryHookResult = ReturnType<
  typeof useGetHoNotificationsLazyQuery
>;
export type GetHoNotificationsQueryResult = Apollo.QueryResult<
  GetHoNotificationsQuery,
  GetHoNotificationsQueryVariables
>;
export const SendNotificationDocument = gql`
  query sendNotification($dto: SendBreachNotificationInput!) {
    sendBreachNotification(dto: $dto) {
      status
    }
  }
`;

/**
 * __useSendNotificationQuery__
 *
 * To run a query within a React component, call `useSendNotificationQuery` and pass it any options that fit your needs.
 * When your component renders, `useSendNotificationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSendNotificationQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSendNotificationQuery(
  baseOptions: Apollo.QueryHookOptions<SendNotificationQuery, SendNotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SendNotificationQuery, SendNotificationQueryVariables>(
    SendNotificationDocument,
    options
  );
}
export function useSendNotificationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SendNotificationQuery, SendNotificationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SendNotificationQuery, SendNotificationQueryVariables>(
    SendNotificationDocument,
    options
  );
}
export type SendNotificationQueryHookResult = ReturnType<typeof useSendNotificationQuery>;
export type SendNotificationLazyQueryHookResult = ReturnType<typeof useSendNotificationLazyQuery>;
export type SendNotificationQueryResult = Apollo.QueryResult<
  SendNotificationQuery,
  SendNotificationQueryVariables
>;
export const GetTasksPaginationDocument = gql`
  query getTasksPagination($pas: PaginationAndSortingInput) {
    getTasksV2(pas: $pas) {
      tasks {
        id
        name
        executor {
          firstName
          lastName
          phone
        }
        objects {
          id
          status
          updateDateTime
          ... on HomeOwnershipTaskObject {
            homeOwnership {
              id
              status
              plot {
                id
                phone
                owners
                usage
                addr {
                  ...cardInfoAddrFragment
                }
                address
              }
              oks {
                id
              }
            }
          }
          ... on OksTaskObject {
            oks {
              id
              phone
              owners
              usage
              addr {
                ...cardInfoAddrFragment
              }
            }
          }
        }
      }
      pagination {
        hasContent
        hasNext
        hasPrevious
        totalElements
        totalPages
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
`;

/**
 * __useGetTasksPaginationQuery__
 *
 * To run a query within a React component, call `useGetTasksPaginationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksPaginationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksPaginationQuery({
 *   variables: {
 *      pas: // value for 'pas'
 *   },
 * });
 */
export function useGetTasksPaginationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksPaginationQuery, GetTasksPaginationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksPaginationQuery, GetTasksPaginationQueryVariables>(
    GetTasksPaginationDocument,
    options
  );
}
export function useGetTasksPaginationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksPaginationQuery,
    GetTasksPaginationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksPaginationQuery, GetTasksPaginationQueryVariables>(
    GetTasksPaginationDocument,
    options
  );
}
export type GetTasksPaginationQueryHookResult = ReturnType<typeof useGetTasksPaginationQuery>;
export type GetTasksPaginationLazyQueryHookResult = ReturnType<
  typeof useGetTasksPaginationLazyQuery
>;
export type GetTasksPaginationQueryResult = Apollo.QueryResult<
  GetTasksPaginationQuery,
  GetTasksPaginationQueryVariables
>;
export const UpdateProcessStatusDocument = gql`
  mutation updateProcessStatus($dto: ProcessingStatusRequest!) {
    updateProcessingStatus(dto: $dto) {
      id
    }
  }
`;
export type UpdateProcessStatusMutationFn = Apollo.MutationFunction<
  UpdateProcessStatusMutation,
  UpdateProcessStatusMutationVariables
>;

/**
 * __useUpdateProcessStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProcessStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcessStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcessStatusMutation, { data, loading, error }] = useUpdateProcessStatusMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateProcessStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateProcessStatusMutation,
    UpdateProcessStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProcessStatusMutation, UpdateProcessStatusMutationVariables>(
    UpdateProcessStatusDocument,
    options
  );
}
export type UpdateProcessStatusMutationHookResult = ReturnType<
  typeof useUpdateProcessStatusMutation
>;
export type UpdateProcessStatusMutationResult = Apollo.MutationResult<UpdateProcessStatusMutation>;
export type UpdateProcessStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateProcessStatusMutation,
  UpdateProcessStatusMutationVariables
>;
export const AbleToMergeDocument = gql`
  query ableToMerge($dto: AbleToMergeInput!) {
    ableToMerge(dto: $dto) {
      sourceId
      res {
        geo {
          coordinates
          type
        }
        isCrosses
      }
    }
  }
`;

/**
 * __useAbleToMergeQuery__
 *
 * To run a query within a React component, call `useAbleToMergeQuery` and pass it any options that fit your needs.
 * When your component renders, `useAbleToMergeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAbleToMergeQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useAbleToMergeQuery(
  baseOptions: Apollo.QueryHookOptions<AbleToMergeQuery, AbleToMergeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AbleToMergeQuery, AbleToMergeQueryVariables>(AbleToMergeDocument, options);
}
export function useAbleToMergeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AbleToMergeQuery, AbleToMergeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AbleToMergeQuery, AbleToMergeQueryVariables>(
    AbleToMergeDocument,
    options
  );
}
export type AbleToMergeQueryHookResult = ReturnType<typeof useAbleToMergeQuery>;
export type AbleToMergeLazyQueryHookResult = ReturnType<typeof useAbleToMergeLazyQuery>;
export type AbleToMergeQueryResult = Apollo.QueryResult<
  AbleToMergeQuery,
  AbleToMergeQueryVariables
>;
export const MergeObjectsDocument = gql`
  mutation mergeObjects($dto: MergeInput!) {
    mergeHomeOwnershipV2(dto: $dto) {
      id
      ... on HomeOwnershipTaskObject {
        homeOwnership {
          id
          plotId
          geom {
            coordinates
          }
        }
      }
    }
  }
`;
export type MergeObjectsMutationFn = Apollo.MutationFunction<
  MergeObjectsMutation,
  MergeObjectsMutationVariables
>;

/**
 * __useMergeObjectsMutation__
 *
 * To run a mutation, you first call `useMergeObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMergeObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [mergeObjectsMutation, { data, loading, error }] = useMergeObjectsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useMergeObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<MergeObjectsMutation, MergeObjectsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MergeObjectsMutation, MergeObjectsMutationVariables>(
    MergeObjectsDocument,
    options
  );
}
export type MergeObjectsMutationHookResult = ReturnType<typeof useMergeObjectsMutation>;
export type MergeObjectsMutationResult = Apollo.MutationResult<MergeObjectsMutation>;
export type MergeObjectsMutationOptions = Apollo.BaseMutationOptions<
  MergeObjectsMutation,
  MergeObjectsMutationVariables
>;
export const SplitObjectsDocument = gql`
  mutation splitObjects($dto: SplitHomeOwnershipInput!) {
    splitHomeOwnershipV2(dto: $dto) {
      id
      ... on HomeOwnershipTaskObject {
        homeOwnership {
          id
          status
          plotId
          geom {
            coordinates
          }
        }
      }
    }
  }
`;
export type SplitObjectsMutationFn = Apollo.MutationFunction<
  SplitObjectsMutation,
  SplitObjectsMutationVariables
>;

/**
 * __useSplitObjectsMutation__
 *
 * To run a mutation, you first call `useSplitObjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSplitObjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [splitObjectsMutation, { data, loading, error }] = useSplitObjectsMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useSplitObjectsMutation(
  baseOptions?: Apollo.MutationHookOptions<SplitObjectsMutation, SplitObjectsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SplitObjectsMutation, SplitObjectsMutationVariables>(
    SplitObjectsDocument,
    options
  );
}
export type SplitObjectsMutationHookResult = ReturnType<typeof useSplitObjectsMutation>;
export type SplitObjectsMutationResult = Apollo.MutationResult<SplitObjectsMutation>;
export type SplitObjectsMutationOptions = Apollo.BaseMutationOptions<
  SplitObjectsMutation,
  SplitObjectsMutationVariables
>;
export const GetAllInGeoDocument = gql`
  query getAllInGeo($dto: AllInWindowInput!) {
    findAllInWindowV2(dto: $dto) {
      id
      layerId
      geo {
        coordinates
      }
      contentType
    }
  }
`;

/**
 * __useGetAllInGeoQuery__
 *
 * To run a query within a React component, call `useGetAllInGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInGeoQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetAllInGeoQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllInGeoQuery, GetAllInGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllInGeoQuery, GetAllInGeoQueryVariables>(GetAllInGeoDocument, options);
}
export function useGetAllInGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllInGeoQuery, GetAllInGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllInGeoQuery, GetAllInGeoQueryVariables>(
    GetAllInGeoDocument,
    options
  );
}
export type GetAllInGeoQueryHookResult = ReturnType<typeof useGetAllInGeoQuery>;
export type GetAllInGeoLazyQueryHookResult = ReturnType<typeof useGetAllInGeoLazyQuery>;
export type GetAllInGeoQueryResult = Apollo.QueryResult<
  GetAllInGeoQuery,
  GetAllInGeoQueryVariables
>;
export const GetAllInGeo2Document = gql`
  query getAllInGeo2($dto: AllInWindowInput!) {
    findAllInWindowV2(dto: $dto) {
      id
      layerId
      geo {
        coordinates
      }
      contentType
    }
  }
`;

/**
 * __useGetAllInGeo2Query__
 *
 * To run a query within a React component, call `useGetAllInGeo2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInGeo2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInGeo2Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetAllInGeo2Query(
  baseOptions: Apollo.QueryHookOptions<GetAllInGeo2Query, GetAllInGeo2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllInGeo2Query, GetAllInGeo2QueryVariables>(
    GetAllInGeo2Document,
    options
  );
}
export function useGetAllInGeo2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllInGeo2Query, GetAllInGeo2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllInGeo2Query, GetAllInGeo2QueryVariables>(
    GetAllInGeo2Document,
    options
  );
}
export type GetAllInGeo2QueryHookResult = ReturnType<typeof useGetAllInGeo2Query>;
export type GetAllInGeo2LazyQueryHookResult = ReturnType<typeof useGetAllInGeo2LazyQuery>;
export type GetAllInGeo2QueryResult = Apollo.QueryResult<
  GetAllInGeo2Query,
  GetAllInGeo2QueryVariables
>;
export const GetAllInGeo3Document = gql`
  query getAllInGeo3($dto: AllInWindowInput!) {
    findAllInWindowV2(dto: $dto) {
      id
      layerId
      geomId
      contentType
    }
  }
`;

/**
 * __useGetAllInGeo3Query__
 *
 * To run a query within a React component, call `useGetAllInGeo3Query` and pass it any options that fit your needs.
 * When your component renders, `useGetAllInGeo3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllInGeo3Query({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetAllInGeo3Query(
  baseOptions: Apollo.QueryHookOptions<GetAllInGeo3Query, GetAllInGeo3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllInGeo3Query, GetAllInGeo3QueryVariables>(
    GetAllInGeo3Document,
    options
  );
}
export function useGetAllInGeo3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllInGeo3Query, GetAllInGeo3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllInGeo3Query, GetAllInGeo3QueryVariables>(
    GetAllInGeo3Document,
    options
  );
}
export type GetAllInGeo3QueryHookResult = ReturnType<typeof useGetAllInGeo3Query>;
export type GetAllInGeo3LazyQueryHookResult = ReturnType<typeof useGetAllInGeo3LazyQuery>;
export type GetAllInGeo3QueryResult = Apollo.QueryResult<
  GetAllInGeo3Query,
  GetAllInGeo3QueryVariables
>;
export const GetCurrentVisitStatusDocument = gql`
  query getCurrentVisitStatus($id: ID!) {
    getCurrentVisitStatus(taskObjectId: $id) {
      currentVisitStatus
    }
  }
`;

/**
 * __useGetCurrentVisitStatusQuery__
 *
 * To run a query within a React component, call `useGetCurrentVisitStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentVisitStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentVisitStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCurrentVisitStatusQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCurrentVisitStatusQuery,
    GetCurrentVisitStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCurrentVisitStatusQuery, GetCurrentVisitStatusQueryVariables>(
    GetCurrentVisitStatusDocument,
    options
  );
}
export function useGetCurrentVisitStatusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCurrentVisitStatusQuery,
    GetCurrentVisitStatusQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCurrentVisitStatusQuery, GetCurrentVisitStatusQueryVariables>(
    GetCurrentVisitStatusDocument,
    options
  );
}
export type GetCurrentVisitStatusQueryHookResult = ReturnType<typeof useGetCurrentVisitStatusQuery>;
export type GetCurrentVisitStatusLazyQueryHookResult = ReturnType<
  typeof useGetCurrentVisitStatusLazyQuery
>;
export type GetCurrentVisitStatusQueryResult = Apollo.QueryResult<
  GetCurrentVisitStatusQuery,
  GetCurrentVisitStatusQueryVariables
>;
export const AddHomeownershipsDocument = gql`
  mutation addHomeownerships($ids: [ID!]!, $wbId: ID!) {
    homeOwnershipFromPlots(ids: $ids, wbId: $wbId) {
      id
      plotId
      plot {
        id
      }
      oks {
        id
      }
    }
  }
`;
export type AddHomeownershipsMutationFn = Apollo.MutationFunction<
  AddHomeownershipsMutation,
  AddHomeownershipsMutationVariables
>;

/**
 * __useAddHomeownershipsMutation__
 *
 * To run a mutation, you first call `useAddHomeownershipsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddHomeownershipsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addHomeownershipsMutation, { data, loading, error }] = useAddHomeownershipsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      wbId: // value for 'wbId'
 *   },
 * });
 */
export function useAddHomeownershipsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddHomeownershipsMutation,
    AddHomeownershipsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddHomeownershipsMutation, AddHomeownershipsMutationVariables>(
    AddHomeownershipsDocument,
    options
  );
}
export type AddHomeownershipsMutationHookResult = ReturnType<typeof useAddHomeownershipsMutation>;
export type AddHomeownershipsMutationResult = Apollo.MutationResult<AddHomeownershipsMutation>;
export type AddHomeownershipsMutationOptions = Apollo.BaseMutationOptions<
  AddHomeownershipsMutation,
  AddHomeownershipsMutationVariables
>;
export const GetHomeownershipsDocument = gql`
  query getHomeownerships($ids: [ID!]!) {
    getHomeOwnerships(ids: $ids) {
      id
      plotId
      plot {
        id
      }
      oks {
        id
      }
    }
  }
`;

/**
 * __useGetHomeownershipsQuery__
 *
 * To run a query within a React component, call `useGetHomeownershipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHomeownershipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHomeownershipsQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetHomeownershipsQuery(
  baseOptions: Apollo.QueryHookOptions<GetHomeownershipsQuery, GetHomeownershipsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHomeownershipsQuery, GetHomeownershipsQueryVariables>(
    GetHomeownershipsDocument,
    options
  );
}
export function useGetHomeownershipsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHomeownershipsQuery, GetHomeownershipsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHomeownershipsQuery, GetHomeownershipsQueryVariables>(
    GetHomeownershipsDocument,
    options
  );
}
export type GetHomeownershipsQueryHookResult = ReturnType<typeof useGetHomeownershipsQuery>;
export type GetHomeownershipsLazyQueryHookResult = ReturnType<typeof useGetHomeownershipsLazyQuery>;
export type GetHomeownershipsQueryResult = Apollo.QueryResult<
  GetHomeownershipsQuery,
  GetHomeownershipsQueryVariables
>;
export const GetHoByIdDocument = gql`
  query getHoById(
    $id: ID!
    $withReasons: Boolean = false
    $withProcessStatus: Boolean = false
    $withNotifications: Boolean = false
  ) {
    getHomeOwnership(id: $id) {
      id
      workBorderId
      plot {
        geomId
        ...cardInfoFragment
        breachReasonsObj @include(if: $withReasons) {
          breachReason
          createDateTime
        }
        processStatusObj @include(if: $withProcessStatus) {
          createDateTime
          updatedDateTime
          status
        }
      }
      oks {
        geomId
        ...oksFragment
        breachReasonsObj @include(if: $withReasons) {
          breachReason
          createDateTime
        }
        processStatusObj @include(if: $withProcessStatus) {
          createDateTime
          updatedDateTime
          status
        }
      }
      notifications @include(if: $withNotifications) {
        ...notification
      }
    }
  }
  ${CardInfoFragmentFragmentDoc}
  ${OksFragmentFragmentDoc}
  ${NotificationFragmentDoc}
`;

/**
 * __useGetHoByIdQuery__
 *
 * To run a query within a React component, call `useGetHoByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      withReasons: // value for 'withReasons'
 *      withProcessStatus: // value for 'withProcessStatus'
 *      withNotifications: // value for 'withNotifications'
 *   },
 * });
 */
export function useGetHoByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetHoByIdQuery, GetHoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHoByIdQuery, GetHoByIdQueryVariables>(GetHoByIdDocument, options);
}
export function useGetHoByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHoByIdQuery, GetHoByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHoByIdQuery, GetHoByIdQueryVariables>(GetHoByIdDocument, options);
}
export type GetHoByIdQueryHookResult = ReturnType<typeof useGetHoByIdQuery>;
export type GetHoByIdLazyQueryHookResult = ReturnType<typeof useGetHoByIdLazyQuery>;
export type GetHoByIdQueryResult = Apollo.QueryResult<GetHoByIdQuery, GetHoByIdQueryVariables>;
export const GetHoByIdv2Document = gql`
  query getHoByIdv2(
    $id: ID!
    $taskObjectId: ID!
    $withReasons: Boolean = false
    $withProcessStatus: Boolean = false
    $withNotifications: Boolean = false
  ) {
    getHomeOwnershipV2(id: $id, taskObjectId: $taskObjectId) {
      id
      workBorderId
      visitStatus
      plot {
        geomId
        ...cardInfoFragment
        breachReasonsObj @include(if: $withReasons) {
          breachReason
          createDateTime
        }
        processStatusObj @include(if: $withProcessStatus) {
          createDateTime
          updatedDateTime
          status
        }
      }
      oks {
        geomId
        ...oksFragment
        breachReasonsObj @include(if: $withReasons) {
          breachReason
          createDateTime
        }
        processStatusObj @include(if: $withProcessStatus) {
          createDateTime
          updatedDateTime
          status
        }
      }
      notifications @include(if: $withNotifications) {
        ...notification
      }
    }
  }
  ${CardInfoFragmentFragmentDoc}
  ${OksFragmentFragmentDoc}
  ${NotificationFragmentDoc}
`;

/**
 * __useGetHoByIdv2Query__
 *
 * To run a query within a React component, call `useGetHoByIdv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetHoByIdv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoByIdv2Query({
 *   variables: {
 *      id: // value for 'id'
 *      taskObjectId: // value for 'taskObjectId'
 *      withReasons: // value for 'withReasons'
 *      withProcessStatus: // value for 'withProcessStatus'
 *      withNotifications: // value for 'withNotifications'
 *   },
 * });
 */
export function useGetHoByIdv2Query(
  baseOptions: Apollo.QueryHookOptions<GetHoByIdv2Query, GetHoByIdv2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHoByIdv2Query, GetHoByIdv2QueryVariables>(GetHoByIdv2Document, options);
}
export function useGetHoByIdv2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetHoByIdv2Query, GetHoByIdv2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHoByIdv2Query, GetHoByIdv2QueryVariables>(
    GetHoByIdv2Document,
    options
  );
}
export type GetHoByIdv2QueryHookResult = ReturnType<typeof useGetHoByIdv2Query>;
export type GetHoByIdv2LazyQueryHookResult = ReturnType<typeof useGetHoByIdv2LazyQuery>;
export type GetHoByIdv2QueryResult = Apollo.QueryResult<
  GetHoByIdv2Query,
  GetHoByIdv2QueryVariables
>;
export const GetHoVisitStatusByIdDocument = gql`
  query getHOVisitStatusById($id: ID!, $taskObjectId: ID!) {
    getHomeOwnershipV2(id: $id, taskObjectId: $taskObjectId) {
      id
      visitStatus
    }
  }
`;

/**
 * __useGetHoVisitStatusByIdQuery__
 *
 * To run a query within a React component, call `useGetHoVisitStatusByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetHoVisitStatusByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetHoVisitStatusByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      taskObjectId: // value for 'taskObjectId'
 *   },
 * });
 */
export function useGetHoVisitStatusByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetHoVisitStatusByIdQuery,
    GetHoVisitStatusByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetHoVisitStatusByIdQuery, GetHoVisitStatusByIdQueryVariables>(
    GetHoVisitStatusByIdDocument,
    options
  );
}
export function useGetHoVisitStatusByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetHoVisitStatusByIdQuery,
    GetHoVisitStatusByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetHoVisitStatusByIdQuery, GetHoVisitStatusByIdQueryVariables>(
    GetHoVisitStatusByIdDocument,
    options
  );
}
export type GetHoVisitStatusByIdQueryHookResult = ReturnType<typeof useGetHoVisitStatusByIdQuery>;
export type GetHoVisitStatusByIdLazyQueryHookResult = ReturnType<
  typeof useGetHoVisitStatusByIdLazyQuery
>;
export type GetHoVisitStatusByIdQueryResult = Apollo.QueryResult<
  GetHoVisitStatusByIdQuery,
  GetHoVisitStatusByIdQueryVariables
>;
export const AddOksDocument = gql`
  mutation addOks($oks: AddOksInput!) {
    addOks(oksDto: $oks) {
      id
      parentId
      floor
      cadNum
      address
      phone
      owners
      comment
      geomId
    }
  }
`;
export type AddOksMutationFn = Apollo.MutationFunction<AddOksMutation, AddOksMutationVariables>;

/**
 * __useAddOksMutation__
 *
 * To run a mutation, you first call `useAddOksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOksMutation, { data, loading, error }] = useAddOksMutation({
 *   variables: {
 *      oks: // value for 'oks'
 *   },
 * });
 */
export function useAddOksMutation(
  baseOptions?: Apollo.MutationHookOptions<AddOksMutation, AddOksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddOksMutation, AddOksMutationVariables>(AddOksDocument, options);
}
export type AddOksMutationHookResult = ReturnType<typeof useAddOksMutation>;
export type AddOksMutationResult = Apollo.MutationResult<AddOksMutation>;
export type AddOksMutationOptions = Apollo.BaseMutationOptions<
  AddOksMutation,
  AddOksMutationVariables
>;
export const DeleteOksDocument = gql`
  mutation deleteOks($id: ID!, $taskId: ID!) {
    deleteOksAntRole(id: $id, taskId: $taskId)
  }
`;
export type DeleteOksMutationFn = Apollo.MutationFunction<
  DeleteOksMutation,
  DeleteOksMutationVariables
>;

/**
 * __useDeleteOksMutation__
 *
 * To run a mutation, you first call `useDeleteOksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOksMutation, { data, loading, error }] = useDeleteOksMutation({
 *   variables: {
 *      id: // value for 'id'
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteOksMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteOksMutation, DeleteOksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteOksMutation, DeleteOksMutationVariables>(
    DeleteOksDocument,
    options
  );
}
export type DeleteOksMutationHookResult = ReturnType<typeof useDeleteOksMutation>;
export type DeleteOksMutationResult = Apollo.MutationResult<DeleteOksMutation>;
export type DeleteOksMutationOptions = Apollo.BaseMutationOptions<
  DeleteOksMutation,
  DeleteOksMutationVariables
>;
export const GetOksByIdsDocument = gql`
  query getOksByIds(
    $dto: OksByIdsInput!
    $withReasons: Boolean = false
    $withProcessStatus: Boolean = false
  ) {
    oksById(dto: $dto) {
      ...oksFragment
      breachReasonsObj @include(if: $withReasons) {
        breachReason
        createDateTime
      }
      processStatusObj @include(if: $withProcessStatus) {
        createDateTime
        updatedDateTime
        status
      }
    }
  }
  ${OksFragmentFragmentDoc}
`;

/**
 * __useGetOksByIdsQuery__
 *
 * To run a query within a React component, call `useGetOksByIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOksByIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOksByIdsQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *      withReasons: // value for 'withReasons'
 *      withProcessStatus: // value for 'withProcessStatus'
 *   },
 * });
 */
export function useGetOksByIdsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOksByIdsQuery, GetOksByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOksByIdsQuery, GetOksByIdsQueryVariables>(GetOksByIdsDocument, options);
}
export function useGetOksByIdsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOksByIdsQuery, GetOksByIdsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOksByIdsQuery, GetOksByIdsQueryVariables>(
    GetOksByIdsDocument,
    options
  );
}
export type GetOksByIdsQueryHookResult = ReturnType<typeof useGetOksByIdsQuery>;
export type GetOksByIdsLazyQueryHookResult = ReturnType<typeof useGetOksByIdsLazyQuery>;
export type GetOksByIdsQueryResult = Apollo.QueryResult<
  GetOksByIdsQuery,
  GetOksByIdsQueryVariables
>;
export const GetOksByIdsv2Document = gql`
  query getOksByIdsv2(
    $dto: OksByIdsInput!
    $taskObjectId: [ID!]
    $withReasons: Boolean = false
    $withProcessStatus: Boolean = false
  ) {
    oksByIdV2(dto: $dto, taskObjectIds: $taskObjectId) {
      visitStatus
      ...oksFragment
      breachReasonsObj @include(if: $withReasons) {
        breachReason
        createDateTime
      }
      processStatusObj @include(if: $withProcessStatus) {
        createDateTime
        updatedDateTime
        status
      }
    }
  }
  ${OksFragmentFragmentDoc}
`;

/**
 * __useGetOksByIdsv2Query__
 *
 * To run a query within a React component, call `useGetOksByIdsv2Query` and pass it any options that fit your needs.
 * When your component renders, `useGetOksByIdsv2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOksByIdsv2Query({
 *   variables: {
 *      dto: // value for 'dto'
 *      taskObjectId: // value for 'taskObjectId'
 *      withReasons: // value for 'withReasons'
 *      withProcessStatus: // value for 'withProcessStatus'
 *   },
 * });
 */
export function useGetOksByIdsv2Query(
  baseOptions: Apollo.QueryHookOptions<GetOksByIdsv2Query, GetOksByIdsv2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOksByIdsv2Query, GetOksByIdsv2QueryVariables>(
    GetOksByIdsv2Document,
    options
  );
}
export function useGetOksByIdsv2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOksByIdsv2Query, GetOksByIdsv2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOksByIdsv2Query, GetOksByIdsv2QueryVariables>(
    GetOksByIdsv2Document,
    options
  );
}
export type GetOksByIdsv2QueryHookResult = ReturnType<typeof useGetOksByIdsv2Query>;
export type GetOksByIdsv2LazyQueryHookResult = ReturnType<typeof useGetOksByIdsv2LazyQuery>;
export type GetOksByIdsv2QueryResult = Apollo.QueryResult<
  GetOksByIdsv2Query,
  GetOksByIdsv2QueryVariables
>;
export const UpdateOksDocument = gql`
  mutation updateOks($input: UpdateObjectInput!) {
    updateOksInfoV2(input: $input) {
      oks {
        ...oksFragment
      }
    }
  }
  ${OksFragmentFragmentDoc}
`;
export type UpdateOksMutationFn = Apollo.MutationFunction<
  UpdateOksMutation,
  UpdateOksMutationVariables
>;

/**
 * __useUpdateOksMutation__
 *
 * To run a mutation, you first call `useUpdateOksMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOksMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOksMutation, { data, loading, error }] = useUpdateOksMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOksMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOksMutation, UpdateOksMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOksMutation, UpdateOksMutationVariables>(
    UpdateOksDocument,
    options
  );
}
export type UpdateOksMutationHookResult = ReturnType<typeof useUpdateOksMutation>;
export type UpdateOksMutationResult = Apollo.MutationResult<UpdateOksMutation>;
export type UpdateOksMutationOptions = Apollo.BaseMutationOptions<
  UpdateOksMutation,
  UpdateOksMutationVariables
>;
export const GetPlotByIdDocument = gql`
  query getPlotById($plots: PlotByIdsInput!) {
    plotsById(dto: $plots) {
      ...cardInfoFragment
    }
  }
  ${CardInfoFragmentFragmentDoc}
`;

/**
 * __useGetPlotByIdQuery__
 *
 * To run a query within a React component, call `useGetPlotByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlotByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlotByIdQuery({
 *   variables: {
 *      plots: // value for 'plots'
 *   },
 * });
 */
export function useGetPlotByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetPlotByIdQuery, GetPlotByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlotByIdQuery, GetPlotByIdQueryVariables>(GetPlotByIdDocument, options);
}
export function useGetPlotByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetPlotByIdQuery, GetPlotByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlotByIdQuery, GetPlotByIdQueryVariables>(
    GetPlotByIdDocument,
    options
  );
}
export type GetPlotByIdQueryHookResult = ReturnType<typeof useGetPlotByIdQuery>;
export type GetPlotByIdLazyQueryHookResult = ReturnType<typeof useGetPlotByIdLazyQuery>;
export type GetPlotByIdQueryResult = Apollo.QueryResult<
  GetPlotByIdQuery,
  GetPlotByIdQueryVariables
>;
export const UpdateCardInfoDocument = gql`
  mutation updateCardInfo($input: UpdateObjectInput!) {
    updatePlotInfoV2(input: $input) {
      plot {
        ...cardInfoFragment
      }
    }
  }
  ${CardInfoFragmentFragmentDoc}
`;
export type UpdateCardInfoMutationFn = Apollo.MutationFunction<
  UpdateCardInfoMutation,
  UpdateCardInfoMutationVariables
>;

/**
 * __useUpdateCardInfoMutation__
 *
 * To run a mutation, you first call `useUpdateCardInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCardInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCardInfoMutation, { data, loading, error }] = useUpdateCardInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCardInfoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCardInfoMutation, UpdateCardInfoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCardInfoMutation, UpdateCardInfoMutationVariables>(
    UpdateCardInfoDocument,
    options
  );
}
export type UpdateCardInfoMutationHookResult = ReturnType<typeof useUpdateCardInfoMutation>;
export type UpdateCardInfoMutationResult = Apollo.MutationResult<UpdateCardInfoMutation>;
export type UpdateCardInfoMutationOptions = Apollo.BaseMutationOptions<
  UpdateCardInfoMutation,
  UpdateCardInfoMutationVariables
>;
export const AddRoomDocument = gql`
  mutation addRoom($dto: AddRoomInput!) {
    addRoom(dto: $dto) {
      ...room
    }
  }
  ${RoomFragmentDoc}
`;
export type AddRoomMutationFn = Apollo.MutationFunction<AddRoomMutation, AddRoomMutationVariables>;

/**
 * __useAddRoomMutation__
 *
 * To run a mutation, you first call `useAddRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRoomMutation, { data, loading, error }] = useAddRoomMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useAddRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<AddRoomMutation, AddRoomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRoomMutation, AddRoomMutationVariables>(AddRoomDocument, options);
}
export type AddRoomMutationHookResult = ReturnType<typeof useAddRoomMutation>;
export type AddRoomMutationResult = Apollo.MutationResult<AddRoomMutation>;
export type AddRoomMutationOptions = Apollo.BaseMutationOptions<
  AddRoomMutation,
  AddRoomMutationVariables
>;
export const DeleteRoomDocument = gql`
  mutation deleteRoom($id: ID!) {
    deleteRoom(id: $id)
  }
`;
export type DeleteRoomMutationFn = Apollo.MutationFunction<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;

/**
 * __useDeleteRoomMutation__
 *
 * To run a mutation, you first call `useDeleteRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoomMutation, { data, loading, error }] = useDeleteRoomMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteRoomMutation, DeleteRoomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteRoomMutation, DeleteRoomMutationVariables>(
    DeleteRoomDocument,
    options
  );
}
export type DeleteRoomMutationHookResult = ReturnType<typeof useDeleteRoomMutation>;
export type DeleteRoomMutationResult = Apollo.MutationResult<DeleteRoomMutation>;
export type DeleteRoomMutationOptions = Apollo.BaseMutationOptions<
  DeleteRoomMutation,
  DeleteRoomMutationVariables
>;
export const FindRoomParentGeomIdDocument = gql`
  query findRoomParentGeomId($roomId: ID!) {
    oksGeomIdByRoomId(roomId: $roomId)
  }
`;

/**
 * __useFindRoomParentGeomIdQuery__
 *
 * To run a query within a React component, call `useFindRoomParentGeomIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindRoomParentGeomIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindRoomParentGeomIdQuery({
 *   variables: {
 *      roomId: // value for 'roomId'
 *   },
 * });
 */
export function useFindRoomParentGeomIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindRoomParentGeomIdQuery,
    FindRoomParentGeomIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindRoomParentGeomIdQuery, FindRoomParentGeomIdQueryVariables>(
    FindRoomParentGeomIdDocument,
    options
  );
}
export function useFindRoomParentGeomIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindRoomParentGeomIdQuery,
    FindRoomParentGeomIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindRoomParentGeomIdQuery, FindRoomParentGeomIdQueryVariables>(
    FindRoomParentGeomIdDocument,
    options
  );
}
export type FindRoomParentGeomIdQueryHookResult = ReturnType<typeof useFindRoomParentGeomIdQuery>;
export type FindRoomParentGeomIdLazyQueryHookResult = ReturnType<
  typeof useFindRoomParentGeomIdLazyQuery
>;
export type FindRoomParentGeomIdQueryResult = Apollo.QueryResult<
  FindRoomParentGeomIdQuery,
  FindRoomParentGeomIdQueryVariables
>;
export const GetOksRoomsDocument = gql`
  query getOksRooms($dto: OksByIdsInput!) {
    oksById(dto: $dto) {
      id
      rooms {
        ...room
      }
    }
  }
  ${RoomFragmentDoc}
`;

/**
 * __useGetOksRoomsQuery__
 *
 * To run a query within a React component, call `useGetOksRoomsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOksRoomsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOksRoomsQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetOksRoomsQuery(
  baseOptions: Apollo.QueryHookOptions<GetOksRoomsQuery, GetOksRoomsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOksRoomsQuery, GetOksRoomsQueryVariables>(GetOksRoomsDocument, options);
}
export function useGetOksRoomsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOksRoomsQuery, GetOksRoomsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOksRoomsQuery, GetOksRoomsQueryVariables>(
    GetOksRoomsDocument,
    options
  );
}
export type GetOksRoomsQueryHookResult = ReturnType<typeof useGetOksRoomsQuery>;
export type GetOksRoomsLazyQueryHookResult = ReturnType<typeof useGetOksRoomsLazyQuery>;
export type GetOksRoomsQueryResult = Apollo.QueryResult<
  GetOksRoomsQuery,
  GetOksRoomsQueryVariables
>;
export const GetRoomByIdDocument = gql`
  query getRoomById($id: ID!) {
    roomById(id: $id) {
      ...roomFragment
    }
  }
  ${RoomFragmentFragmentDoc}
`;

/**
 * __useGetRoomByIdQuery__
 *
 * To run a query within a React component, call `useGetRoomByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRoomByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRoomByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRoomByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetRoomByIdQuery, GetRoomByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRoomByIdQuery, GetRoomByIdQueryVariables>(GetRoomByIdDocument, options);
}
export function useGetRoomByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRoomByIdQuery, GetRoomByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRoomByIdQuery, GetRoomByIdQueryVariables>(
    GetRoomByIdDocument,
    options
  );
}
export type GetRoomByIdQueryHookResult = ReturnType<typeof useGetRoomByIdQuery>;
export type GetRoomByIdLazyQueryHookResult = ReturnType<typeof useGetRoomByIdLazyQuery>;
export type GetRoomByIdQueryResult = Apollo.QueryResult<
  GetRoomByIdQuery,
  GetRoomByIdQueryVariables
>;
export const UpdateRoomDocument = gql`
  mutation updateRoom($input: UpdateObjectInput!) {
    updateRoomInfoV2(input: $input) {
      room {
        id
        parentId
        cadNum
        usage
        address
        owners
        phone
        comment
        commentLabels
        unifiedCadNum
        addr {
          ...cardInfoAddrFragment
        }
        breachReasonsObj {
          ...breachReasonsObj
        }
        processStatusObj {
          ...processStatusObj
        }
        noDocReason {
          ...noDocReason
        }
        noInfoReasons {
          ...noInfoReasons
        }
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
  ${BreachReasonsObjFragmentDoc}
  ${ProcessStatusObjFragmentDoc}
  ${NoDocReasonFragmentDoc}
  ${NoInfoReasonsFragmentDoc}
`;
export type UpdateRoomMutationFn = Apollo.MutationFunction<
  UpdateRoomMutation,
  UpdateRoomMutationVariables
>;

/**
 * __useUpdateRoomMutation__
 *
 * To run a mutation, you first call `useUpdateRoomMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoomMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoomMutation, { data, loading, error }] = useUpdateRoomMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRoomMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRoomMutation, UpdateRoomMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRoomMutation, UpdateRoomMutationVariables>(
    UpdateRoomDocument,
    options
  );
}
export type UpdateRoomMutationHookResult = ReturnType<typeof useUpdateRoomMutation>;
export type UpdateRoomMutationResult = Apollo.MutationResult<UpdateRoomMutation>;
export type UpdateRoomMutationOptions = Apollo.BaseMutationOptions<
  UpdateRoomMutation,
  UpdateRoomMutationVariables
>;
export const UpdateAntSalaryTariffDocument = gql`
  mutation updateAntSalaryTariff($input: SalaryTariffInput!) {
    updateAntSalaryTariff(input: $input) {
      tariffs {
        value
        type
      }
    }
  }
`;
export type UpdateAntSalaryTariffMutationFn = Apollo.MutationFunction<
  UpdateAntSalaryTariffMutation,
  UpdateAntSalaryTariffMutationVariables
>;

/**
 * __useUpdateAntSalaryTariffMutation__
 *
 * To run a mutation, you first call `useUpdateAntSalaryTariffMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAntSalaryTariffMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAntSalaryTariffMutation, { data, loading, error }] = useUpdateAntSalaryTariffMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAntSalaryTariffMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateAntSalaryTariffMutation,
    UpdateAntSalaryTariffMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAntSalaryTariffMutation, UpdateAntSalaryTariffMutationVariables>(
    UpdateAntSalaryTariffDocument,
    options
  );
}
export type UpdateAntSalaryTariffMutationHookResult = ReturnType<
  typeof useUpdateAntSalaryTariffMutation
>;
export type UpdateAntSalaryTariffMutationResult =
  Apollo.MutationResult<UpdateAntSalaryTariffMutation>;
export type UpdateAntSalaryTariffMutationOptions = Apollo.BaseMutationOptions<
  UpdateAntSalaryTariffMutation,
  UpdateAntSalaryTariffMutationVariables
>;
export const UpdateCommentLabelsDocument = gql`
  mutation updateCommentLabels($input: CommentLabelUpdateInputV2!) {
    updateCommentLabelsV2(input: $input) {
      commentLabel {
        id
      }
    }
  }
`;
export type UpdateCommentLabelsMutationFn = Apollo.MutationFunction<
  UpdateCommentLabelsMutation,
  UpdateCommentLabelsMutationVariables
>;

/**
 * __useUpdateCommentLabelsMutation__
 *
 * To run a mutation, you first call `useUpdateCommentLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentLabelsMutation, { data, loading, error }] = useUpdateCommentLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentLabelsMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCommentLabelsMutation,
    UpdateCommentLabelsMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCommentLabelsMutation, UpdateCommentLabelsMutationVariables>(
    UpdateCommentLabelsDocument,
    options
  );
}
export type UpdateCommentLabelsMutationHookResult = ReturnType<
  typeof useUpdateCommentLabelsMutation
>;
export type UpdateCommentLabelsMutationResult = Apollo.MutationResult<UpdateCommentLabelsMutation>;
export type UpdateCommentLabelsMutationOptions = Apollo.BaseMutationOptions<
  UpdateCommentLabelsMutation,
  UpdateCommentLabelsMutationVariables
>;
export const UpdateCurrentVisitStatusDocument = gql`
  mutation updateCurrentVisitStatus($input: UpdateTaskVisitStatusInput!) {
    updateTaskVisitStatus(input: $input) {
      __typename
    }
  }
`;
export type UpdateCurrentVisitStatusMutationFn = Apollo.MutationFunction<
  UpdateCurrentVisitStatusMutation,
  UpdateCurrentVisitStatusMutationVariables
>;

/**
 * __useUpdateCurrentVisitStatusMutation__
 *
 * To run a mutation, you first call `useUpdateCurrentVisitStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCurrentVisitStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCurrentVisitStatusMutation, { data, loading, error }] = useUpdateCurrentVisitStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCurrentVisitStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateCurrentVisitStatusMutation,
    UpdateCurrentVisitStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateCurrentVisitStatusMutation,
    UpdateCurrentVisitStatusMutationVariables
  >(UpdateCurrentVisitStatusDocument, options);
}
export type UpdateCurrentVisitStatusMutationHookResult = ReturnType<
  typeof useUpdateCurrentVisitStatusMutation
>;
export type UpdateCurrentVisitStatusMutationResult =
  Apollo.MutationResult<UpdateCurrentVisitStatusMutation>;
export type UpdateCurrentVisitStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateCurrentVisitStatusMutation,
  UpdateCurrentVisitStatusMutationVariables
>;
export const UpdateNoDocReasonDocument = gql`
  mutation updateNoDocReason($dto: NoDocReasonInput!) {
    updateNoDocReason(dto: $dto) {
      contentId
      noDocumentReason
      noDocumentDeadline
    }
  }
`;
export type UpdateNoDocReasonMutationFn = Apollo.MutationFunction<
  UpdateNoDocReasonMutation,
  UpdateNoDocReasonMutationVariables
>;

/**
 * __useUpdateNoDocReasonMutation__
 *
 * To run a mutation, you first call `useUpdateNoDocReasonMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNoDocReasonMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNoDocReasonMutation, { data, loading, error }] = useUpdateNoDocReasonMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateNoDocReasonMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateNoDocReasonMutation,
    UpdateNoDocReasonMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNoDocReasonMutation, UpdateNoDocReasonMutationVariables>(
    UpdateNoDocReasonDocument,
    options
  );
}
export type UpdateNoDocReasonMutationHookResult = ReturnType<typeof useUpdateNoDocReasonMutation>;
export type UpdateNoDocReasonMutationResult = Apollo.MutationResult<UpdateNoDocReasonMutation>;
export type UpdateNoDocReasonMutationOptions = Apollo.BaseMutationOptions<
  UpdateNoDocReasonMutation,
  UpdateNoDocReasonMutationVariables
>;
export const FindByWindowDocument = gql`
  query findByWindow($tile: WindowInput!) {
    findByWindowV3(windowDto: $tile) {
      id
      hintValue
      status
      geo {
        coordinates
      }
    }
  }
`;

/**
 * __useFindByWindowQuery__
 *
 * To run a query within a React component, call `useFindByWindowQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindByWindowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByWindowQuery({
 *   variables: {
 *      tile: // value for 'tile'
 *   },
 * });
 */
export function useFindByWindowQuery(
  baseOptions: Apollo.QueryHookOptions<FindByWindowQuery, FindByWindowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindByWindowQuery, FindByWindowQueryVariables>(
    FindByWindowDocument,
    options
  );
}
export function useFindByWindowLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindByWindowQuery, FindByWindowQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindByWindowQuery, FindByWindowQueryVariables>(
    FindByWindowDocument,
    options
  );
}
export type FindByWindowQueryHookResult = ReturnType<typeof useFindByWindowQuery>;
export type FindByWindowLazyQueryHookResult = ReturnType<typeof useFindByWindowLazyQuery>;
export type FindByWindowQueryResult = Apollo.QueryResult<
  FindByWindowQuery,
  FindByWindowQueryVariables
>;
export const FindByWindow2Document = gql`
  query findByWindow2($tile: WindowInput!) {
    findByWindowV3(windowDto: $tile) {
      id
      hintValue
      status
      geo {
        coordinates
      }
    }
  }
`;

/**
 * __useFindByWindow2Query__
 *
 * To run a query within a React component, call `useFindByWindow2Query` and pass it any options that fit your needs.
 * When your component renders, `useFindByWindow2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByWindow2Query({
 *   variables: {
 *      tile: // value for 'tile'
 *   },
 * });
 */
export function useFindByWindow2Query(
  baseOptions: Apollo.QueryHookOptions<FindByWindow2Query, FindByWindow2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindByWindow2Query, FindByWindow2QueryVariables>(
    FindByWindow2Document,
    options
  );
}
export function useFindByWindow2LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindByWindow2Query, FindByWindow2QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindByWindow2Query, FindByWindow2QueryVariables>(
    FindByWindow2Document,
    options
  );
}
export type FindByWindow2QueryHookResult = ReturnType<typeof useFindByWindow2Query>;
export type FindByWindow2LazyQueryHookResult = ReturnType<typeof useFindByWindow2LazyQuery>;
export type FindByWindow2QueryResult = Apollo.QueryResult<
  FindByWindow2Query,
  FindByWindow2QueryVariables
>;
export const FindByWindow3Document = gql`
  query findByWindow3($tile: WindowInput!) {
    findByWindowV3(windowDto: $tile) {
      id
      hintValue
      status
      geomId
    }
  }
`;

/**
 * __useFindByWindow3Query__
 *
 * To run a query within a React component, call `useFindByWindow3Query` and pass it any options that fit your needs.
 * When your component renders, `useFindByWindow3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindByWindow3Query({
 *   variables: {
 *      tile: // value for 'tile'
 *   },
 * });
 */
export function useFindByWindow3Query(
  baseOptions: Apollo.QueryHookOptions<FindByWindow3Query, FindByWindow3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindByWindow3Query, FindByWindow3QueryVariables>(
    FindByWindow3Document,
    options
  );
}
export function useFindByWindow3LazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindByWindow3Query, FindByWindow3QueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindByWindow3Query, FindByWindow3QueryVariables>(
    FindByWindow3Document,
    options
  );
}
export type FindByWindow3QueryHookResult = ReturnType<typeof useFindByWindow3Query>;
export type FindByWindow3LazyQueryHookResult = ReturnType<typeof useFindByWindow3LazyQuery>;
export type FindByWindow3QueryResult = Apollo.QueryResult<
  FindByWindow3Query,
  FindByWindow3QueryVariables
>;
export const GetBordersDocument = gql`
  query getBorders($withGeo: Boolean = true) {
    bordersByToken {
      id
      name
      bType
      cityName
      geo @include(if: $withGeo) {
        coordinates
        type
      }
      parentId
    }
  }
`;

/**
 * __useGetBordersQuery__
 *
 * To run a query within a React component, call `useGetBordersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBordersQuery({
 *   variables: {
 *      withGeo: // value for 'withGeo'
 *   },
 * });
 */
export function useGetBordersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBordersQuery, GetBordersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBordersQuery, GetBordersQueryVariables>(GetBordersDocument, options);
}
export function useGetBordersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBordersQuery, GetBordersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBordersQuery, GetBordersQueryVariables>(
    GetBordersDocument,
    options
  );
}
export type GetBordersQueryHookResult = ReturnType<typeof useGetBordersQuery>;
export type GetBordersLazyQueryHookResult = ReturnType<typeof useGetBordersLazyQuery>;
export type GetBordersQueryResult = Apollo.QueryResult<GetBordersQuery, GetBordersQueryVariables>;
export const GetMapObjectsDocument = gql`
  query getMapObjects($tile: MapObjectInput!) {
    getMapObjects(dto: $tile) {
      id
      geomId
      contentType
      status
      wasInTask
      layerId
    }
  }
`;

/**
 * __useGetMapObjectsQuery__
 *
 * To run a query within a React component, call `useGetMapObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMapObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMapObjectsQuery({
 *   variables: {
 *      tile: // value for 'tile'
 *   },
 * });
 */
export function useGetMapObjectsQuery(
  baseOptions: Apollo.QueryHookOptions<GetMapObjectsQuery, GetMapObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMapObjectsQuery, GetMapObjectsQueryVariables>(
    GetMapObjectsDocument,
    options
  );
}
export function useGetMapObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMapObjectsQuery, GetMapObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMapObjectsQuery, GetMapObjectsQueryVariables>(
    GetMapObjectsDocument,
    options
  );
}
export type GetMapObjectsQueryHookResult = ReturnType<typeof useGetMapObjectsQuery>;
export type GetMapObjectsLazyQueryHookResult = ReturnType<typeof useGetMapObjectsLazyQuery>;
export type GetMapObjectsQueryResult = Apollo.QueryResult<
  GetMapObjectsQuery,
  GetMapObjectsQueryVariables
>;
export const GetCachedMapObjectsDocument = gql`
  query getCachedMapObjects($tile: MapObjectInput!) {
    getCachedMapObjects(dto: $tile) {
      id
      geomId
      contentType
      status
      wasInTask
      layerId
    }
  }
`;

/**
 * __useGetCachedMapObjectsQuery__
 *
 * To run a query within a React component, call `useGetCachedMapObjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCachedMapObjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCachedMapObjectsQuery({
 *   variables: {
 *      tile: // value for 'tile'
 *   },
 * });
 */
export function useGetCachedMapObjectsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCachedMapObjectsQuery, GetCachedMapObjectsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCachedMapObjectsQuery, GetCachedMapObjectsQueryVariables>(
    GetCachedMapObjectsDocument,
    options
  );
}
export function useGetCachedMapObjectsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCachedMapObjectsQuery,
    GetCachedMapObjectsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCachedMapObjectsQuery, GetCachedMapObjectsQueryVariables>(
    GetCachedMapObjectsDocument,
    options
  );
}
export type GetCachedMapObjectsQueryHookResult = ReturnType<typeof useGetCachedMapObjectsQuery>;
export type GetCachedMapObjectsLazyQueryHookResult = ReturnType<
  typeof useGetCachedMapObjectsLazyQuery
>;
export type GetCachedMapObjectsQueryResult = Apollo.QueryResult<
  GetCachedMapObjectsQuery,
  GetCachedMapObjectsQueryVariables
>;
export const AllRegionsDocument = gql`
  query allRegions {
    regionBordersByToken {
      geo {
        coordinates
      }
      id
      name
      ofpName
    }
  }
`;

/**
 * __useAllRegionsQuery__
 *
 * To run a query within a React component, call `useAllRegionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRegionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRegionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRegionsQuery(
  baseOptions?: Apollo.QueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllRegionsQuery, AllRegionsQueryVariables>(AllRegionsDocument, options);
}
export function useAllRegionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AllRegionsQuery, AllRegionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllRegionsQuery, AllRegionsQueryVariables>(
    AllRegionsDocument,
    options
  );
}
export type AllRegionsQueryHookResult = ReturnType<typeof useAllRegionsQuery>;
export type AllRegionsLazyQueryHookResult = ReturnType<typeof useAllRegionsLazyQuery>;
export type AllRegionsQueryResult = Apollo.QueryResult<AllRegionsQuery, AllRegionsQueryVariables>;
export const HeatMapDocument = gql`
  query heatMap($dto: HeatMapInput!) {
    heatMap(dto: $dto) {
      count
      geo {
        coordinates
        type
      }
    }
  }
`;

/**
 * __useHeatMapQuery__
 *
 * To run a query within a React component, call `useHeatMapQuery` and pass it any options that fit your needs.
 * When your component renders, `useHeatMapQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHeatMapQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useHeatMapQuery(
  baseOptions: Apollo.QueryHookOptions<HeatMapQuery, HeatMapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<HeatMapQuery, HeatMapQueryVariables>(HeatMapDocument, options);
}
export function useHeatMapLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<HeatMapQuery, HeatMapQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<HeatMapQuery, HeatMapQueryVariables>(HeatMapDocument, options);
}
export type HeatMapQueryHookResult = ReturnType<typeof useHeatMapQuery>;
export type HeatMapLazyQueryHookResult = ReturnType<typeof useHeatMapLazyQuery>;
export type HeatMapQueryResult = Apollo.QueryResult<HeatMapQuery, HeatMapQueryVariables>;
export const WorkBordersByRegionDocument = gql`
  query workBordersByRegion($id: ID!) {
    workBordersByRegionId(id: $id) {
      id
    }
  }
`;

/**
 * __useWorkBordersByRegionQuery__
 *
 * To run a query within a React component, call `useWorkBordersByRegionQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkBordersByRegionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkBordersByRegionQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkBordersByRegionQuery(
  baseOptions: Apollo.QueryHookOptions<WorkBordersByRegionQuery, WorkBordersByRegionQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WorkBordersByRegionQuery, WorkBordersByRegionQueryVariables>(
    WorkBordersByRegionDocument,
    options
  );
}
export function useWorkBordersByRegionLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkBordersByRegionQuery,
    WorkBordersByRegionQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WorkBordersByRegionQuery, WorkBordersByRegionQueryVariables>(
    WorkBordersByRegionDocument,
    options
  );
}
export type WorkBordersByRegionQueryHookResult = ReturnType<typeof useWorkBordersByRegionQuery>;
export type WorkBordersByRegionLazyQueryHookResult = ReturnType<
  typeof useWorkBordersByRegionLazyQuery
>;
export type WorkBordersByRegionQueryResult = Apollo.QueryResult<
  WorkBordersByRegionQuery,
  WorkBordersByRegionQueryVariables
>;
export const AddGroupDocument = gql`
  mutation addGroup($group: AddGroupInput!) {
    addGroup(groupDto: $group) {
      id
      name
      parentId
    }
  }
`;
export type AddGroupMutationFn = Apollo.MutationFunction<
  AddGroupMutation,
  AddGroupMutationVariables
>;

/**
 * __useAddGroupMutation__
 *
 * To run a mutation, you first call `useAddGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addGroupMutation, { data, loading, error }] = useAddGroupMutation({
 *   variables: {
 *      group: // value for 'group'
 *   },
 * });
 */
export function useAddGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<AddGroupMutation, AddGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddGroupMutation, AddGroupMutationVariables>(AddGroupDocument, options);
}
export type AddGroupMutationHookResult = ReturnType<typeof useAddGroupMutation>;
export type AddGroupMutationResult = Apollo.MutationResult<AddGroupMutation>;
export type AddGroupMutationOptions = Apollo.BaseMutationOptions<
  AddGroupMutation,
  AddGroupMutationVariables
>;
export const AddLayerDocument = gql`
  mutation addLayer($layer: LayerAddInput!) {
    addLayer(dto: $layer) {
      id
      name
      layerType
      contentType
      groupId
      opacity
      editable
      color
      visible
    }
  }
`;
export type AddLayerMutationFn = Apollo.MutationFunction<
  AddLayerMutation,
  AddLayerMutationVariables
>;

/**
 * __useAddLayerMutation__
 *
 * To run a mutation, you first call `useAddLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addLayerMutation, { data, loading, error }] = useAddLayerMutation({
 *   variables: {
 *      layer: // value for 'layer'
 *   },
 * });
 */
export function useAddLayerMutation(
  baseOptions?: Apollo.MutationHookOptions<AddLayerMutation, AddLayerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddLayerMutation, AddLayerMutationVariables>(AddLayerDocument, options);
}
export type AddLayerMutationHookResult = ReturnType<typeof useAddLayerMutation>;
export type AddLayerMutationResult = Apollo.MutationResult<AddLayerMutation>;
export type AddLayerMutationOptions = Apollo.BaseMutationOptions<
  AddLayerMutation,
  AddLayerMutationVariables
>;
export const DeleteLGroupDocument = gql`
  mutation deleteLGroup($id: ID!) {
    deleteGroup(id: $id)
  }
`;
export type DeleteLGroupMutationFn = Apollo.MutationFunction<
  DeleteLGroupMutation,
  DeleteLGroupMutationVariables
>;

/**
 * __useDeleteLGroupMutation__
 *
 * To run a mutation, you first call `useDeleteLGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLGroupMutation, { data, loading, error }] = useDeleteLGroupMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLGroupMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLGroupMutation, DeleteLGroupMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLGroupMutation, DeleteLGroupMutationVariables>(
    DeleteLGroupDocument,
    options
  );
}
export type DeleteLGroupMutationHookResult = ReturnType<typeof useDeleteLGroupMutation>;
export type DeleteLGroupMutationResult = Apollo.MutationResult<DeleteLGroupMutation>;
export type DeleteLGroupMutationOptions = Apollo.BaseMutationOptions<
  DeleteLGroupMutation,
  DeleteLGroupMutationVariables
>;
export const DeleteLayerDocument = gql`
  mutation deleteLayer($id: ID!) {
    deleteLayer(id: $id)
  }
`;
export type DeleteLayerMutationFn = Apollo.MutationFunction<
  DeleteLayerMutation,
  DeleteLayerMutationVariables
>;

/**
 * __useDeleteLayerMutation__
 *
 * To run a mutation, you first call `useDeleteLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLayerMutation, { data, loading, error }] = useDeleteLayerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteLayerMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteLayerMutation, DeleteLayerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteLayerMutation, DeleteLayerMutationVariables>(
    DeleteLayerDocument,
    options
  );
}
export type DeleteLayerMutationHookResult = ReturnType<typeof useDeleteLayerMutation>;
export type DeleteLayerMutationResult = Apollo.MutationResult<DeleteLayerMutation>;
export type DeleteLayerMutationOptions = Apollo.BaseMutationOptions<
  DeleteLayerMutation,
  DeleteLayerMutationVariables
>;
export const GetAllLayoutsDocument = gql`
  query getAllLayouts {
    allLayers {
      id
      name
      groupId
      opacity
      editable
      updatable
      color
      visible
      contentType
      layerType
      zMax
      zMin
      count
    }
    groups {
      id
      name
      parentId
    }
  }
`;

/**
 * __useGetAllLayoutsQuery__
 *
 * To run a query within a React component, call `useGetAllLayoutsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLayoutsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLayoutsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLayoutsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllLayoutsQuery, GetAllLayoutsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllLayoutsQuery, GetAllLayoutsQueryVariables>(
    GetAllLayoutsDocument,
    options
  );
}
export function useGetAllLayoutsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllLayoutsQuery, GetAllLayoutsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllLayoutsQuery, GetAllLayoutsQueryVariables>(
    GetAllLayoutsDocument,
    options
  );
}
export type GetAllLayoutsQueryHookResult = ReturnType<typeof useGetAllLayoutsQuery>;
export type GetAllLayoutsLazyQueryHookResult = ReturnType<typeof useGetAllLayoutsLazyQuery>;
export type GetAllLayoutsQueryResult = Apollo.QueryResult<
  GetAllLayoutsQuery,
  GetAllLayoutsQueryVariables
>;
export const LayersByTokenDocument = gql`
  query layersByToken {
    layersByToken {
      id
      name
      groupId
      opacity
      editable
      updatable
      color
      visible
      contentType
      layerType
      zMax
      zMin
      count
      isAllowedToExport
      isAllowedToImport
      taskLayer
    }
  }
`;

/**
 * __useLayersByTokenQuery__
 *
 * To run a query within a React component, call `useLayersByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useLayersByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLayersByTokenQuery({
 *   variables: {
 *   },
 * });
 */
export function useLayersByTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<LayersByTokenQuery, LayersByTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LayersByTokenQuery, LayersByTokenQueryVariables>(
    LayersByTokenDocument,
    options
  );
}
export function useLayersByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LayersByTokenQuery, LayersByTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LayersByTokenQuery, LayersByTokenQueryVariables>(
    LayersByTokenDocument,
    options
  );
}
export type LayersByTokenQueryHookResult = ReturnType<typeof useLayersByTokenQuery>;
export type LayersByTokenLazyQueryHookResult = ReturnType<typeof useLayersByTokenLazyQuery>;
export type LayersByTokenQueryResult = Apollo.QueryResult<
  LayersByTokenQuery,
  LayersByTokenQueryVariables
>;
export const UpdateLayerDocument = gql`
  mutation updateLayer($layer: LayerUpdateInput!) {
    updateLayer(dto: $layer) {
      id
    }
  }
`;
export type UpdateLayerMutationFn = Apollo.MutationFunction<
  UpdateLayerMutation,
  UpdateLayerMutationVariables
>;

/**
 * __useUpdateLayerMutation__
 *
 * To run a mutation, you first call `useUpdateLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLayerMutation, { data, loading, error }] = useUpdateLayerMutation({
 *   variables: {
 *      layer: // value for 'layer'
 *   },
 * });
 */
export function useUpdateLayerMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateLayerMutation, UpdateLayerMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateLayerMutation, UpdateLayerMutationVariables>(
    UpdateLayerDocument,
    options
  );
}
export type UpdateLayerMutationHookResult = ReturnType<typeof useUpdateLayerMutation>;
export type UpdateLayerMutationResult = Apollo.MutationResult<UpdateLayerMutation>;
export type UpdateLayerMutationOptions = Apollo.BaseMutationOptions<
  UpdateLayerMutation,
  UpdateLayerMutationVariables
>;
export const UpdateMapObjectLayerDocument = gql`
  mutation updateMapObjectLayer($dto: [UpdateObjectLayerInput!]!) {
    updateMapObjectLayer(input: $dto)
  }
`;
export type UpdateMapObjectLayerMutationFn = Apollo.MutationFunction<
  UpdateMapObjectLayerMutation,
  UpdateMapObjectLayerMutationVariables
>;

/**
 * __useUpdateMapObjectLayerMutation__
 *
 * To run a mutation, you first call `useUpdateMapObjectLayerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMapObjectLayerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMapObjectLayerMutation, { data, loading, error }] = useUpdateMapObjectLayerMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateMapObjectLayerMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateMapObjectLayerMutation,
    UpdateMapObjectLayerMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMapObjectLayerMutation, UpdateMapObjectLayerMutationVariables>(
    UpdateMapObjectLayerDocument,
    options
  );
}
export type UpdateMapObjectLayerMutationHookResult = ReturnType<
  typeof useUpdateMapObjectLayerMutation
>;
export type UpdateMapObjectLayerMutationResult =
  Apollo.MutationResult<UpdateMapObjectLayerMutation>;
export type UpdateMapObjectLayerMutationOptions = Apollo.BaseMutationOptions<
  UpdateMapObjectLayerMutation,
  UpdateMapObjectLayerMutationVariables
>;
export const AddTaskDocument = gql`
  mutation addTask($task: TaskRequest!) {
    addTask(taskRequest: $task) {
      id
    }
  }
`;
export type AddTaskMutationFn = Apollo.MutationFunction<AddTaskMutation, AddTaskMutationVariables>;

/**
 * __useAddTaskMutation__
 *
 * To run a mutation, you first call `useAddTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskMutation, { data, loading, error }] = useAddTaskMutation({
 *   variables: {
 *      task: // value for 'task'
 *   },
 * });
 */
export function useAddTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTaskMutation, AddTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTaskMutation, AddTaskMutationVariables>(AddTaskDocument, options);
}
export type AddTaskMutationHookResult = ReturnType<typeof useAddTaskMutation>;
export type AddTaskMutationResult = Apollo.MutationResult<AddTaskMutation>;
export type AddTaskMutationOptions = Apollo.BaseMutationOptions<
  AddTaskMutation,
  AddTaskMutationVariables
>;
export const DeleteTaskDocument = gql`
  mutation deleteTask($id: ID!) {
    deleteTask(id: $id)
  }
`;
export type DeleteTaskMutationFn = Apollo.MutationFunction<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(
    DeleteTaskDocument,
    options
  );
}
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = Apollo.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = Apollo.BaseMutationOptions<
  DeleteTaskMutation,
  DeleteTaskMutationVariables
>;
export const UpdateTaskStatusDocument = gql`
  mutation updateTaskStatus($dto: UpdateTaskObjectStatusInput!) {
    updateTaskObjectStatus(dto: $dto) {
      objectId
      status
    }
  }
`;
export type UpdateTaskStatusMutationFn = Apollo.MutationFunction<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;

/**
 * __useUpdateTaskStatusMutation__
 *
 * To run a mutation, you first call `useUpdateTaskStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskStatusMutation, { data, loading, error }] = useUpdateTaskStatusMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useUpdateTaskStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateTaskStatusMutation,
    UpdateTaskStatusMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTaskStatusMutation, UpdateTaskStatusMutationVariables>(
    UpdateTaskStatusDocument,
    options
  );
}
export type UpdateTaskStatusMutationHookResult = ReturnType<typeof useUpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationResult = Apollo.MutationResult<UpdateTaskStatusMutation>;
export type UpdateTaskStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateTaskStatusMutation,
  UpdateTaskStatusMutationVariables
>;
export const GetTasksDocument = gql`
  query getTasks {
    getTasks {
      id
      name
      authorName
      deadLine
      executorName
      objects {
        id
        ... on HomeOwnershipTaskObject {
          homeOwnership {
            id
            plotId
            plot {
              geomId
            }
          }
        }
        ... on OksTaskObject {
          oks {
            id
            geomId
            layerId
          }
        }
        status
      }
      type
      objectsStatuses {
        count
        status
        labelColor
        hexColor
        label
      }
    }
  }
`;

/**
 * __useGetTasksQuery__
 *
 * To run a query within a React component, call `useGetTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTasksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export function useGetTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTasksQuery, GetTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksQuery, GetTasksQueryVariables>(GetTasksDocument, options);
}
export type GetTasksQueryHookResult = ReturnType<typeof useGetTasksQuery>;
export type GetTasksLazyQueryHookResult = ReturnType<typeof useGetTasksLazyQuery>;
export type GetTasksQueryResult = Apollo.QueryResult<GetTasksQuery, GetTasksQueryVariables>;
export const GetTasksByRegionAndExecutorDocument = gql`
  query getTasksByRegionAndExecutor($dto: ManagerFilterTasksInput!) {
    getTasksV3(input: $dto) {
      id
      name
      type
      author {
        id
        firstName
        lastName
      }
      deadLine
      executor {
        id
        firstName
        lastName
      }
      objectsStatuses {
        count
        status
        labelColor
        hexColor
        label
      }
    }
  }
`;

/**
 * __useGetTasksByRegionAndExecutorQuery__
 *
 * To run a query within a React component, call `useGetTasksByRegionAndExecutorQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksByRegionAndExecutorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksByRegionAndExecutorQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetTasksByRegionAndExecutorQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetTasksByRegionAndExecutorQuery,
    GetTasksByRegionAndExecutorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetTasksByRegionAndExecutorQuery,
    GetTasksByRegionAndExecutorQueryVariables
  >(GetTasksByRegionAndExecutorDocument, options);
}
export function useGetTasksByRegionAndExecutorLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetTasksByRegionAndExecutorQuery,
    GetTasksByRegionAndExecutorQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetTasksByRegionAndExecutorQuery,
    GetTasksByRegionAndExecutorQueryVariables
  >(GetTasksByRegionAndExecutorDocument, options);
}
export type GetTasksByRegionAndExecutorQueryHookResult = ReturnType<
  typeof useGetTasksByRegionAndExecutorQuery
>;
export type GetTasksByRegionAndExecutorLazyQueryHookResult = ReturnType<
  typeof useGetTasksByRegionAndExecutorLazyQuery
>;
export type GetTasksByRegionAndExecutorQueryResult = Apollo.QueryResult<
  GetTasksByRegionAndExecutorQuery,
  GetTasksByRegionAndExecutorQueryVariables
>;
export const GetTasksMinimalDocument = gql`
  query getTasksMinimal {
    getTasks {
      id
      name
      type
      author {
        id
        lastName
        firstName
      }
      executor {
        id
        firstName
        lastName
      }
      deadLine
      objects {
        id
        ... on OksTaskObject {
          oks {
            id
            layerId
          }
        }
        ... on HomeOwnershipTaskObject {
          homeOwnership {
            plot {
              geomId
            }
            id
          }
        }
        status
      }
      objectsStatuses {
        ...taskStatus
      }
    }
  }
  ${TaskStatusFragmentDoc}
`;

/**
 * __useGetTasksMinimalQuery__
 *
 * To run a query within a React component, call `useGetTasksMinimalQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTasksMinimalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTasksMinimalQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTasksMinimalQuery(
  baseOptions?: Apollo.QueryHookOptions<GetTasksMinimalQuery, GetTasksMinimalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTasksMinimalQuery, GetTasksMinimalQueryVariables>(
    GetTasksMinimalDocument,
    options
  );
}
export function useGetTasksMinimalLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTasksMinimalQuery, GetTasksMinimalQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTasksMinimalQuery, GetTasksMinimalQueryVariables>(
    GetTasksMinimalDocument,
    options
  );
}
export type GetTasksMinimalQueryHookResult = ReturnType<typeof useGetTasksMinimalQuery>;
export type GetTasksMinimalLazyQueryHookResult = ReturnType<typeof useGetTasksMinimalLazyQuery>;
export type GetTasksMinimalQueryResult = Apollo.QueryResult<
  GetTasksMinimalQuery,
  GetTasksMinimalQueryVariables
>;
export const GetAuthorTasksDocument = gql`
  query getAuthorTasks($id: ID!) {
    getAuthorTasks(id: $id) {
      id
      name
      type
      author {
        id
        lastName
        firstName
      }
      executor {
        id
        firstName
        lastName
      }
      deadLine
      objectsStatuses {
        ...taskStatus
      }
    }
  }
  ${TaskStatusFragmentDoc}
`;

/**
 * __useGetAuthorTasksQuery__
 *
 * To run a query within a React component, call `useGetAuthorTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthorTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthorTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAuthorTasksQuery(
  baseOptions: Apollo.QueryHookOptions<GetAuthorTasksQuery, GetAuthorTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAuthorTasksQuery, GetAuthorTasksQueryVariables>(
    GetAuthorTasksDocument,
    options
  );
}
export function useGetAuthorTasksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAuthorTasksQuery, GetAuthorTasksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAuthorTasksQuery, GetAuthorTasksQueryVariables>(
    GetAuthorTasksDocument,
    options
  );
}
export type GetAuthorTasksQueryHookResult = ReturnType<typeof useGetAuthorTasksQuery>;
export type GetAuthorTasksLazyQueryHookResult = ReturnType<typeof useGetAuthorTasksLazyQuery>;
export type GetAuthorTasksQueryResult = Apollo.QueryResult<
  GetAuthorTasksQuery,
  GetAuthorTasksQueryVariables
>;
export const GetTaskByIdDocument = gql`
  query getTaskById($id: ID!) {
    getTask(id: $id) {
      id
      name
      type
      objects {
        id
        status
        ... on HomeOwnershipTaskObject {
          homeOwnership {
            id
          }
        }
        ... on OksTaskObject {
          oks {
            id
            layerId
            type
          }
        }
      }
    }
  }
`;

/**
 * __useGetTaskByIdQuery__
 *
 * To run a query within a React component, call `useGetTaskByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskByIdQuery, GetTaskByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskByIdQuery, GetTaskByIdQueryVariables>(GetTaskByIdDocument, options);
}
export function useGetTaskByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskByIdQuery, GetTaskByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskByIdQuery, GetTaskByIdQueryVariables>(
    GetTaskByIdDocument,
    options
  );
}
export type GetTaskByIdQueryHookResult = ReturnType<typeof useGetTaskByIdQuery>;
export type GetTaskByIdLazyQueryHookResult = ReturnType<typeof useGetTaskByIdLazyQuery>;
export type GetTaskByIdQueryResult = Apollo.QueryResult<
  GetTaskByIdQuery,
  GetTaskByIdQueryVariables
>;
export const GetTaskGeoDocument = gql`
  query getTaskGeo($id: ID!) {
    getTask(id: $id) {
      id
      name
      type
      objects {
        id
        ... on HomeOwnershipTaskObject {
          homeOwnership {
            id
            plotId
            plot {
              geomId
              address
              addr {
                ...cardInfoAddrFragment
              }
            }
          }
        }
        ... on OksTaskObject {
          oks {
            id
            geomId
            layerId
            address
            addr {
              ...cardInfoAddrFragment
            }
          }
        }
        status
      }
    }
  }
  ${CardInfoAddrFragmentFragmentDoc}
`;

/**
 * __useGetTaskGeoQuery__
 *
 * To run a query within a React component, call `useGetTaskGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskGeoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskGeoQuery(
  baseOptions: Apollo.QueryHookOptions<GetTaskGeoQuery, GetTaskGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTaskGeoQuery, GetTaskGeoQueryVariables>(GetTaskGeoDocument, options);
}
export function useGetTaskGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTaskGeoQuery, GetTaskGeoQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTaskGeoQuery, GetTaskGeoQueryVariables>(
    GetTaskGeoDocument,
    options
  );
}
export type GetTaskGeoQueryHookResult = ReturnType<typeof useGetTaskGeoQuery>;
export type GetTaskGeoLazyQueryHookResult = ReturnType<typeof useGetTaskGeoLazyQuery>;
export type GetTaskGeoQueryResult = Apollo.QueryResult<GetTaskGeoQuery, GetTaskGeoQueryVariables>;
export const BlockUserDocument = gql`
  mutation blockUser($id: ID!) {
    blockUser(id: $id) {
      id
    }
  }
`;
export type BlockUserMutationFn = Apollo.MutationFunction<
  BlockUserMutation,
  BlockUserMutationVariables
>;

/**
 * __useBlockUserMutation__
 *
 * To run a mutation, you first call `useBlockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useBlockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [blockUserMutation, { data, loading, error }] = useBlockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useBlockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<BlockUserMutation, BlockUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<BlockUserMutation, BlockUserMutationVariables>(
    BlockUserDocument,
    options
  );
}
export type BlockUserMutationHookResult = ReturnType<typeof useBlockUserMutation>;
export type BlockUserMutationResult = Apollo.MutationResult<BlockUserMutation>;
export type BlockUserMutationOptions = Apollo.BaseMutationOptions<
  BlockUserMutation,
  BlockUserMutationVariables
>;
export const DeleteUserDocument = gql`
  mutation deleteUser($userId: ID!) {
    deleteUser(id: $userId)
  }
`;
export type DeleteUserMutationFn = Apollo.MutationFunction<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useDeleteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(
    DeleteUserDocument,
    options
  );
}
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteUserMutation,
  DeleteUserMutationVariables
>;
export const AllRegionsWithOutGeoDocument = gql`
  query allRegionsWithOutGeo {
    regionBordersByToken {
      id
      name
      ofpName
    }
  }
`;

/**
 * __useAllRegionsWithOutGeoQuery__
 *
 * To run a query within a React component, call `useAllRegionsWithOutGeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRegionsWithOutGeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRegionsWithOutGeoQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllRegionsWithOutGeoQuery(
  baseOptions?: Apollo.QueryHookOptions<
    AllRegionsWithOutGeoQuery,
    AllRegionsWithOutGeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AllRegionsWithOutGeoQuery, AllRegionsWithOutGeoQueryVariables>(
    AllRegionsWithOutGeoDocument,
    options
  );
}
export function useAllRegionsWithOutGeoLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    AllRegionsWithOutGeoQuery,
    AllRegionsWithOutGeoQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AllRegionsWithOutGeoQuery, AllRegionsWithOutGeoQueryVariables>(
    AllRegionsWithOutGeoDocument,
    options
  );
}
export type AllRegionsWithOutGeoQueryHookResult = ReturnType<typeof useAllRegionsWithOutGeoQuery>;
export type AllRegionsWithOutGeoLazyQueryHookResult = ReturnType<
  typeof useAllRegionsWithOutGeoLazyQuery
>;
export type AllRegionsWithOutGeoQueryResult = Apollo.QueryResult<
  AllRegionsWithOutGeoQuery,
  AllRegionsWithOutGeoQueryVariables
>;
export const WorkBordersByRegionWithNameDocument = gql`
  query workBordersByRegionWithName($id: ID!) {
    workBordersByRegionId(id: $id) {
      id
      name
    }
  }
`;

/**
 * __useWorkBordersByRegionWithNameQuery__
 *
 * To run a query within a React component, call `useWorkBordersByRegionWithNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkBordersByRegionWithNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkBordersByRegionWithNameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkBordersByRegionWithNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    WorkBordersByRegionWithNameQuery,
    WorkBordersByRegionWithNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    WorkBordersByRegionWithNameQuery,
    WorkBordersByRegionWithNameQueryVariables
  >(WorkBordersByRegionWithNameDocument, options);
}
export function useWorkBordersByRegionWithNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    WorkBordersByRegionWithNameQuery,
    WorkBordersByRegionWithNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    WorkBordersByRegionWithNameQuery,
    WorkBordersByRegionWithNameQueryVariables
  >(WorkBordersByRegionWithNameDocument, options);
}
export type WorkBordersByRegionWithNameQueryHookResult = ReturnType<
  typeof useWorkBordersByRegionWithNameQuery
>;
export type WorkBordersByRegionWithNameLazyQueryHookResult = ReturnType<
  typeof useWorkBordersByRegionWithNameLazyQuery
>;
export type WorkBordersByRegionWithNameQueryResult = Apollo.QueryResult<
  WorkBordersByRegionWithNameQuery,
  WorkBordersByRegionWithNameQueryVariables
>;
export const GetUsersByBorderDocument = gql`
  query getUsersByBorder($id: ID!) {
    getUsersByWorkBorderId(id: $id) {
      id
      username
      lastName
      firstName
      phone
      assignedUsers {
        id
      }
      workBorders {
        name
        id
        geo {
          coordinates
        }
        parentId
      }
      roles {
        roleName
      }
    }
  }
`;

/**
 * __useGetUsersByBorderQuery__
 *
 * To run a query within a React component, call `useGetUsersByBorderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByBorderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByBorderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUsersByBorderQuery(
  baseOptions: Apollo.QueryHookOptions<GetUsersByBorderQuery, GetUsersByBorderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByBorderQuery, GetUsersByBorderQueryVariables>(
    GetUsersByBorderDocument,
    options
  );
}
export function useGetUsersByBorderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByBorderQuery, GetUsersByBorderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByBorderQuery, GetUsersByBorderQueryVariables>(
    GetUsersByBorderDocument,
    options
  );
}
export type GetUsersByBorderQueryHookResult = ReturnType<typeof useGetUsersByBorderQuery>;
export type GetUsersByBorderLazyQueryHookResult = ReturnType<typeof useGetUsersByBorderLazyQuery>;
export type GetUsersByBorderQueryResult = Apollo.QueryResult<
  GetUsersByBorderQuery,
  GetUsersByBorderQueryVariables
>;
export const GetUsersByBordersDocument = gql`
  query getUsersByBorders($ids: [ID!]) {
    getUsers(workBorderIds: $ids) {
      id
      username
      lastName
      firstName
      phone
      workBorders {
        name
        id
        geo {
          coordinates
        }
        parentId
      }
      roles {
        roleName
      }
    }
  }
`;

/**
 * __useGetUsersByBordersQuery__
 *
 * To run a query within a React component, call `useGetUsersByBordersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByBordersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByBordersQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *   },
 * });
 */
export function useGetUsersByBordersQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersByBordersQuery, GetUsersByBordersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByBordersQuery, GetUsersByBordersQueryVariables>(
    GetUsersByBordersDocument,
    options
  );
}
export function useGetUsersByBordersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByBordersQuery, GetUsersByBordersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByBordersQuery, GetUsersByBordersQueryVariables>(
    GetUsersByBordersDocument,
    options
  );
}
export type GetUsersByBordersQueryHookResult = ReturnType<typeof useGetUsersByBordersQuery>;
export type GetUsersByBordersLazyQueryHookResult = ReturnType<typeof useGetUsersByBordersLazyQuery>;
export type GetUsersByBordersQueryResult = Apollo.QueryResult<
  GetUsersByBordersQuery,
  GetUsersByBordersQueryVariables
>;
export const GetUsersByTerritoryIdDocument = gql`
  query getUsersByTerritoryID($territoryId: ID!) {
    getUsersByTerritoryId(id: $territoryId) {
      id
      username
      lastName
      firstName
      workBorders {
        name
        id
        geo {
          coordinates
        }
      }
      roles {
        roleName
      }
    }
  }
`;

/**
 * __useGetUsersByTerritoryIdQuery__
 *
 * To run a query within a React component, call `useGetUsersByTerritoryIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByTerritoryIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByTerritoryIdQuery({
 *   variables: {
 *      territoryId: // value for 'territoryId'
 *   },
 * });
 */
export function useGetUsersByTerritoryIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUsersByTerritoryIdQuery,
    GetUsersByTerritoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByTerritoryIdQuery, GetUsersByTerritoryIdQueryVariables>(
    GetUsersByTerritoryIdDocument,
    options
  );
}
export function useGetUsersByTerritoryIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUsersByTerritoryIdQuery,
    GetUsersByTerritoryIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByTerritoryIdQuery, GetUsersByTerritoryIdQueryVariables>(
    GetUsersByTerritoryIdDocument,
    options
  );
}
export type GetUsersByTerritoryIdQueryHookResult = ReturnType<typeof useGetUsersByTerritoryIdQuery>;
export type GetUsersByTerritoryIdLazyQueryHookResult = ReturnType<
  typeof useGetUsersByTerritoryIdLazyQuery
>;
export type GetUsersByTerritoryIdQueryResult = Apollo.QueryResult<
  GetUsersByTerritoryIdQuery,
  GetUsersByTerritoryIdQueryVariables
>;
export const GetUsersByTokenDocument = gql`
  query getUsersByToken($withRegistries: Boolean = false) {
    getUsersByToken {
      id
      username
      firstName
      lastName
      phone
      roles {
        roleName
      }
      workBorders {
        id
        name
        parentId
      }
      assignedUsers {
        id
        username
        roles {
          roleName
        }
        phone
      }
      registries @include(if: $withRegistries) {
        id
        name
        type
        subtype
      }
      deletedAt
    }
  }
`;

/**
 * __useGetUsersByTokenQuery__
 *
 * To run a query within a React component, call `useGetUsersByTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersByTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersByTokenQuery({
 *   variables: {
 *      withRegistries: // value for 'withRegistries'
 *   },
 * });
 */
export function useGetUsersByTokenQuery(
  baseOptions?: Apollo.QueryHookOptions<GetUsersByTokenQuery, GetUsersByTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUsersByTokenQuery, GetUsersByTokenQueryVariables>(
    GetUsersByTokenDocument,
    options
  );
}
export function useGetUsersByTokenLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUsersByTokenQuery, GetUsersByTokenQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUsersByTokenQuery, GetUsersByTokenQueryVariables>(
    GetUsersByTokenDocument,
    options
  );
}
export type GetUsersByTokenQueryHookResult = ReturnType<typeof useGetUsersByTokenQuery>;
export type GetUsersByTokenLazyQueryHookResult = ReturnType<typeof useGetUsersByTokenLazyQuery>;
export type GetUsersByTokenQueryResult = Apollo.QueryResult<
  GetUsersByTokenQuery,
  GetUsersByTokenQueryVariables
>;
export const GetUserByIdDocument = gql`
  query getUserById(
    $userId: ID!
    $needAssignedUsers: Boolean = false
    $needWorkBorders: Boolean = false
    $needRoles: Boolean = false
    $needRegistries: Boolean = false
  ) {
    getUserByID(id: $userId) {
      id
      username
      firstName
      lastName
      phone
      regions {
        name
        id
      }
      defaultRegionId
      assignedUsers @include(if: $needAssignedUsers) {
        ...assignedUsers
      }
      workBorders @include(if: $needWorkBorders) {
        ...workBorders
      }
      roles @include(if: $needRoles) {
        ...roles
      }
      registries @include(if: $needRegistries) {
        ...registries
      }
    }
  }
  ${AssignedUsersFragmentDoc}
  ${WorkBordersFragmentDoc}
  ${RolesFragmentDoc}
  ${RegistriesFragmentDoc}
`;

/**
 * __useGetUserByIdQuery__
 *
 * To run a query within a React component, call `useGetUserByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserByIdQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *      needAssignedUsers: // value for 'needAssignedUsers'
 *      needWorkBorders: // value for 'needWorkBorders'
 *      needRoles: // value for 'needRoles'
 *      needRegistries: // value for 'needRegistries'
 *   },
 * });
 */
export function useGetUserByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(GetUserByIdDocument, options);
}
export function useGetUserByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetUserByIdQuery, GetUserByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUserByIdQuery, GetUserByIdQueryVariables>(
    GetUserByIdDocument,
    options
  );
}
export type GetUserByIdQueryHookResult = ReturnType<typeof useGetUserByIdQuery>;
export type GetUserByIdLazyQueryHookResult = ReturnType<typeof useGetUserByIdLazyQuery>;
export type GetUserByIdQueryResult = Apollo.QueryResult<
  GetUserByIdQuery,
  GetUserByIdQueryVariables
>;
export const RegisterUserV2Document = gql`
  mutation registerUserV2($user: RegisterUserInputV2) {
    registerUserV2(dto: $user) {
      id
      username
      firstName
      lastName
      phone
      workBorders {
        name
        id
        parentId
      }
      roles {
        roleName
      }
    }
  }
`;
export type RegisterUserV2MutationFn = Apollo.MutationFunction<
  RegisterUserV2Mutation,
  RegisterUserV2MutationVariables
>;

/**
 * __useRegisterUserV2Mutation__
 *
 * To run a mutation, you first call `useRegisterUserV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerUserV2Mutation, { data, loading, error }] = useRegisterUserV2Mutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useRegisterUserV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<RegisterUserV2Mutation, RegisterUserV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RegisterUserV2Mutation, RegisterUserV2MutationVariables>(
    RegisterUserV2Document,
    options
  );
}
export type RegisterUserV2MutationHookResult = ReturnType<typeof useRegisterUserV2Mutation>;
export type RegisterUserV2MutationResult = Apollo.MutationResult<RegisterUserV2Mutation>;
export type RegisterUserV2MutationOptions = Apollo.BaseMutationOptions<
  RegisterUserV2Mutation,
  RegisterUserV2MutationVariables
>;
export const UnblockUserDocument = gql`
  mutation unblockUser($id: ID!) {
    unblockUser(id: $id) {
      id
    }
  }
`;
export type UnblockUserMutationFn = Apollo.MutationFunction<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;

/**
 * __useUnblockUserMutation__
 *
 * To run a mutation, you first call `useUnblockUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnblockUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unblockUserMutation, { data, loading, error }] = useUnblockUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnblockUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UnblockUserMutation, UnblockUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UnblockUserMutation, UnblockUserMutationVariables>(
    UnblockUserDocument,
    options
  );
}
export type UnblockUserMutationHookResult = ReturnType<typeof useUnblockUserMutation>;
export type UnblockUserMutationResult = Apollo.MutationResult<UnblockUserMutation>;
export type UnblockUserMutationOptions = Apollo.BaseMutationOptions<
  UnblockUserMutation,
  UnblockUserMutationVariables
>;
export const UpdateUserV2Document = gql`
  mutation updateUserV2($user: UserUpdateRequestV2) {
    updateUserV2(dto: $user) {
      id
      username
      firstName
      lastName
      phone
      workBorders {
        name
        id
        parentId
      }
      roles {
        roleName
      }
    }
  }
`;
export type UpdateUserV2MutationFn = Apollo.MutationFunction<
  UpdateUserV2Mutation,
  UpdateUserV2MutationVariables
>;

/**
 * __useUpdateUserV2Mutation__
 *
 * To run a mutation, you first call `useUpdateUserV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserV2Mutation, { data, loading, error }] = useUpdateUserV2Mutation({
 *   variables: {
 *      user: // value for 'user'
 *   },
 * });
 */
export function useUpdateUserV2Mutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateUserV2Mutation, UpdateUserV2MutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserV2Mutation, UpdateUserV2MutationVariables>(
    UpdateUserV2Document,
    options
  );
}
export type UpdateUserV2MutationHookResult = ReturnType<typeof useUpdateUserV2Mutation>;
export type UpdateUserV2MutationResult = Apollo.MutationResult<UpdateUserV2Mutation>;
export type UpdateUserV2MutationOptions = Apollo.BaseMutationOptions<
  UpdateUserV2Mutation,
  UpdateUserV2MutationVariables
>;
export const AddAddressDocument = gql`
  mutation addAddress($input: AddressInputV2!, $withDetails: Boolean = false) {
    addAddressV2(input: $input) {
      address {
        dadataAddress {
          address
        }
        addressDetail @include(if: $withDetails) {
          ...addressDetail
        }
      }
    }
  }
  ${AddressDetailFragmentDoc}
`;
export type AddAddressMutationFn = Apollo.MutationFunction<
  AddAddressMutation,
  AddAddressMutationVariables
>;

/**
 * __useAddAddressMutation__
 *
 * To run a mutation, you first call `useAddAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAddressMutation, { data, loading, error }] = useAddAddressMutation({
 *   variables: {
 *      input: // value for 'input'
 *      withDetails: // value for 'withDetails'
 *   },
 * });
 */
export function useAddAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAddressMutation, AddAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAddressMutation, AddAddressMutationVariables>(
    AddAddressDocument,
    options
  );
}
export type AddAddressMutationHookResult = ReturnType<typeof useAddAddressMutation>;
export type AddAddressMutationResult = Apollo.MutationResult<AddAddressMutation>;
export type AddAddressMutationOptions = Apollo.BaseMutationOptions<
  AddAddressMutation,
  AddAddressMutationVariables
>;
export const DeleteAddressDocument = gql`
  mutation deleteAddress($dto: DeleteAddressInput!) {
    deleteAddressV2(dto: $dto)
  }
`;
export type DeleteAddressMutationFn = Apollo.MutationFunction<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;

/**
 * __useDeleteAddressMutation__
 *
 * To run a mutation, you first call `useDeleteAddressMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAddressMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAddressMutation, { data, loading, error }] = useDeleteAddressMutation({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useDeleteAddressMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAddressMutation, DeleteAddressMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAddressMutation, DeleteAddressMutationVariables>(
    DeleteAddressDocument,
    options
  );
}
export type DeleteAddressMutationHookResult = ReturnType<typeof useDeleteAddressMutation>;
export type DeleteAddressMutationResult = Apollo.MutationResult<DeleteAddressMutation>;
export type DeleteAddressMutationOptions = Apollo.BaseMutationOptions<
  DeleteAddressMutation,
  DeleteAddressMutationVariables
>;
export const GetAddressByCoordsDocument = gql`
  query getAddressByCoords($options: DadataGeoSearchInput!) {
    dadataGeoSearch(dto: $options) {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;

/**
 * __useGetAddressByCoordsQuery__
 *
 * To run a query within a React component, call `useGetAddressByCoordsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressByCoordsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressByCoordsQuery({
 *   variables: {
 *      options: // value for 'options'
 *   },
 * });
 */
export function useGetAddressByCoordsQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddressByCoordsQuery, GetAddressByCoordsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressByCoordsQuery, GetAddressByCoordsQueryVariables>(
    GetAddressByCoordsDocument,
    options
  );
}
export function useGetAddressByCoordsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressByCoordsQuery,
    GetAddressByCoordsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressByCoordsQuery, GetAddressByCoordsQueryVariables>(
    GetAddressByCoordsDocument,
    options
  );
}
export type GetAddressByCoordsQueryHookResult = ReturnType<typeof useGetAddressByCoordsQuery>;
export type GetAddressByCoordsLazyQueryHookResult = ReturnType<
  typeof useGetAddressByCoordsLazyQuery
>;
export type GetAddressByCoordsQueryResult = Apollo.QueryResult<
  GetAddressByCoordsQuery,
  GetAddressByCoordsQueryVariables
>;
export const GetAddressByStringDocument = gql`
  query getAddressByString($dto: DadataSuggestAddressInput!) {
    dadataSuggestAddress(dto: $dto) {
      ...address
    }
  }
  ${AddressFragmentDoc}
`;

/**
 * __useGetAddressByStringQuery__
 *
 * To run a query within a React component, call `useGetAddressByStringQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressByStringQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressByStringQuery({
 *   variables: {
 *      dto: // value for 'dto'
 *   },
 * });
 */
export function useGetAddressByStringQuery(
  baseOptions: Apollo.QueryHookOptions<GetAddressByStringQuery, GetAddressByStringQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressByStringQuery, GetAddressByStringQueryVariables>(
    GetAddressByStringDocument,
    options
  );
}
export function useGetAddressByStringLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressByStringQuery,
    GetAddressByStringQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressByStringQuery, GetAddressByStringQueryVariables>(
    GetAddressByStringDocument,
    options
  );
}
export type GetAddressByStringQueryHookResult = ReturnType<typeof useGetAddressByStringQuery>;
export type GetAddressByStringLazyQueryHookResult = ReturnType<
  typeof useGetAddressByStringLazyQuery
>;
export type GetAddressByStringQueryResult = Apollo.QueryResult<
  GetAddressByStringQuery,
  GetAddressByStringQueryVariables
>;
