import { Button, Form, InputGroup } from 'react-bootstrap';
import Select from 'react-select';
import TextInputMask from 'react-input-mask';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { ReactElement, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { FilterProps, IFilters, IForm, Kinds } from './FilterTypes';
import useFilterData from './useFilterData';
import SelectWithDates from './components/SelectWithDates';
import AddressFilter from './components/AddressFilter';
import RemoveFilterButton from './components/RemoveFilterButton';
import useFilterController from './useFilterController';
import { actions } from '../../../../../../store/modals/modalsSlice';
import RegionFilter from './components/RegionFilter';
import { GetHoByIdDocument, GetOksByIdsDocument } from '../../../../../../generated/graphql';
import UsageFilter from './components/usageFilter/UsageFilter';
import { getModalsInfo } from '../../../../../../store/modals/modalsSelectors';

const Filter = (props: FilterProps): ReactElement => {
  const { onChange, onSubmitFn, onAddressChange, onFieldFiltersChange, onRegionChange, data } =
    props;
  const { filters, t, filterSelect } = useFilterData(data);
  const dispatch = useDispatch();
  const apollo = useApolloClient();
  const methods = useForm<IForm>();
  const { control, watch } = methods;
  const { fields, append, remove, update } = useFieldArray<IForm, 'filters', 'id'>({
    control,
    name: 'filters',
  });

  const isUCadNum = useSelector(getModalsInfo)?.isUCadNum;

  useEffect(() => {
    const kindFieldFilters = data.fieldFilters.map((field: any) => {
      return {
        kind: field.field,
        value: field.value,
        emptyField: field.emptyField,
      };
    });
    append(data.filters);
    append(data.addr);
    append(kindFieldFilters);
  }, [data]);

  watch('filters');

  const { onSubmit } = useFilterController(
    onChange,
    onAddressChange,
    onRegionChange,
    onFieldFiltersChange
  );

  return (
    <FormProvider {...methods}>
      <Form>
        {!fields.length && <div>{t('backOffice.modals.noFilters')}</div>}
        <div className="d-flex flex-column gap-2">
          {fields.map((filter, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={`${filter?.kind}-${index}`}>
                {t(`filters.fields.${filter?.kind}`)}
                {filters[filter?.kind as Kinds]?.type === 'selectWithDate' && (
                  <SelectWithDates
                    index={index}
                    field={filter}
                    filters={filters}
                    onRemove={(): void => remove(index)}
                    update={update}
                  />
                )}
                {filters[filter?.kind as Kinds]?.type === 'address' && (
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    <AddressFilter field={filter} index={index} update={update} />
                    <RemoveFilterButton onClick={(): void => remove(index)} />
                  </div>
                )}
                {filters[filter?.kind as Kinds]?.type === 'region' && (
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    <RegionFilter field={filter} index={index} update={update} />
                    <RemoveFilterButton onClick={(): void => remove(index)} />
                  </div>
                )}
                {filters[filter?.kind as Kinds]?.type === 'usage' && (
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    <UsageFilter field={filter} index={index} update={update} />
                    <RemoveFilterButton onClick={(): void => remove(index)} />
                  </div>
                )}
                {filters[filter?.kind as Kinds]?.type === 'input' && (
                  <div className="d-flex justify-content-between align-items-center gap-1">
                    <div className="d-flex gap-1 flex-column w-100">
                      {!filter?.emptyField && (
                        <InputGroup>
                          <TextInputMask
                            mask={filters[filter?.kind as Kinds]?.options?.mask}
                            onChange={(event): void => {
                              filters[filter?.kind as Kinds]?.options?.onChangeFn(
                                event.target.value,
                                filter?.kind,
                                filter,
                                index,
                                update
                              );
                            }}
                            value={filter?.value}
                          >
                            <Form.Control style={{ borderRight: 'none' }} />
                          </TextInputMask>
                          <Button
                            style={{
                              backgroundColor: 'transparent',
                              color: 'black',
                              border: '1px solid #ced4da',
                              borderLeft: 'none',
                            }}
                            onClick={(): void => {
                              const changeArr = filter;
                              changeArr.value = '';
                              update(index, changeArr);
                            }}
                          >
                            {t('general.close')}
                          </Button>
                        </InputGroup>
                      )}
                      {!filter?.value && (
                        <Form.Check
                          type="switch"
                          defaultChecked={filter?.emptyField || false}
                          onClick={(event: any): void => {
                            const changeField = filter;
                            changeField.emptyField = event.target.checked;
                            update(index, changeField);
                          }}
                          id="custom-switch"
                          label={`${t(`filters.fields.${filter?.kind}`)} ${t('general.apsend')}`}
                        />
                      )}
                      {filter.kind === 'CAD_NUM' && !filter?.emptyField && (
                        <Form.Check
                          type="switch"
                          disabled={Boolean(filter?.value)}
                          defaultChecked={isUCadNum}
                          onClick={() => dispatch(actions.toggleCadNumType())}
                          id="custom-switch"
                          label="Условный кадастровый номер"
                        />
                      )}
                    </div>
                    <RemoveFilterButton onClick={(): void => remove(index)} />
                  </div>
                )}
              </div>
            );
          })}
          <div className="d-flex align-items-center justify-content-between gap-1 flex-column">
            <Select
              isDisabled={fields.length >= filterSelect.length}
              className="w-75"
              value={null}
              placeholder={
                fields.length >= filterSelect.length ? (
                  <div>{t('backOffice.modals.maxFilters')}</div>
                ) : (
                  <div>{t('backOffice.modals.chooseFilter')}</div>
                )
              }
              options={filterSelect.filter(
                (filter: any) => !fields.map((field) => field?.kind).includes(filter.value)
              )}
              onChange={(option: any): void => {
                const newField = { kind: option?.value };
                append(newField as IFilters);
              }}
            />
            <Button
              onClick={(): void => {
                onSubmit(fields);
                dispatch(actions.hideModal());
              }}
            >
              {t('general.apply')}
            </Button>
            <Button
              disabled={!fields.length}
              onClick={(): void => {
                onSubmit([]);
                onSubmitFn();
                apollo.refetchQueries({ include: [GetHoByIdDocument, GetOksByIdsDocument] });
              }}
            >
              Очистить фильтры
            </Button>
          </div>
        </div>
      </Form>
    </FormProvider>
  );
};
export default Filter;
