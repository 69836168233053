import { FC } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { IoCloseOutline } from 'react-icons/io5';
import { ModalProps } from '../interfaces/ModalProps';
import NewKptForm from '../../Registries/components/AddNewKptFrom/AddNewKptForm';

const AddNewKptModal: FC<ModalProps> = ({ onHide }) => {
  return (
    <>
      <Modal.Header>
        <Modal.Title>Загрузить КПТ</Modal.Title>
        <Button
          type="button"
          className="d-flex align-items-center justify-content-center"
          onClick={onHide}
          style={{ fontSize: '20px' }}
        >
          <IoCloseOutline />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <NewKptForm onHide={onHide} />
      </Modal.Body>
    </>
  );
};

export default AddNewKptModal;
