import { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import TestModal from './TestModal';
import { actions, selectors } from '../../../../store';
import modalKindsMap from '../modalKindsMap';
import InfSelectModal from './InfSelectModal';
import ProcessStatusModal from './ProcessStatusModal';
import BackOfficeFiltersModal from './BackOfficeFiltersModal';
import SaveFilterLayerModal from './SaveFilterLayerModal';
import AllSavedFiltersLayerModal from './AllSavedFiltersLayerModal';
import { LayerEditModal } from './LayerEditModal';
import AddNewKptModal from './AddNewKptModal';

const modalsMap = {
  [modalKindsMap.testModal]: TestModal,
  [modalKindsMap.infSelectModal]: InfSelectModal,
  [modalKindsMap.processStatusModal]: ProcessStatusModal,
  [modalKindsMap.boFiltersModal]: BackOfficeFiltersModal,
  [modalKindsMap.saveFilterLayerModal]: SaveFilterLayerModal,
  [modalKindsMap.allSavedFiltersLayerModal]: AllSavedFiltersLayerModal,
  [modalKindsMap.layerEditModal]: LayerEditModal,
  [modalKindsMap.addNewKptModal]: AddNewKptModal,
};

interface ModalContainerProps {
  kind: string;
}

const ModalContainer: FC<ModalContainerProps> = ({ kind }) => {
  const dispatch = useDispatch();
  const { data, state, size } = useSelector(selectors.getModalsInfo).modal;

  const onHide = (): void => {
    dispatch(actions.hideModal());
  };

  const ModalContent = modalsMap[kind];

  return (
    <Modal size={size} onHide={onHide} show={state === 'opened'} centered style={{zIndex: 100000000}}>
      <ModalContent data={data} onHide={onHide} />
    </Modal>
  );
};

export default ModalContainer;
