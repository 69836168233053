import { ChangeEvent, ReactElement, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input, Upload, message, Spin, Progress } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import './styles/AddNewKptFormStyles.scss';
import axios from 'axios';
import { isEmpty } from 'lodash';
import BordersSelect from '../../../../shared/components/bordersSelect/BordersSelect';
import getEnvs from '../../../../../environments/env';
import { BorderOutput } from '../../../../../generated/graphql';

interface IFile extends File {
  status?: 'removed' | 'uploading';
}

const NewKptForm = ({ onHide }: { onHide: any }): ReactElement => {
  const { t } = useTranslation();
  const { serverAddressKptStorage } = getEnvs();
  const abortControllerRef = useRef<AbortController | null>(null);

  const [regions, setRegions] = useState<BorderOutput[] | null>(null);
  const [selectedRegion, setSelectedRegion] = useState<number | null>(null);
  const [name, setName] = useState<string | null>(null);
  const [file, setFile] = useState<IFile | null>(null);
  const [isUploadBtnDisabled, setIsUploadBtnDisabled] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const handleFileChange = (info: any): void => {
    if (info.file.status !== 'removed') {
      setFile(null);
    }
    if (info.file.status !== 'uploading') {
      setFile(info.file);
    }
  };

  const handleSubmit = async (): Promise<void> => {
    if (!file) {
      return;
    }
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append(
      'metadata',
      new Blob([JSON.stringify({ setName: name, regionId: selectedRegion })], {
        type: 'application/json',
      })
    );
    try {
      const response = await axios.post(`${serverAddressKptStorage}/api/v1/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        signal: abortController.signal,
        onUploadProgress: (progressEvent) => {
          const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(percentCompleted);
        },
      });
      if (response.status === 200) {
        message.success(t('registries.addNewKptForm.successfulSending'));
        onHide();
      }
    } catch (error) {
      message.error(t('registries.addNewKptForm.errorWhileSending'));
    } finally {
      setLoading(false);
    }
  };

  const handleBeforeUpload = (f: IFile): boolean => {
    setFile(f);
    return false;
  };

  useEffect(() => {
    if (name && selectedRegion && file && file.status !== 'removed') {
      return setIsUploadBtnDisabled(false);
    }
    return setIsUploadBtnDisabled(true);
  }, [name, selectedRegion, file]);

  useEffect(() => {
    const abortController = new AbortController();
    abortControllerRef.current = abortController;

    if (isEmpty(regions)) {
      axios
        .get(`${serverAddressKptStorage}/regions/all`, { signal: abortController.signal })
        .then((response: any) => {
          setRegions(response.data);
        })
        .catch((error: any) => {
          console.error('Error:', error);
        });
    }

    return () => {
      abortController.abort();
    };
  }, [regions]);

  // сбрасываем выгрузку файла при unmount-е компонента
  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current?.abort();
      }
    };
  }, []);

  return (
    <div>
      <Spin spinning={loading} indicator={<div />}>
        <Form layout="vertical">
          <div>
            <Form.Item label="Введите название">
              <Input
                value={name || ''}
                onChange={(e: ChangeEvent<HTMLInputElement>): void => setName(e.target.value)}
              />
            </Form.Item>
            <Form.Item label="выберите регион">
              <BordersSelect
                isMulti={false}
                needDefault={false}
                initOptions={regions}
                onChange={(selected: any): void => {
                  setSelectedRegion(selected?.id);
                }}
              />
            </Form.Item>
            <div className="newKptFormButtons">
              <Upload
                accept=".zip"
                beforeUpload={handleBeforeUpload}
                onChange={handleFileChange}
                maxCount={1}
              >
                <Button icon={<UploadOutlined />}>
                  {t('registries.addNewKptForm.chooseKpt')}{' '}
                </Button>
              </Upload>

              <Button type="primary" disabled={isUploadBtnDisabled} onClick={handleSubmit}>
                {t('registries.addNewKptForm.loadKpt')}
              </Button>
            </div>
          </div>
        </Form>
      </Spin>
      <Progress
        className="progressLoading"
        style={{ display: loading ? 'block' : 'none' }}
        type="circle"
        percent={uploadProgress}
        status={uploadProgress === 100 ? 'success' : 'active'}
      />
    </div>
  );
};

export default NewKptForm;
