import { useTranslation } from 'react-i18next';
import { BiBuildingHouse, BiMapAlt, BiSpreadsheet, BiTask, BiUser } from 'react-icons/bi';
import { IconType } from 'react-icons';
import { useNavigate } from 'react-router-dom';
import { FiUsers } from 'react-icons/fi';
import { GiReceiveMoney } from 'react-icons/gi';
import { TbBrandGoogleAnalytics, TbFileAnalytics } from 'react-icons/tb';
import { AiOutlineBorderOuter, AiOutlineGlobal, AiOutlineSchedule } from 'react-icons/ai';
import { FaTasks } from 'react-icons/fa';
import { LiaUserCheckSolid } from 'react-icons/lia';
import { useDispatch } from 'react-redux';
import { Button, Upload } from 'antd';
import { IoIosAddCircleOutline } from 'react-icons/io';
import useRoles from '../../../hooks/useRoles';
import { DataSourceKind, Roles, useGetUserByIdQuery } from '../../../../generated/graphql';
import { pagesRoutesMap } from '../../../pages/routes';
import { actions } from '../../../../store';
import { AnalyticsViews } from '../../Analytics/Views/models/AnalyticsViews';
import { TableViews } from '../../Analytics/Views/models/TableViews';
import useAuth from '../../../hooks/useAuth';

const icons: Record<string, IconType> = {
  tasks: BiTask,
  map: BiMapAlt,
  users: BiUser,
  analytics: TbBrandGoogleAnalytics,
  backOffice: BiBuildingHouse,
  registries: BiSpreadsheet,
  tasksAnalytics: AiOutlineSchedule,
  usersAnalytics: FiUsers,
  antsSalaries: GiReceiveMoney,
  breachesCount: TbFileAnalytics,
  processCount: TbFileAnalytics,
  breachesDynamic: TbFileAnalytics,
  processDynamic: TbFileAnalytics,
  regions: AiOutlineGlobal,
  usersQuality: FaTasks,
  usersTasks: LiaUserCheckSolid,
  ruBorders: AiOutlineBorderOuter,
};

const useMenuItemsConfig = (): any => {
  const { t } = useTranslation();
  const permissions = useRoles();
  const { isANT: isAnt, isVIEWER: isViewer } = permissions;
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeAnalyticsView = (view: AnalyticsViews) => (): void => {
    dispatch(actions.changeMainAnalyticsView('analytics'));
    dispatch(actions.handleChangeAnalyticsView(view));
  };

  const changeRegisterView = (view: DataSourceKind): void => {
    dispatch(actions.handleChangeregistriesView({ viewPage: view }));
  };

  const changeTableView = (view: TableViews) => (): void => {
    dispatch(actions.changeMainAnalyticsView('table'));
    dispatch(actions.handleChangeTableView(view));
  };
  const { data } = useGetUserByIdQuery({
    variables: { userId: user?.id || '', needRegistries: true, needRoles: true },
  });
  const registries = data?.getUserByID?.registries?.filter((el: any) => el.subtype !== 'OKS');

  const getAnalyticsMenu = () => {
    if (isAnt(false))
      return [
        {
          key: '_antsSalaries',
          title: t('analytics.views.antsSalaries'),
          icon: icons.antsSalaries({}),
          onClick: changeTableView('antsSalaries'),
          disabled: false,
        },
      ];

    if (isViewer(false))
      return [
        {
          key: null,
          title: t('analytics.analyticsPart'),
          type: 'group',
          children: [
            {
              key: '_boBreachesCount',
              title: t('analytics.views.breachesCount'),
              icon: icons.breachesCount({}),
              onClick: changeAnalyticsView('breachesCount'),
              disabled: false,
            },
            {
              key: '_boProcessesCount',
              title: t('analytics.views.processCount'),
              icon: icons.processCount({}),
              onClick: changeAnalyticsView('processCount'),
              disabled: false,
            },
            {
              key: '_boBreachesDynamic',
              title: t('analytics.views.breachesDynamic'),
              icon: icons.breachesDynamic({}),
              onClick: changeAnalyticsView('breachesDynamic'),
              disabled: false,
            },
            {
              key: '_boProcessDynamic',
              title: t('analytics.views.processDynamic'),
              icon: icons.processDynamic({}),
              onClick: changeAnalyticsView('processDynamic'),
              disabled: false,
            },
            {
              key: '_usersAnalytics',
              title: t('analytics.views.users'),
              icon: icons.usersAnalytics({}),
              onClick: changeAnalyticsView('users'),
            },
            {
              key: '_usersQuality',
              title: t('analytics.views.usersQuality'),
              icon: icons.usersQuality({}),
              onClick: changeAnalyticsView('usersQuality'),
            },
            {
              key: '_usersTasks',
              title: t('analytics.views.usersTasks'),
              icon: icons.usersTasks({}),
              onClick: changeAnalyticsView('usersTasks'),
            },
            {
              key: '_regions',
              title: t('analytics.views.regions'),
              icon: icons.regions({}),
              onClick: changeAnalyticsView('regions'),
            },
          ],
        },
      ];

    return [
      {
        key: null,
        title: t('analytics.tableForms'),
        type: 'group',
        children: [
          {
            key: '_tasksAnalytics',
            title: t('analytics.views.tasks'),
            icon: icons.tasksAnalytics({}),
            onClick: changeTableView('tasks'),
            disabled: true,
          },
          {
            key: '_antsSalaries',
            title: t('analytics.views.antsSalaries'),
            icon: icons.antsSalaries({}),
            onClick: changeTableView('antsSalaries'),
            disabled: isViewer(false),
          },
        ],
      },
      {
        key: null,
        title: t('analytics.analyticsPart'),
        type: 'group',
        children: [
          {
            key: '_boBreachesCount',
            title: t('analytics.views.breachesCount'),
            icon: icons.breachesCount({}),
            onClick: changeAnalyticsView('breachesCount'),
            disabled: false,
          },
          {
            key: '_boProcessesCount',
            title: t('analytics.views.processCount'),
            icon: icons.processCount({}),
            onClick: changeAnalyticsView('processCount'),
            disabled: false,
          },
          {
            key: '_boBreachesDynamic',
            title: t('analytics.views.breachesDynamic'),
            icon: icons.breachesDynamic({}),
            onClick: changeAnalyticsView('breachesDynamic'),
            disabled: false,
          },
          {
            key: '_boProcessDynamic',
            title: t('analytics.views.processDynamic'),
            icon: icons.processDynamic({}),
            onClick: changeAnalyticsView('processDynamic'),
            disabled: false,
          },
          {
            key: '_usersAnalytics',
            title: t('analytics.views.users'),
            icon: icons.usersAnalytics({}),
            onClick: changeAnalyticsView('users'),
          },
          {
            key: '_usersQuality',
            title: t('analytics.views.usersQuality'),
            icon: icons.usersQuality({}),
            onClick: changeAnalyticsView('usersQuality'),
          },
          {
            key: '_usersTasks',
            title: t('analytics.views.usersTasks'),
            icon: icons.usersTasks({}),
            onClick: changeAnalyticsView('usersTasks'),
          },
          {
            key: '_regions',
            title: t('analytics.views.regions'),
            icon: icons.regions({}),
            onClick: changeAnalyticsView('regions'),
          },
        ],
      },
    ];
  };
  const subMenues: Record<string, any> = {
    map: !permissions.isVIEWER(false)
      ? [
          {
            key: '_tasks',
            title: t('nav.tasks'),
            icon: icons.tasks({}),
            onClick: () =>
              !permissions.isVIEWER(false) ? dispatch(actions.changeMenuState('opened')) : null,
          },
        ]
      : null,
    analytics: getAnalyticsMenu(),
    registries: [
      {
        key: null,
        title: 'Реестры',
        type: 'group',
        children: registries?.map((registry) => {
          return {
            key: registry?.id,
            title: registry?.name,
            icon: icons.ruBorders({}),
            onClick: () => changeRegisterView(registry?.type as DataSourceKind),
          };
        }),
      },
      {
        key: 'sub2',
        hover: false,
        title: (
          <Button
            onClick={() =>
              dispatch(
                actions.openModal({
                  kind: 'addNewKptModal',
                  size: 'lg',
                })
              )
            }
            icon={<IoIosAddCircleOutline />}
          >
            {t('registries.addNewKptForm.addKpt')}
          </Button>
        ),
      },
    ],
  };

  const onClick = (path: string) => (): void => {
    navigate(path);
  };

  const canAccess = (permission: boolean | Roles | Roles[]): boolean | any => {
    if (typeof permission === 'boolean') return permission;
    if (permission instanceof Array) {
      permission.some((perm) => {
        return permissions[`is${perm}`](false);
      });
      return permission.some((perm) => permissions[`is${perm}`](false));
    }
    return permissions[`is${permission}`]();
  };

  const MENU_ITEMS_CONFIG = pagesRoutesMap
    .filter((item) => canAccess(item.permission) && item.title)
    .map(({ title, path, key }) => ({
      key,
      title: t(`${title}`),
      route: path,
      role: 'menuitem',
      onTitleClick: onClick(path),
      children: subMenues[key] || null,
      icon: icons[key]({}),
    }));
  return MENU_ITEMS_CONFIG;
};

export default useMenuItemsConfig;
